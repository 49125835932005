/* eslint-disable no-shadow */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  Text as Txt,
  Platform,
  Image,
  TouchableOpacity,
} from "react-native";
import {
  ProgressBar,
  TextInput,
  Button,
  IconButton,
  Text,
  SegmentedButtons,
  RadioButton,
  Card,
  Divider,
  Portal,
  Dialog,
} from "react-native-paper";
import Global from "../Global";
import { Picker } from "@react-native-picker/picker";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

const MobileRechargeScreen = ({ navigation, route }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [phone, setPhone] = useState("");
  const [networkProvider, setNetworkProvider] = useState(false);
  const [value, setValue] = useState("whdat");
  const [selectedPackage, setSelectedPackage] = useState();
  const [visible, setVisible] = useState(false);

  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const handleSelectPackage = (pkgId) => {
    setSelectedPackage(pkgId);
    hideDialog();
  };

  useEffect(() => {
    mainStore.getPackages();
  }, []);
  useEffect(() => {
    if (route.params !== undefined) {
      console.log(route.params);
      setPhone(route.params.number);
    }
  }, [route.params]);
  useEffect(() => {
    setSelectedPackage(mainStore.packages[1]?.id);
  }, [mainStore.packages]);
  const steps = [
    "1/3 معلومات الباقة والخط",
    "2/3 مراجعة وتأكيد",
    "3/3 نتيجة العملية",
  ];

  const handleSubmit = () => {
    if (step < 3) {
      setStep(step + 1);
    }
    if (step === 2) {
      const id = mainStore.packages.find(
        (obj) => obj.id === selectedPackage
      ).id;
      mainStore.chargeMobileBalance(phone, value, id);
    }
    if (step === 3) {
      navigation.navigate(
        "TransactionDetailsScreen",
        mainStore.latestTransactions[0]
      );
    }
  };

  const handleBack = () => {
    if (step > 1) {
      step === 3 ? navigation.navigate("MyTabs") : setStep(step - 1);
    }
  };
  if (mainStore.packages.length === 0 || mainStore.loading) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Portal>
          <Dialog
            visible={visible}
            onDismiss={hideDialog}
            style={{ maxHeight: "90%" }}
          >
            <Dialog.Title>اختر الباقة</Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {mainStore.packages.map((pkg, index) => (
                <React.Fragment key={pkg.id}>
                  <Dialog.Content>
                    <TouchableOpacity
                      onPress={() => handleSelectPackage(pkg.id)}
                    >
                      <Txt
                        style={{
                          fontFamily: "Tajawal",
                          alignSelf: "center",
                          color: Global.textcolor,
                        }}
                      >
                        {Global.arabicToNumeric(pkg.mobile_balance) + " "}
                        وحدة
                      </Txt>

                      {index < mainStore.packages.length - 1 && (
                        <Divider style={{ marginTop: 20 }} />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>
            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideDialog}>إلغاء</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Text style={styles.stepText}>{steps[step - 1]}</Text>
        <View>
          <ProgressBar
            style={{
              transform: [{ rotate: "180deg" }],
            }}
            progress={step / 3}
            color={"#ebb703"}
          />
        </View>{" "}
        <View style={styles.stepIndicators}>
          {[1, 2].map((value) => (
            <IconButton
              key={value}
              icon={
                step > value
                  ? "checkbox-marked-circle-outline"
                  : "circle-outline"
              }
              iconColor={step >= value ? "green" : "gray"}
              size={30}
            />
          ))}
        </View>
        {step === 1 && (
          <View style={{ padding: 5 }}>
            <Card style={styles.card} mode="elevated">
              <SegmentedButtons
                style={{ direction: "ltr" }}
                theme={{
                  colors: {
                    secondaryContainer: Global.primary,
                    onSecondaryContainer: Global.buttonTextColor,
                  },
                }}
                value={value}
                onValueChange={setValue}
                buttons={[
                  {
                    value: "fatura",
                    label: "فاتورة",
                  },
                  {
                    value: "whdat",
                    label: "وحدات",
                  },
                ]}
              />
              <Text style={{ fontSize: 17, marginTop: 20 }}> :رقم الهاتف</Text>

              <TextInput
                keyboardType="numeric"
                value={phone}
                onChangeText={setPhone}
                contentStyle={{}}
                style={styles.input}
                mode="outlined"
                right={
                  <TextInput.Icon
                    onPress={() =>
                      navigation.navigate("ContactsScreen", "mobile")
                    }
                    icon="contacts"
                    iconColor={Global.primary}
                    style={{
                      borderWidth: 1,
                      borderRadius: 10,
                      borderColor: Global.placeholder,
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  />
                }
              />
              <Text style={styles.text}> :عدد الوحدات</Text>
              <View
                style={{
                  borderColor: Global.primary,
                  borderRadius: 10,
                  marginTop: 10,
                  backgroundColor: Global.isios ? "" : Global.backgroundColor,
                }}
              >
                <Button
                  icon={() => (
                    <Icon
                      name="chevron-down"
                      size={30}
                      color={Global.textcolor}
                      style={{ marginTop: -5, marginHorizontal: -10 }}
                    />
                  )}
                  mode="outlined"
                  style={{ borderRadius: 10, paddingVertical: "2.5%" }}
                  onPress={showDialog}
                >
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Txt
                      style={{
                        fontFamily: "Tajawal",
                        alignSelf: "center",
                        color: Global.textcolor,
                        marginStart: 5,
                        fontSize: 18,
                      }}
                    >
                      {Global.arabicToNumeric(
                        mainStore.packages.find(
                          (obj) => obj.id === selectedPackage
                        )?.mobile_balance
                      )}
                    </Txt>
                    <Txt
                      style={{
                        fontFamily: "Tajawal",
                        alignSelf: "center",
                        color: Global.textcolor,
                        marginStart: 5,
                        fontSize: 18,
                      }}
                    >
                      وحدة
                    </Txt>
                  </View>
                </Button>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <Text style={styles.text}>:السعر</Text>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: Global.primary,
                    borderRadius: 10,
                    marginTop: 10,
                    backgroundColor: Global.backgroundColor,
                  }}
                >
                  <Txt
                    style={{
                      fontSize: 18,
                      alignSelf: "center",
                      paddingVertical: "5%",
                      fontFamily: "Tajawal",
                      color: Global.textcolor,
                    }}
                  >
                    {`${Number(
                      mainStore.packages.find(
                        (obj) => obj.id === selectedPackage
                      )?.amount
                    )} دولار`}
                  </Txt>
                </View>
              </View>
              {/*  <View style={styles.switchContainer}>
                <RadioButton.Group
                  onValueChange={value => setNetworkProvider(value)}
                  value={networkProvider}>
                  <RadioButton.Item
                    labelStyle={{fontWeight: Global.fontWeight}}
                    label="خط سيرياتل"
                    value="syriatel"
                  />
                  <RadioButton.Item
                    labelStyle={{fontWeight: Global.fontWeight}}
                    label="خط MTN"
                    value="MTN"
                  />
                </RadioButton.Group>
                </View> */}
            </Card>
          </View>
        )}
        {step === 2 && (
          <View>
            <Text style={styles.title}>تأكد من رقم الهاتف والباقة:</Text>

            <Text style={{ marginBottom: 10, fontSize: 16, marginTop: 10 }}>
              :رقم هاتف المستفيد ونوع الخط
            </Text>
            <View
              style={{
                backgroundColor: Global.cardcolor,
                borderRadius: 15,
                padding: 12,
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 17 }}>
                {phone} | {value === "fatura" ? "فاتورة" : "وحدات"}
              </Text>
            </View>
            <Text style={{ marginBottom: 10, fontSize: 16 }}>
              :الباقة المختارة
            </Text>
            <View
              style={{
                backgroundColor: Global.cardcolor,
                borderRadius: 15,
                padding: 12,
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 17 }}>
                {Global.arabicToNumeric(
                  mainStore.packages.find((obj) => obj.id === selectedPackage)
                    .mobile_balance
                ) + " وحدة"}
              </Text>
            </View>
            <Text style={{ marginBottom: 10, fontSize: 16 }}>
              :يتوجب عليك دفع
            </Text>
            <View
              style={{
                backgroundColor: Global.cardcolor,
                borderRadius: 15,
                padding: 12,
                marginBottom: 10,
              }}
            >
              <Txt
                style={{
                  fontSize: 17,
                  fontFamily: "Tajawal",
                  color: Global.textcolor,
                }}
              >
                {`${Global.arabicToNumeric(
                  mainStore.packages.find((obj) => obj.id === selectedPackage)
                    .amount
                )} دولار`}
              </Txt>
            </View>
            <Divider
              bold={true}
              style={{
                backgroundColor: Global.placeholder,
                marginBottom: 20,
                marginTop: 10,
              }}
            />
          </View>
        )}
        {step === 3 && (
          <View>
            <IconButton
              icon={mainStore.errorMessage === "" ? "check" : "close"}
              size={150}
              style={{
                marginBottom: 16,
                backgroundColor: Global.cardcolor,
                alignSelf: "center",
              }}
              mode={"contained"}
              iconColor={mainStore.errorMessage === "" ? "green" : "red"}
            />
            <Text
              style={{
                fontSize: 25,
                fontWeight: Global.fontWeight,
                lineHeight: 30,
                textAlign: "center",
                marginBottom: 16,
                paddingTop: 20,
              }}
            >
              {mainStore.errorMessage === ""
                ? "تمت العملية بنجاح"
                : "فشلت العملية"}
            </Text>
            <Text
              style={{
                fontSize: 16,
                textAlign: "center",
                marginBottom: 32,
                fontWeight: Global.fontWeight,
                lineHeight: 25,
              }}
            >
              {mainStore.errorMessage === ""
                ? "سيتم شحن الرصيد إلى الرقم المذكور خلال مدة أقصاها 3 ساعات"
                : mainStore.errorMessage}
            </Text>
          </View>
        )}
        <View style={styles.buttonsContainer}>
          {step > 1 && (
            <Button
              disabled={step > 3 ? false : buttonDisabled && false}
              mode="outlined"
              onPress={handleBack}
              style={styles.button}
            >
              {step === 1 ? "" : step === 2 ? "السابق" : "إغلاق"}
            </Button>
          )}
          {(mainStore.errorMessage === "" || step < 3) && (
            <Button
              mode="contained"
              disabled={phone === ""}
              onPress={handleSubmit}
              style={styles.button}
            >
              {step === 1
                ? "التالي"
                : step === 2
                ? "موافقة وتأكيد"
                : "تعقب المعاملة"}
            </Button>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 0,
    flex: 1,
    backgroundColor: Global.backgroundColor,
  },
  title: {
    fontSize: 19,
    marginBottom: 10,
  },

  infoBox: {
    backgroundColor: Global.cardcolor,
    padding: 10,
    width: "100%",
    alignSelf: "center",
    borderRadius: 5,
    borderWidth: 1,
    margin: 10,
    borderColor: Global.graycolor,
  },

  image: {
    width: 200,
    height: 200,
  },
  input2: {
    flex: 1,
  },
  stepIndicators: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
    marginBottom: "15%",
  },
  stepText: {
    fontSize: 18,
    marginBottom: 10,
  },

  card: {
    marginTop: 20,
    padding: 20,
    elevation: 1,
  },

  input: {
    marginBottom: 20,
    marginTop: 10,
    textAlign: "left",
    fontSize: 17,
    // padding: 5,
  },
  switchContainer: {
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
  },
  text: {
    fontSize: 17,
  },
});

export default observer(MobileRechargeScreen);
