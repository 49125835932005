import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Keyboard,
  Platform,
  KeyboardAvoidingView,
  Modal,
  ScrollView,
  BackHandler,
  I18nManager,
  Text as Txt,
  Linking,
} from "react-native";
import {
  TextInput,
  Button,
  Text,
  Title,
  Checkbox,
  ActivityIndicator,
  MD2Colors,
  IconButton,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import mainStore from "../../stores/MainStore";
import Global from "../../Global";
import CountryFlag from "react-native-country-flag";
const SignUpScreen = ({ navigation }) => {
  const [name, setname] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [CD, setCD] = useState();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const callingCodeToIso = require("../../callingcode.json");
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  useEffect(() => {
    setCD(mainStore.callingCode.replace("+", ""));
  }, [mainStore.callingCode]);
  const getIsoCode = (callingCode) => {
    return callingCodeToIso[callingCode];
  };
  const handleSignUp = async () => {
    await mainStore.register(
      name + " " + surname,
      email,
      password,
      confirmedPassword,
      "+" + CD + phone,
      referralCode
    );

    mainStore.errorMessage !== "" &&
      mainStore.loading === false &&
      setModalVisible(true);
  };
  if (mainStore.loading || mainStore.callingCode === "") {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" && "position"}
      style={{ flex: 1, backgroundColor: Global.backgroundColor }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        style={{ backgroundColor: Global.backgroundColor }}
      >
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Image
              source={require("../../assets/logo.webp")}
              style={styles.logo}
            />
            <Text style={styles.appName}>محفظة يا مرسال</Text>
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              label="رقم هاتفك"
              mode="outlined"
              keyboardType="phone-pad"
              value={phone}
              onChangeText={setPhone}
              style={styles.phoneNumberInput}
            />
            <TextInput
              label="رمز البلد"
              placeholder="00"
              placeholderTextColor={"gray"}
              value={CD}
              onChangeText={setCD}
              style={styles.countryCodeInput}
              keyboardType="phone-pad"
              mode="outlined"
              contentStyle={{ marginLeft: 60, marginRight: -10 }}
            />
            <Text
              style={[
                {
                  alignSelf: "center",
                  fontSize: 30,
                  position: "absolute",
                  left: 55,
                  fontWeight: Global.fontWeight,
                  color: Global.textcolor,
                  bottom: 6,
                },
                Platform.OS === "ios" && {},
              ]}
            >
              +{" "}
            </Text>
            {getIsoCode(CD) !== undefined && (
              <CountryFlag
                style={{
                  alignSelf: "center",
                  position: "absolute",
                  left: 10,
                  bottom: 13,
                }}
                isoCode={getIsoCode(CD) === undefined ? "" : getIsoCode(CD)}
                size={25}
              />
            )}
          </View>
          <View
            style={{
              display:
                !/^[0-9]*$/.test(phone) || phone[0] === "0" ? "flex" : "none",
              flexDirection: "row",
              alignItems: "center",
              marginEnd: 50,
              marginRight: -15,
              marginTop: -20,
              marginBottom: 5,
            }}
          >
            <IconButton iconColor="red" icon="information-outline" size={20} />
            <Text
              style={{
                color: "red",
                fontWeight: Global.fontWeight,
                lineHeight: 20,
              }}
            >
              {phone[0] === "0"
                ? "الرجاء إدخال رقم هاتفك بدون صفر في بدايته"
                : "يمنع إدخال الحروف والمسافات والرموز"}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginEnd: 50,
              marginRight: -15,
              marginTop: -20,
              alignSelf: "flex-start",
            }}
          >
            <IconButton icon="information-outline" size={20} />
            <Txt
              style={{
                color: Global.textcolor,
                fontWeight: Global.fontWeight,
                lineHeight: 20,
                fontFamily: "Tajawal",
              }}
            >
              تأكد أن الرقم مرتبط بـ WhatsApp ليصلك كود التحقق
            </Txt>
          </View>
          <View style={{ flexDirection: "row" }}>
            <TextInput
              label="الاسم"
              value={name}
              onChangeText={(text) => setname(text)}
              mode="outlined"
              // keyboardType="phone-pad"
              style={styles.input}
            />
            <TextInput
              label="الكنية"
              value={surname}
              onChangeText={(text) => setSurname(text)}
              mode="outlined"
              // keyboardType="phone-pad"
              style={[styles.input, { marginEnd: 10 }]}
            />
          </View>
          <TextInput
            label="البريد الإلكتروني"
            value={email}
            onChangeText={(text) => setemail(text)}
            mode="outlined"
            // keyboardType="phone-pad"
            style={styles.input}
          />
          <TextInput
            label="كلمة المرور"
            value={password}
            onChangeText={(text) => setPassword(text)}
            secureTextEntry={!showPassword}
            style={styles.input}
            mode="outlined"
            left={
              <TextInput.Icon
                onPress={() => setShowPassword(!showPassword)}
                icon={!showPassword ? "eye-off" : "eye"}
                iconColor={Global.primary}
                style={{
                  borderColor: Global.placeholder,
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              />
            }
          />

          <TextInput
            label="تأكيد كلمة المرور"
            value={confirmedPassword}
            onChangeText={(text) => setConfirmedPassword(text)}
            secureTextEntry={!showPassword}
            style={styles.input}
            mode="outlined"
          />
          <TextInput
            label="كود التوصية (اختياري)"
            value={referralCode}
            onChangeText={(text) => setReferralCode(text)}
            style={styles.input}
            mode="outlined"
          />
          <Txt
            style={{
              marginBottom: 10,
              color: Global.textcolor,
              writingDirection: "rtl",
              lineHeight: 23,
              fontSize: 15,
              fontFamily: "Tajawal",
            }}
          >
            عند تسجيلك في تطبيق يامرسال فأنت توافق على{" "}
            <Txt
              style={{
                textDecorationLine: "underline",
                color: Global.primary,
                fontWeight: "900",
              }}
              onPress={() =>
                Linking.openURL("https://yamersal.com/terms-and-conditions/")
              }
            >
              الشروط والأحكام
            </Txt>
            .
          </Txt>
          <Button
            disabled={
              phone === "" ||
              name === "" ||
              surname === "" ||
              email === "" ||
              password === "" ||
              confirmedPassword === "" ||
              !/^[0-9]*$/.test(phone) ||
              phone[0] === "0"
            }
            mode="contained"
            onPress={handleSignUp}
            style={styles.signUpButton}
          >
            التسجيل
          </Button>

          <TouchableOpacity
            style={{
              borderBottomWidth: 1,
              borderColor: Global.textcolor,
              marginHorizontal: "13%",
              marginVertical: 10,
              borderBottomColor: Global.primary,
            }}
            onPress={() => navigation.navigate("LoginScreen")}
          >
            <Text style={styles.loginLink}>
              لديك حساب بالفعل؟ قم بتسجيل الدخول
            </Text>
          </TouchableOpacity>

          <Modal visible={modalVisible} transparent={true} animationType="fade">
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              activeOpacity={1}
              onPress={() => setModalVisible(false)}
              onRequestClose={() => setModalVisible(false)}
            >
              <View
                padding={8}
                style={{
                  width: "auto",
                  maxWidth: "80%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  icon="alert-circle"
                  color={"red"}
                  size={50}
                  style={styles.icon}
                />
                <Text style={{ marginHorizontal: 20, marginBottom: 10 }}>
                  {mainStore.errorMessage}
                </Text>
                <Button
                  mode="contained"
                  onPress={() => setModalVisible(false)}
                  style={{ alignSelf: "center", marginBottom: 20 }}
                >
                  حسنًا
                </Button>
              </View>
            </TouchableOpacity>
          </Modal>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    padding: 20,
    backgroundColor: Global.backgroundColor,
    paddingBottom: "10%",
  },
  logoContainer: {
    alignItems: "center",
    marginBottom: 20,
  },
  appName: {
    fontSize: 24,
    fontWeight: Global.fontWeight,
  },
  input: {
    marginBottom: 20,
    textAlign: "auto",
    flex: 1,
  },
  showPasswordContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  showPasswordText: {
    marginLeft: 5,
    fontWeight: Global.fontWeight,
    top: Platform.OS === "ios" ? 3 : 0,
    lineHeight: 20,
  },
  signUpButton: {
    marginBottom: 10,
    borderRadius: 5,
  },
  loginLink: {
    textAlign: "center",
    color: Global.primary,
  },
  logo: {
    width: 125,
    height: 125,
    marginBottom: 20,
  },
  inputContainer: {
    flexDirection: "row",
    marginBottom: 20,
    width: "100%",
  },
  countryCodeInput: {
    flex: 1.8,
  },
  phoneNumberInput: {
    flex: 3,
    marginLeft: 10,
    textAlign: "left",
  },
});

export default observer(SignUpScreen);
