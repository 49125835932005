import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Platform,
  KeyboardAvoidingView,
  TouchableOpacity,
  Modal,
  Image,
  ScrollView,
} from "react-native";
import {
  TextInput,
  Button,
  Title,
  Text,
  HelperText,
  IconButton,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import mainStore from "../../stores/MainStore";
import Global from "../../Global";
import ResetPassword2 from "./ResetPassword2";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import CountryFlag from "react-native-country-flag";

const ResetPasswordScreen = ({ navigation, route }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [timer, setTimer] = useState(60);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [succsess, setSuccsess] = useState(false);
  const [CountryCode, setCountryCode] = useState("");

  useEffect(() => {
    setCountryCode(mainStore.callingCode.replace("+", ""));
  }, [mainStore.callingCode]);
  const callingCodeToIso = require("../../callingcode.json");

  const getIsoCode = (callingCode) => {
    return callingCodeToIso[callingCode];
  };
  const handleVerificationCodeChange = (text) => setVerificationCode(text);

  const submitPhoneNumber = () => {
    if (!phoneNumber.trim()) {
      alert("الرجاء إدخال رقم هاتف صالح");
      return;
    }
    mainStore.sendWhatsapp("+" + CountryCode + phoneNumber);
    setCodeSent(true);
    setButtonDisabled(true);
    startTimer();
  };

  const submitVerificationCode = async () => {
    await mainStore.confirmCode(
      "+" + CountryCode + phoneNumber,
      verificationCode
    );
    if (mainStore.errorMessage === "") {
      setSuccsess(true);
    } else {
      setModalVisible(true);
    }
  };

  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          setButtonDisabled(false);
          return 90;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  if (succsess) {
    return (
      <ResetPassword2
        props={{
          phoneNumber: "+" + CountryCode + phoneNumber,
          verificationCode: verificationCode,
          li: route.params,
        }}
        navigation={navigation}
        setSuccsess={setSuccsess}
      />
    );
  }
  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        // paddingHorizontal: 16,
        //  backgroundColor: Global.backgroundColor,
      }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.container}>
        <Icon name="lock-reset" size={100} color={Global.graycolor} />
        <Text style={styles.txt}>
          {route.params === "li"
            ? "سنساعدك على تغيير كلمة مرور حسابك"
            : "  سنساعدك على استعادة كلمة مرور حسابك"}
        </Text>

        <View style={styles.inputContainer}>
          <TextInput
            label="رقم هاتفك"
            mode="outlined"
            keyboardType="phone-pad"
            value={phoneNumber}
            onChangeText={setPhoneNumber}
            style={styles.phoneNumberInput}
          />
          <TextInput
            label="رمز البلد"
            placeholder="00"
            placeholderTextColor={"gray"}
            value={CountryCode}
            onChangeText={setCountryCode}
            style={styles.countryCodeInput}
            keyboardType="phone-pad"
            mode="outlined"
            contentStyle={{ marginLeft: 60, marginRight: -10 }}
          />
          <Text
            style={[
              {
                alignSelf: "center",
                fontSize: 30,
                position: "absolute",
                left: 55,
                fontWeight: Global.fontWeight,
                color: Global.textcolor,
                bottom: 6,
              },
              Platform.OS === "ios" && {},
            ]}
          >
            +{" "}
          </Text>
          {getIsoCode(CountryCode) !== undefined && (
            <CountryFlag
              style={{
                alignSelf: "center",
                position: "absolute",
                left: 10,
                bottom: 13,
              }}
              isoCode={
                getIsoCode(CountryCode) === undefined
                  ? ""
                  : getIsoCode(CountryCode)
              }
              size={25}
            />
          )}
        </View>
        <View
          style={{
            display:
              !/^[0-9]*$/.test(phoneNumber) || phoneNumber[0] === "0"
                ? "flex"
                : "none",
            flexDirection: "row",
            alignItems: "center",
            // marginHorizontal: '-25%',
          }}
        >
          <IconButton iconColor="red" icon="information-outline" size={20} />
          <Text
            style={{
              color: "red",
              fontWeight: Global.fontWeight,
              lineHeight: 20,
            }}
          >
            {phoneNumber[0] === "0"
              ? "الرجاء إدخال رقم هاتفك بدون صفر في بدايته"
              : "يمنع إدخال الحروف والمسافات والرموز"}
          </Text>
        </View>

        {codeSent && (
          <TextInput
            label="رمز التحقق"
            value={verificationCode}
            onChangeText={handleVerificationCodeChange}
            keyboardType="decimal-pad"
            style={styles.input}
            mode="outlined"
          />
        )}

        <Button
          mode="contained"
          onPress={submitPhoneNumber}
          disabled={buttonDisabled || codeSent}
          style={styles.button}
        >
          أرسل رمزًا
        </Button>

        {codeSent && (
          <Button
            mode="contained"
            onPress={submitVerificationCode}
            style={styles.button}
          >
            تحقق من الرمز
          </Button>
        )}

        {codeSent && (
          <Button
            mode="text"
            onPress={submitPhoneNumber}
            disabled={buttonDisabled}
            style={styles.textButton}
          >
            {buttonDisabled
              ? `يمكنك استلام رمز جديد خلال ${timer} ثانية`
              : "أعد الإرسال"}
          </Button>
        )}
        <Modal visible={modalVisible} transparent={true} animationType="fade">
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            activeOpacity={1}
            onPress={() => setModalVisible(false)}
            onRequestClose={() => setModalVisible(false)}
          >
            <View
              padding={8}
              style={{
                width: "auto",
                maxWidth: "80%",
                minWidth: "50%",
                backgroundColor: Global.backgroundColor,
                borderRadius: 10,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                icon="alert-circle"
                color={"red"}
                size={50}
                style={styles.icon}
              />
              <Text style={{ marginHorizontal: 20, marginBottom: 10 }}>
                {mainStore.errorMessage}
              </Text>
              <Button
                mode="contained"
                onPress={() => setModalVisible(false)}
                style={{ alignSelf: "center", marginBottom: 20 }}
              >
                حسنًا
              </Button>
            </View>
          </TouchableOpacity>
        </Modal>

        {/* onPress={() =>
                  mainStore.errorMessage !== ""
                    ? setModalVisible(false)
                    : (setModalVisible(false),
                      navigation.navigate("HomeScreen")) */}
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    backgroundColor: Global.backgroundColor,
  },
  input: {
    width: "100%",
    marginTop: 20,
    textAlign: "left",
  },
  button: {
    marginTop: 20,
  },
  txt: {
    fontWeight: Global.fontWeight,
    marginVertical: 20,
    fontSize: 17,
  },
  countryCodeInput: {
    flex: 1.55,
  },
  phoneNumberInput: {
    flex: 3,
    marginLeft: 10,
    textAlign: "left",
  },
  inputContainer: {
    flexDirection: "row",
    width: "100%",
  },
});

export default ResetPasswordScreen;
