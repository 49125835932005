import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, View, Image } from "react-native";
import {
  IconButton,
  Title,
  Text,
  Button,
  MD3Colors,
  ActivityIndicator,
  MD2Colors,
  List,
} from "react-native-paper";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";
import { useIsFocused, useNavigationState } from "@react-navigation/native";
import Global from "../Global";

const ResultScreen = ({ navigation, route, props }) => {
  const [activeback, setactiveback] = useState(false);
  const isFocused = useIsFocused();
  const errorMessage = mainStore.errorMessage;
  const previousRouteName = props?.previousRouteName;
  const time = props?.time;
  const previousRouteName2 = useNavigationState(
    (state) => state.routes[state.index - 1]?.name
  );
  useEffect(() => {
    //isFocused ? mainStore.stackState('opened') : mainStore.stackState('closed');
  }, [isFocused, mainStore.stackOpened]);
  useEffect(() => {
    //  console.error(route.params[1]);
    const preventback = navigation.addListener("beforeRemove", (e) => {
      // Prevent the back action
      if (!activeback && previousRouteName2.includes("Deposit")) {
        e.preventDefault();
        navigation.navigate("HomeScreen");
      }
    });
    // Clean up the listener on unmount
    return preventback;
  }, [navigation, activeback]);
  const status = mainStore.transferStatus;

  const title =
    previousRouteName === "SendToSyScreen" && errorMessage === ""
      ? "تمت معالجة الحوالة بنجاح وإصدار وصل استلام"
      : previousRouteName === "externalTransfer" && errorMessage === ""
      ? `تم استلام طلبكم بنجاح وستتم معالجته خلال ${time} وسطيًا`
      : status === 200
      ? "تم إرسال الحوالة بنجاح!"
      : route?.params === "deposit_done"
      ? "تم استلام طلبك بنجاح"
      : "فشلت العملية";

  const subtitle =
    previousRouteName === "SendToSyScreen" && errorMessage === ""
      ? "يمكنك إرسال الوصل إلى الطرف المستلم لاستلام الحوالة فورًا!"
      : previousRouteName === "externalTransfer" && errorMessage === ""
      ? "يمكنك الآن مشاهدة تفاصيل المعاملة"
      : status === 200
      ? "سيكون المبلغ في حساب المستلم خلال دقيقة"
      : route?.params === "deposit_done"
      ? "يمكنك الآن مشاهدة تفاصيل المعاملة"
      : errorMessage;

  const iconName =
    previousRouteName === "SendToSyScreen" && errorMessage === ""
      ? "check"
      : previousRouteName === "externalTransfer" && errorMessage === ""
      ? "progress-check"
      : previousRouteName === "ManualToSyScreen" && errorMessage === ""
      ? "progress-check"
      : status === 200 || route?.params === "deposit_done"
      ? "check"
      : "close";
  const buttonTitle =
    previousRouteName === "SendToSyScreen" && errorMessage === ""
      ? "إشعار الاستلام"
      : previousRouteName === "externalTransfer" && errorMessage === ""
      ? "تعقب المعاملة"
      : previousRouteName === "ManualToSyScreen" && errorMessage === ""
      ? "تعقب المعاملة"
      : status === 200 || route?.params === "deposit_done"
      ? "تعقب المعاملة"
      : "العودة للصفحة الرئيسية";

  const handleButtonPress = async () => {
    setactiveback(true);
    if (
      status === 200 ||
      route?.params === "deposit_done" ||
      (previousRouteName === "SendToSyScreen" && errorMessage === "") ||
      (previousRouteName === "externalTransfer" && errorMessage === "") ||
      (previousRouteName === "ManualToSyScreen" && errorMessage === "")
    ) {
      if (
        previousRouteName === "SendToSyScreen" ||
        previousRouteName === "externalTransfer"
      ) {
        mainStore.load(true);
        setTimeout(() => {
          mainStore.load(false);
        }, 300);
      }
      const calcMethod =
        mainStore.latestTransactions[0].external_transfer
          ?.external_transfer_method?.calc_method;

      calcMethod === "sawa" || calcMethod === "agent"
        ? navigation.navigate(
            "TransactionDetails2",
            mainStore.latestTransactions[0]
          )
        : navigation.navigate(
            "TransactionDetailsScreen",
            mainStore.latestTransactions[0]
          );
    } else {
      navigation.navigate("MyTabs");
    }
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <IconButton
        icon={iconName}
        size={150}
        style={styles.icon}
        mode={"contained"}
        iconColor={
          previousRouteName === "externalTransfer" && errorMessage === ""
            ? Global.iconcolor
            : previousRouteName === "SendToSyScreen" && errorMessage === ""
            ? "green"
            : previousRouteName === "ManualToSyScreen" && errorMessage === ""
            ? Global.iconcolor
            : status === 200 || route?.params === "deposit_done"
            ? "green"
            : "red"
        }
      />
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{subtitle}</Text>
      <Button
        mode="contained"
        onPress={handleButtonPress}
        style={styles.button}
        contentStyle={{ height: 50 }}
        labelStyle={{ fontSize: 18 }}
      >
        {buttonTitle}
      </Button>
      {previousRouteName === "SendToSyScreen" && errorMessage === "" && (
        <View
          style={{
            padding: 10,
            marginBottom: 20,
            backgroundColor: Global.backgroundColor,
            width: "100%",
          }}
        >
          <Text style={{ fontSize: 18, writingDirection: "rtl" }}>
            أسئلة شائعة:
          </Text>

          <List.Section key={1}>
            <List.Accordion
              left={(props) => (
                <List.Icon
                  {...props}
                  icon="information-outline"
                  color={Global.primary}
                />
              )}
              titleNumberOfLines={7}
              title={"هل يستطيع المستفيد الاستلام فوراً؟"}
              titleStyle={{ lineHeight: 22 }}
            >
              <List.Item
                style={{ marginLeft: -70 }}
                title={
                  "نعم، يستطيع المستفيد الاستلام فوراً بعد مشاركتك للإشعار معه"
                }
                titleNumberOfLines={7}
                titleStyle={{ lineHeight: 22 }}
              />
            </List.Accordion>
          </List.Section>
          <List.Section key={2}>
            <List.Accordion
              left={(props) => (
                <List.Icon
                  {...props}
                  icon="information-outline"
                  color={Global.primary}
                />
              )}
              titleNumberOfLines={7}
              title={"من أي شركة يستطيع المستفيد الاستلام؟"}
              titleStyle={{ lineHeight: 22 }}
            >
              <List.Item
                style={{ marginLeft: -70 }}
                title={
                  "يستطيع المستفيد الاستلام من جميع الشركات الرسمية (الفاضل - المتحدة - النضال - الأدهم - شخاشيرو) ولا ينحصر فقط الاستلام في الشركات الموصى بها في رسالة الـ SMS التي سيتلقاها المستفيد"
                }
                titleNumberOfLines={7}
                titleStyle={{ lineHeight: 22 }}
              />
            </List.Accordion>
          </List.Section>

          <List.Section key={3}>
            <List.Accordion
              left={(props) => (
                <List.Icon
                  {...props}
                  icon="information-outline"
                  color={Global.primary}
                />
              )}
              titleNumberOfLines={7}
              title={"هل هذا الإشعار قانوني؟"}
              titleStyle={{ lineHeight: 22 }}
            >
              <List.Item
                style={{ marginLeft: -70 }}
                title={
                  "نعم، الاستلام عبر الإشعار الفوري قانوني تماماً وهو يتم عبر السيستم الرسمي للبنك المركزي للحوالات"
                }
                titleNumberOfLines={7}
                titleStyle={{ lineHeight: 22 }}
              />
            </List.Accordion>
          </List.Section>
        </View>
      )}
      {previousRouteName === "" && (
        //  {should be previousRouteName === 'SendToSyScreen'}
        <View>
          <IconButton
            onPress={() => navigation.navigate("ContactsScreen")}
            icon="file-download-outline"
            accessibilityLabel="lll"
            size={65}
            iconColor={Global.iconcolor}
            style={{
              borderWidth: 1,
              borderRadius: 10,
              borderColor: Global.placeholder,
              justifyContent: "center",
              alignSelf: "center",
              marginBottom: 10,
              marginTop: 20,
            }}
          />
          <Text alignSelf={"center"}>تحميل الوصل</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Global.backgroundColor,
    paddingHorizontal: 15,
  },
  icon: {
    marginBottom: 16,
    backgroundColor: Global.cardcolor,
  },
  title: {
    fontSize: 25,
    fontWeight: Global.fontWeight,
    lineHeight: 35,
    textAlign: "center",
    marginBottom: 16,
    paddingTop: 20,
  },
  subtitle: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 32,
    fontWeight: Global.fontWeight,
    lineHeight: 25,
  },
  button: {
    width: "100%",
    marginBottom: 20,
  },
});

export default observer(ResultScreen);
