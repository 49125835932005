import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Alert,
  ScrollView,
  Platform,
  TouchableOpacity,
  Linking,
  Image,
  Modal,
} from "react-native";
import {
  TextInput,
  Button,
  Text,
  Card,
  Title,
  Paragraph,
  Snackbar,
  IconButton,
  Menu,
} from "react-native-paper";
import mainStore from "../../stores/MainStore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Global from "../../Global";
import Icon from "react-native-vector-icons/MaterialIcons";
//import DeviceInfo from "react-native-device-info";

const AccountScreen = ({ navigation }) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [editing, setEditing] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [version, setVersion] = useState("");
  const [sortMenuVisible, setSortMenuVisible] = useState("");

  useEffect(() => {
    if (mainStore.deleted) {
      setModal2Visible(false);
      setTimeout(() => {
        setModalVisible(true);
      }, 500);
    }
  }, [mainStore.deleted]);
  useEffect(() => {
    //   const version = DeviceInfo.getVersion();
    setVersion(version);
  }, []);

  const onDismissSnackBar = () => setSnackbarVisible(false);
  const handleUpdate = () => {
    if (editing) {
      setSnackbarVisible(true);
    }
    setEditing(!editing);
  };

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      setSnackbarVisible(true);
    } else {
      Alert.alert("New password and confirm password do not match");
    }
  };

  const toggleChangePassword = () => {
    navigation.navigate("ResetPasswordScreen", "li");

    // setShowChangePassword(!showChangePassword);
  };

  const nobalabce = mainStore.balance === "0.00";
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <>
      <View
        style={{
          // alignItems: 'flex-end',
          justifyContent: "center",
          //padding: 10,
          position: "absolute",
          zIndex: 999,
          transform: [{ rotate: "270deg" }],
          // right: 0,
          right: -35,
          top: "50%",
        }}
      >
        <Button
          icon="message-outline" // Choose an appropriate icon from Material Icons
          //icon={() => <Icon name="message"/>}
          mode="contained"
          onPress={() => navigation.navigate("SuggestionsScreen")}
          style={{
            borderRadius: 5,
            //   // Adjustable padding for dynamic width
            // height: 40, // Set the height according to your design needs
            // width: 150,
            justifyContent: "center", // Center content vertically
            backgroundColor: Global.isDark
              ? "rgba(235, 183, 3, 0.8)"
              : "rgba(38, 62, 91, 0.9)",
          }}
          contentStyle={{
            // flexDirection: "row-reverse",
            //
            // height: 300,
            paddingRight: 25,
          }}
          labelStyle={{ marginRight: 0 }}
        >
          اقتراح
        </Button>
      </View>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Text style={styles.welcome}>أهلا بك، {mainStore.userName}</Text>
          <Card style={styles.card}>
            <Card.Content>
              <Text
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  color: Global.textcolor,
                  fontWeight: Platform.OS === "ios" ? "900" : "normal",
                }}
              >
                المعلومات الشخصية
              </Text>
              {editing ? (
                <View>
                  <TextInput
                    label="الاسم"
                    value={mainStore.userName}
                    onChangeText={(text) => setName(text)}
                    mode="outlined"
                    style={styles.txtinput}
                  />
                  <TextInput
                    label="البريد الإلكتروني"
                    value={mainStore.email}
                    onChangeText={(text) => setEmail(text)}
                    mode="outlined"
                    style={styles.txtinput}
                  />
                  <TextInput
                    label="رقم الهاتف"
                    value={mainStore.phone}
                    onChangeText={(text) => setPhone(text)}
                    mode="outlined"
                    style={styles.txtinput}
                  />
                </View>
              ) : (
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paragraph style={styles.p}>الاسم</Paragraph>
                    <Paragraph style={styles.p}>{mainStore.userName}</Paragraph>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paragraph style={styles.p}>البريد الإلكتروني</Paragraph>
                    <Paragraph style={styles.p}>{mainStore.email}</Paragraph>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paragraph style={styles.p}>رقم الهاتف</Paragraph>
                    <Paragraph style={styles.p}>{mainStore.phone}</Paragraph>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paragraph style={styles.p}>حالة الحساب</Paragraph>

                    <View
                      style={{
                        alignSelf: "flex-start",

                        flexDirection: "row",
                      }}
                    >
                      <Paragraph style={styles.p}>
                        {mainStore.isVerified === 1 ? "مؤكد" : "غير مؤكد"}
                      </Paragraph>
                      {mainStore.isVerified === 1 ? (
                        <Icon name="verified-user" size={25} color={"green"} />
                      ) : (
                        <View style={{ flexDirection: "row" }}>
                          <Icon name="warning" size={25} color={"red"} />
                          <Button
                            onPress={() =>
                              navigation.navigate("ConfirmationScreen")
                            }
                            style={{
                              marginTop: -5,
                              marginLeft: -7,
                            }}
                          >
                            | تأكيد
                          </Button>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              )}
              <Button
                mode="contained"
                onPress={handleUpdate}
                style={[styles.button, { display: "none" }]}
              >
                {editing ? "حفظ المعلومات" : "تحديث المعلومات"}
              </Button>
            </Card.Content>
          </Card>
          <Card style={styles.card}>
            <Card.Content>
              <Text
                style={{
                  fontSize: 20,
                  color: Global.textcolor,
                  fontWeight: Platform.OS === "ios" ? "900" : "normal",
                }}
              >
                إعدادات الأمان
              </Text>
              <Button onPress={toggleChangePassword} style={styles.button}>
                {showChangePassword ? "الرجوع" : "تغيير كلمة المرور"}
              </Button>
              <Menu
                contentStyle={{
                  backgroundColor: Global.backgroundColor,
                  alignSelf: "center",
                }}
                style={{
                  marginTop: 30,
                  //    alignSelf: 'center',
                  marginLeft: "20%",
                }}
                visible={sortMenuVisible}
                onDismiss={() => setSortMenuVisible(false)}
                anchor={
                  <Text
                    onPress={() => setSortMenuVisible(true)}
                    style={{ alignSelf: "center", color: Global.primary }}
                  >
                    المزيد{" "}
                  </Text>
                }
              >
                <Menu.Item
                  onPress={() => {
                    setModal2("");
                    mainStore.balance !== "0.00"
                      ? setModal2(
                          "لا يمكن حذف حسابك بوجود رصيد جاري، الرجاء سحب رصيدك أو انتظار الموافقة على عملية السحب إذا قمت بسحبه بالفعل"
                        )
                      : setModal2(
                          "حذف الحساب عملية لا يمكن التراجع عنها، ستفقد كافة بياناتك السابقة بما في ذلك سجل معاملاتك المالية، هل أنت متأكد من رغبتك بحذف حسابك على يامرسال؟"
                        );
                    console.error(mainStore.balance);
                    setModal2Visible(true);
                  }}
                  title="حذف الحساب"
                />
              </Menu>
              {showChangePassword && (
                <View>
                  <TextInput
                    label="كلمة المرور الحالية"
                    value={oldPassword}
                    onChangeText={(text) => setOldPassword(text)}
                    secureTextEntry
                    mode="outlined"
                    style={styles.txtinput}
                  />
                  <TextInput
                    label="كلمة المرور الجديدة"
                    value={newPassword}
                    onChangeText={(text) => setNewPassword(text)}
                    secureTextEntry
                    mode="outlined"
                    style={styles.txtinput}
                  />
                  <TextInput
                    label="تأكيد كلمة المرور"
                    value={confirmPassword}
                    onChangeText={(text) => setConfirmPassword(text)}
                    secureTextEntry
                    mode="outlined"
                    style={styles.txtinput}
                  />
                  <Button
                    mode="contained"
                    onPress={handleChangePassword}
                    style={styles.button}
                  >
                    تأكيد
                  </Button>
                </View>
              )}
            </Card.Content>
          </Card>
          <Button
            mode="contained"
            onPress={() => {
              AsyncStorage.removeItem("accesstoken");
              mainStore.logout();
            }}
            style={styles.button}
          >
            تسجيل الخروج
          </Button>
          {/*  <Button
          mode="text"
          onPress={() => {
            setModal2('');
            mainStore.balance !== '0.00'
              ? setModal2(
                  'لا يمكن حذف حسابك بوجود رصيد جاري، الرجاء سحب رصيدك أو انتظار الموافقة على عملية السحب إذا قمت بسحبه بالفعل',
                )
              : setModal2(
                  'حذف الحساب عملية لا يمكن التراجع عنها، ستفقد كافة بياناتك السابقة بما في ذلك سجل معاملاتك المالية، هل أنت متأكد من رغبتك بحذف حسابك على يامرسال؟',
                );
            console.error(mainStore.balance);
            setModal2Visible(true);
          }}
          style={[
            styles.button,
            {
              marginTop: 20,
              marginHorizontal: 100,
              borderRadius: 10,
            },
          ]}>
          حذف الحساب
        </Button> */}
          <Button
            mode="contained-tonal"
            onPress={() => {
              navigation.navigate("FaqScreen");
            }}
            style={[
              styles.button,
              {
                borderRadius: 10,
                marginTop: 15,
              },
            ]}
          >
            الأسئلة الشائعة
          </Button>
          <Button
            mode="text"
            onPress={() => {
              const URL = "https://yamersal.com/privacy-policy.html";
              Linking.openURL(URL).catch((err) => {
                console.error("Failed to open url:", err);
              });
            }}
            style={[
              styles.button,
              {
                borderRadius: 10,
              },
            ]}
          >
            سياسة الخصوصية
          </Button>

          <View
            style={{
              flexDirection: "row",
              paddingBottom: 40,
              justifyContent: "center",
              //  alignSelf: 'center',
            }}
          >
            <IconButton
              onPress={() => {
                const URL = `https://www.facebook.com/yamersal.fb?mibextid=ZbWKwL`;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open WhatsApp:", err);
                });
              }}
              icon={() => (
                <Image
                  source={{
                    uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/600px-Facebook_Logo_%282019%29.png",
                  }}
                  style={{
                    width: Global.isios ? "80%" : "80%",
                    height: Global.isios ? "80%" : "80%",
                    resizeMode: "contain",
                  }}
                />
              )}
              size={30}
              iconColor={Global.iconcolor}
              style={{
                borderColor: Global.placeholder,
                justifyContent: "center",
              }}
            />
            <IconButton
              onPress={() => {
                const URL = `https://instagram.com/yamersal20?igshid=MWo0YjllZW9rc3h1dQ==`;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open WhatsApp:", err);
                });
              }}
              icon={() => (
                <Image
                  source={{
                    uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png",
                  }}
                  style={{
                    width: Global.isios ? "80%" : "80%",
                    height: Global.isios ? "80%" : "80%",
                    resizeMode: "contain",
                  }}
                />
              )}
              size={30}
              iconColor={Global.iconcolor}
              style={{
                borderColor: Global.placeholder,
                justifyContent: "center",
              }}
            />
            <IconButton
              onPress={() => {
                const URL = `https://t.me/yamersal`;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open WhatsApp:", err);
                });
              }}
              icon={() => (
                <Image
                  source={{
                    uri: "https://seeklogo.com/images/T/telegram-logo-AD3D08A014-seeklogo.com.png",
                  }}
                  style={{
                    width: Global.isios ? "80%" : "80%",
                    height: Global.isios ? "80%" : "80%",
                    resizeMode: "contain",
                  }}
                />
              )}
              size={30}
              iconColor={Global.iconcolor}
              style={{
                borderColor: Global.placeholder,
                justifyContent: "center",
              }}
            />
          </View>
          <Modal
            backdropOpacity={nobalabce ? 0.95 : 0.95}
            visible={modal2Visible}
            animationInTiming={350}
            animationOutTiming={800}
            backdropTransitionInTiming={350}
            backdropTransitionOutTiming={0}
          >
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              activeOpacity={1}
              onPress={() => setModal2Visible(false)}
              onRequestClose={() => setModal2Visible(false)}
            >
              <View
                padding={8}
                style={{
                  width: "100%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                }}
                alignItems={"center"}
                alignContent={"center"}
              >
                <IconButton
                  icon="information-outline"
                  iconColor={nobalabce ? "red" : Global.iconcolor}
                  size={100}
                />
                <Text
                  paddingBottom={10}
                  style={{
                    textAlign: "center",
                    fontWeight: Global.fontWeight,
                    lineHeight: 23,
                  }}
                >
                  {modal2}
                </Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <Button
                  mode="contained"
                  style={{
                    width: 150,
                    height: 50,
                    justifyContent: "center",
                    margin: 10,
                    fontFamily: "Tajawal-Bold",
                  }}
                  onPress={async () => {
                    nobalabce && (await mainStore.deleteAccount());
                    !nobalabce && setModal2Visible(false);
                  }}
                >
                  موافق
                </Button>
                {nobalabce && (
                  <Button
                    mode="contained"
                    style={{
                      width: 150,
                      //   alignSelf: 'center',
                      height: 50,
                      justifyContent: "center",
                      margin: nobalabce ? 10 : 0,
                      fontFamily: "Tajawal-Bold",
                      display: nobalabce ? "flex" : "none",
                    }}
                    onPress={() => setModal2Visible(false)}
                  >
                    إلغاء
                  </Button>
                )}
              </View>
            </TouchableOpacity>
          </Modal>
          <Modal
            visible={modalVisible}
            animationInTiming={350}
            animationOutTiming={800}
            backdropTransitionInTiming={350}
            backdropOpacity={1}
            backdropTransitionOutTiming={0}
          >
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              activeOpacity={1}
              onPress={() => {
                AsyncStorage.removeItem("accesstoken");
                mainStore.logout();
                setModalVisible(false);
              }}
              onRequestClose={() => {
                AsyncStorage.removeItem("accesstoken");
                mainStore.logout();
                setModalVisible(false);
              }}
            >
              <View
                padding={8}
                style={{
                  width: "100%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                }}
                alignItems={"center"}
                alignContent={"center"}
              >
                <IconButton
                  icon="check-circle-outline"
                  iconColor={"green"}
                  size={100}
                />
                <Text
                  paddingBottom={10}
                  style={{
                    textAlign: "center",
                    fontWeight: Global.fontWeight,
                    lineHeight: 23,
                  }}
                >
                  تم حذف حسابك بنجاح!
                </Text>
              </View>

              <Button
                mode="contained"
                style={{
                  alignSelf: "center",
                  width: "100%",
                  height: 50,
                  justifyContent: "center",
                  margin: 10,
                  fontFamily: "Tajawal-Bold",
                }}
                onPress={() => {
                  AsyncStorage.removeItem("accesstoken");
                  mainStore.logout();
                  setModalVisible(false);
                }}
              >
                موافق
              </Button>
            </TouchableOpacity>
          </Modal>
          <Snackbar
            visible={snackbarVisible}
            onDismiss={onDismissSnackBar}
            duration={2000}
            style={{
              backgroundColor: "lightgray",
              right: -20,
              bottom: 20,
            }}
          >
            <Text style={{ color: "black" }}>تم تحديث معلوماتك بنجاح! </Text>
          </Snackbar>
        </ScrollView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingTop: 20,
    backgroundColor: Global.backgroundColor,
  },
  welcome: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
    color: Global.textcolor,
  },
  card: {
    marginBottom: 20,
    backgroundColor: Global.cardcolor,
  },
  button: {
    marginTop: 10,
    borderRadius: 10,
  },
  p: {
    marginBottom: 10,
    fontWeight: "600",
    color: Global.textcolor,
  },
  txtinput: {
    marginBottom: 10,
  },
});

export default observer(AccountScreen);
