import { useNavigation } from "@react-navigation/native";
import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  Keyboard,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
  BackHandler,
  Modal,
} from "react-native";
import {
  TextInput,
  Button,
  Text,
  Divider,
  Checkbox,
  useTheme,
  IconButton,
} from "react-native-paper";
import { observer } from "mobx-react-lite";
import mainStore from "../../stores/MainStore";
import { ActivityIndicator, MD2Colors } from "react-native-paper";
import Global from "../../Global";
import CountryFlag from "react-native-country-flag";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

const LoginScreen = () => {
  const { colors } = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState('');
  // const [password, setPassword] = useState('');
  // const [CountryCode, setCountryCode] = useState('+963');
  const [phoneNumber, setPhoneNumber] = useState(
    mainStore.isTest ? "536000001" : ""
  );
  const [password, setPassword] = useState(mainStore.isTest ? "TesT!100" : "");
  const [CountryCode, setCountryCode] = useState("");
  const navigation = useNavigation();
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setCountryCode(mainStore.callingCode.replace("+", ""));
  }, [mainStore.callingCode]);
  console.error(CountryCode);
  const callingCodeToIso = require("../../callingcode.json");

  const getIsoCode = (callingCode) => {
    return callingCodeToIso[callingCode];
  };
  const handleLoginPress = async () => {
    // mainStore.login('+905363915811', 'password');
    await mainStore.login(`+${CountryCode}${phoneNumber}`, password);
    //console.log(`{+${CountryCode}${phoneNumber}}`, password);

    mainStore.errorMessage !== "" &&
      mainStore.loading === false &&
      setModalVisible(true);
  };
  if (mainStore.loading || mainStore.callingCode === "") {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        justifyContent: "center",
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        justifyContent={"center"}
        style={{
          flex: 1,
          backgroundColor: Global.backgroundColor,
        }}
      >
        <View style={styles.container}>
          <Image
            source={require("../../assets/logo.webp")}
            style={{ width: 125, height: 125, marginBottom: 20 }}
          />
          <Text style={styles.title}>محفظة يا مرسال</Text>
          <View style={styles.inputContainer}>
            <TextInput
              label="رقم هاتفك"
              mode="outlined"
              keyboardType="phone-pad"
              value={phoneNumber}
              onChangeText={setPhoneNumber}
              style={styles.phoneNumberInput}
            />
            <TextInput
              label="رمز البلد"
              placeholder="00"
              placeholderTextColor={"gray"}
              value={CountryCode}
              onChangeText={setCountryCode}
              style={styles.countryCodeInput}
              keyboardType="phone-pad"
              mode="outlined"
              contentStyle={{ marginLeft: 60, marginRight: -10 }}
            />
            <Text
              style={[
                {
                  alignSelf: "center",
                  fontSize: 30,
                  position: "absolute",
                  left: 55,
                  fontWeight: Global.fontWeight,
                  color: Global.textcolor,
                  bottom: 6,
                },
                Platform.OS === "ios" && {},
              ]}
            >
              +{" "}
            </Text>
            {getIsoCode(CountryCode) !== undefined && (
              <CountryFlag
                style={{
                  alignSelf: "center",
                  position: "absolute",
                  left: 10,
                  bottom: 13,
                }}
                isoCode={
                  getIsoCode(CountryCode) === undefined
                    ? ""
                    : getIsoCode(CountryCode)
                }
                size={25}
              />
            )}
          </View>
          <View
            style={{
              display:
                !/^[0-9]*$/.test(phoneNumber) || phoneNumber[0] === "0"
                  ? "flex"
                  : "none",
              flexDirection: "row",
              alignItems: "center",
              marginTop: -15,
              marginBottom: 5,
              marginHorizontal: "-30%",
            }}
          >
            <IconButton iconColor="red" icon="information-outline" size={20} />
            <Text
              style={{
                color: "red",
                fontWeight: Global.fontWeight,
                lineHeight: 20,
              }}
            >
              {phoneNumber[0] === "0"
                ? "الرجاء إدخال رقم هاتفك بدون صفر في بدايته"
                : "يمنع إدخال الحروف والمسافات والرموز"}
            </Text>
          </View>
          <TextInput
            label="كلمة المرور"
            mode="outlined"
            value={password}
            onChangeText={setPassword}
            secureTextEntry={!showPassword}
            style={styles.input}
            left={
              <TextInput.Icon
                onPress={() => setShowPassword(!showPassword)}
                icon={!showPassword ? "eye-off" : "eye"}
                iconColor={Global.primary}
                style={{
                  borderColor: Global.placeholder,
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              />
            }
          />
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              mode="contained"
              onPress={handleLoginPress}
              contentStyle={{ width: "150%", alignSelf: "center" }}
              style={styles.button}
            >
              تسجيل الدخول
            </Button>
            <Text style={{ marginVertical: 10, lineHeight: 22 }}> أو </Text>
            <Button
              mode="outlined"
              onPress={() => navigation.navigate("SignupScreen")}
              style={styles.link}
            >
              التسجيل
            </Button>
          </View>

          <Divider style={styles.divider} />

          <Button
            mode="text"
            onPress={() => navigation.navigate("ResetPasswordScreen")}
            style={styles.link}
          >
            نسيت كلمة المرور؟
          </Button>

          <Modal visible={modalVisible} transparent={true} animationType="fade">
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              activeOpacity={1}
              onPress={() => setModalVisible(false)}
              onRequestClose={() => setModalVisible(false)}
            >
              <View
                padding={8}
                style={{
                  width: "auto",
                  maxWidth: "80%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  icon="alert-circle"
                  color={"red"}
                  size={50}
                  style={styles.icon}
                />
                <Text
                  style={[
                    styles.errorMessage,
                    { color: colors.text, marginHorizontal: 20 },
                  ]}
                >
                  {mainStore.errorMessage}
                </Text>
                <Button
                  mode="contained"
                  onPress={() => setModalVisible(false)}
                  style={{ alignSelf: "center", marginBottom: 20 }}
                >
                  حسنًا
                </Button>
              </View>
            </TouchableOpacity>
          </Modal>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Global.backgroundColor,
    paddingHorizontal: "25%",
    marginVertical: "25%",
    // paddingTop: 30,
  },

  title: {
    fontSize: 24,
    marginBottom: 30,
    fontWeight: Global.fontWeight,
    marginHorizontal: -50,
  },
  input: {
    width: "160%",
    marginBottom: 20,
  },
  button: {
    // width: 300,
    // marginVertical: 16,
    borderRadius: 5,
    //flex: 5,
  },
  divider: {
    height: 1,
    width: "160%",
    marginVertical: 20,
  },
  link: {
    // width: '75%',

    // marginVertical: 8,
    borderRadius: 5,
    //flex: 5,
  },
  countryCodeInput: {
    flex: 2,
  },
  phoneNumberInput: {
    flex: 3,
    marginLeft: 10,
    textAlign: "left",
  },
  inputContainer: {
    flexDirection: "row",
    marginBottom: 20,
    width: "160%",
  },
  showPasswordContainer: {
    flexDirection: "row",
    alignItems: "center",
  },

  modalContent: {
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
    borderRadius: 8,
    alignSelf: "center",
  },
  icon: {
    marginBottom: 16,
  },
  errorMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 16,
  },
  okButton: {
    alignSelf: "center",
  },
});

export default observer(LoginScreen);
