import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Platform,
  TouchableOpacity,
  Text as Txt,
  KeyboardAvoidingView,
  Modal,
  ScrollView,
} from "react-native";
import {
  Button,
  TextInput,
  Text,
  RadioButton,
  Avatar,
  IconButton,
  Portal,
  Dialog,
} from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import Global from "../Global";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";
import SkeletonPlaceholder from "../react-native-skeleton-placeholder-master/src/skeleton-placeholder";
import debounce from "lodash.debounce";
const MySkeletonPlaceholder = ({
  width = 150,
  height = 20,
  borderRadius = 5,
}) => {
  return (
    <SkeletonPlaceholder
      borderRadius={borderRadius}
      speed={300}
      backgroundColor={Global.placeholder}
    >
      <SkeletonPlaceholder.Item width={width} height={height} />
    </SkeletonPlaceholder>
  );
};
const UsdtWithdraw = () => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [editing, setediting] = useState(false);
  const [editing2, setediting2] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [recipientAmount, setRecipientAmount] = useState(" ");
  const navigation = useNavigation();
  const [amount, setAmount] = useState("100");
  const [SorF, setSorF] = useState("");
  const [address, setaddress] = useState("");
  const [memo, setMemo] = useState("");
  const [modal2Visible, setModal2Visible] = useState(false);
  const [amountFoucs, setAmountFoucs] = useState(false);
  const [rAmountFoucs, setRAmountFoucs] = useState(false);
  const [network, setNetwork] = useState({
    name: "Tron (TRC20)",
    value: "trc20",
  });
  const [dialogVisible, setDialogVisible] = useState(false);
  const debouncedTextChange = debounce(async (amount) => {
    setediting(true);
    if (!editing2) {
      setShowSkeleton(true);
      await mainStore.calc(amount, "usd", "usd", "withdrawal", "bank", true);
      mainStore.feeDetails.receiving_amount < 0 || amount === ""
        ? setRecipientAmount("0")
        : setRecipientAmount(
            Number(mainStore.feeDetails.receiving_amount).toFixed(2).toString()
          );
      setShowSkeleton(false);
    }
    setediting(false);
  }, 500);
  const debouncedTextChange2 = debounce(async (amount) => {
    setediting2(true);

    if (!editing) {
      setShowSkeleton(true);
      await mainStore.calc(amount, "usd", "usd", "withdrawal", "bank", false);
      mainStore.feeDetails.receiving_amount < 0 || recipientAmount === ""
        ? setAmount("0")
        : setAmount(
            Number(mainStore.feeDetails.sending_amount).toFixed(2).toString()
          );
      setShowSkeleton(false);
    }
    setediting2(false);
  }, 500);
  useEffect(() => {
    return () => {
      debouncedTextChange.cancel();
    };
  }, []);
  useEffect(() => {
    debouncedTextChange(amount);

    return () => {
      debouncedTextChange2.cancel();
    };
  }, []);

  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    //amount === '' && debouncedTextChange('0');
  }, [amount]);

  useEffect(() => {
    if (isFirstRender2.current) {
      isFirstRender2.current = false;
      return;
    }
    //recipientAmount === '' && debouncedTextChange2('0');
  }, [recipientAmount]);

  const handleConfirm = async () => {
    await mainStore.withdraw(
      amount,
      "USDT",
      network.name === "TON" ? address + " - " + memo : address,
      network.value
    );

    mainStore.errorMessage !== "" && mainStore.loading === false
      ? setSorF(mainStore.errorMessage)
      : setSorF(
          "تم استلام طلبكم بنجاح سيتم إيداع المبلغ في المحفظة المعطاة خلال فترة وجيزة"
        );
    setModal2Visible(true);
  };
  if (mainStore.loading === true) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "height" : "height"}
      style={{ flex: 1, backgroundColor: Global.backgroundColor }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        style={{ flex: 1, backgroundColor: Global.backgroundColor }}
      >
        <View style={styles.container}>
          <Portal>
            <Dialog
              style={{ width: "95%", alignSelf: "center" }}
              visible={dialogVisible}
              onDismiss={() => setDialogVisible(false)}
            >
              <Dialog.Title style={{ writingDirection: "rtl" }}>
                اختر الشبكة:
              </Dialog.Title>
              <ScrollView showsVerticalScrollIndicator={false}>
                <Dialog.Content>
                  <TouchableOpacity
                    onPress={() => {
                      setNetwork({
                        name: "Tron (TRC20)",
                        value: "trc20",
                      });
                      setDialogVisible(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 0.5,
                        borderColor: Global.graycolor,
                        padding: 10,
                      }}
                    >
                      <Text style={{ fontSize: 16 }}>Tron (TRC20)</Text>
                      <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                        وقت المعالجة المتوقع: خلال دقيقة
                      </Text>
                      <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                        الحد الأدنى: 3 دولار
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ marginTop: 20 }}
                    onPress={() => {
                      setNetwork({
                        name: "BSC (BEP20)",
                        value: "bep20",
                      });

                      setDialogVisible(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 0.5,
                        borderColor: Global.graycolor,
                        padding: 10,
                      }}
                    >
                      <Text style={{ fontSize: 16 }}>BSC (BEP20)</Text>
                      <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                        وقت المعالجة المتوقع: خلال دقيقة
                      </Text>
                      <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                        الحد الأدنى: 3 دولار
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ marginTop: 20 }}
                    onPress={() => {
                      setNetwork({
                        name: "TON",
                        value: "ton",
                      });

                      setDialogVisible(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 0.5,
                        borderColor: Global.graycolor,
                        padding: 10,
                      }}
                    >
                      <Text style={{ fontSize: 16 }}>TON</Text>
                      <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                        وقت المعالجة المتوقع: خلال دقيقة
                      </Text>
                      <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                        الحد الأدنى: 3 دولار
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Dialog.Content>
              </ScrollView>

              <Dialog.Actions style={{ justifyContent: "center" }}>
                <Button onPress={() => setDialogVisible(false)}>موافق</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
          <Text style={styles.title}>:عنوان محفظتك</Text>
          <TextInput
            label="عنوان المحفظة"
            value={address}
            onChangeText={setaddress}
            style={styles.input}
            //  keyboardType="twitter"
            mode="outlined"
            left={<TextInput.Icon icon="wallet" />}
          />
          {network.name === "TON" && (
            <>
              <Text style={[styles.title, { writingDirection: "rtl" }]}>
                رقم الـ Memo/Tag:
              </Text>
              <TextInput
                label="Memo/Tag"
                value={memo}
                onChangeText={setMemo}
                style={styles.input}
                //  keyboardType="twitter"
                mode="outlined"
                left={<TextInput.Icon icon="comment" />}
              />
            </>
          )}

          <Text style={styles.title}>:الشبكة</Text>

          <Button
            onPress={setDialogVisible}
            icon={"chevron-down"}
            style={{ borderRadius: 5, marginBottom: 20 }}
            labelStyle={{ fontSize: 16 }}
            contentStyle={{ margin: 3 }}
            mode="outlined"
          >
            {network.name}
          </Button>
          <Text style={styles.title}>:المبلغ المراد سحبه بالدولار</Text>
          <TextInput
            label="المبلغ"
            value={amountFoucs ? amount : Global.arabicToNumeric(amount)}
            onChangeText={(text) => {
              const normalizedText = text.replace(/,/g, ".");
              setAmount(normalizedText);
              debouncedTextChange(normalizedText);
            }}
            onFocus={() => {
              setAmountFoucs(true);
            }}
            onBlur={() => {
              setAmountFoucs(false);
            }}
            style={styles.recipientAmount}
            left={<TextInput.Icon icon="currency-usd" />}
            keyboardType="decimal-pad"
            mode="outlined"
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              alignContent: "center",
              marginBottom: 10,
              //marginTop: 20,
            }}
          >
            <Text style={[styles.alert]}>:رصيدك الحالي</Text>
            <TouchableOpacity
              style={{
                borderBottomWidth: 1,
                borderColor: Global.primary,
              }}
              onPress={() => {
                const normalizedText = mainStore.balance.replace(/,/g, "");
                setAmount(normalizedText);
                debouncedTextChange(normalizedText);
              }}
            >
              <Txt
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                  color: Global.primary,
                }}
              >
                ${Global.arabicToNumeric(mainStore.balance)}
              </Txt>
            </TouchableOpacity>
          </View>
          <View style={styles.hintBox}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 5,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Icon
                  name="minus-circle-outline"
                  size={20}
                  style={{ marginLeft: 3, color: Global.graycolor }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {" "}
                    {mainStore.feeDetails.fee.toLocaleString("en") +
                      " " +
                      "دولار"}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>أجور التحويل</Text>
            </View>
          </View>
          <Text style={styles.title}>:ستحصل على</Text>
          <TextInput
            value={
              rAmountFoucs
                ? recipientAmount
                : Global.arabicToNumeric(recipientAmount)
            }
            onChangeText={(text) => {
              const normalizedText = text.replace(/,/g, ".");
              setRecipientAmount(normalizedText);
              debouncedTextChange2(normalizedText);
            }}
            onFocus={() => {
              setRAmountFoucs(true);
            }}
            onBlur={() => {
              setRAmountFoucs(false);
            }}
            placeholderTextColor={Global.placeholder}
            style={styles.recipientAmount}
            left={<TextInput.Affix text="USDT" />}
            mode="outlined"
            keyboardType="decimal-pad"
          />

          <View
            style={{
              alignSelf: "center",
              borderRadius: 5,
              marginVertical: 20,
              // direction: "rtl",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <IconButton icon="information-outline" size={20} />
              <Text
                style={{
                  fontWeight: Global.fontWeight,
                  lineHeight: 25,
                  color: "red",
                  direction: "rtl",
                }}
              >
                تأكد من عنوان محفظتك جيدًا، في حال إيداع المبلغ إلى المحفظة
                المعطاة لن تتمكن من التراجع عن العملية!
              </Text>
            </View>
          </View>

          <Button
            mode="contained"
            onPress={handleConfirm}
            disabled={
              !(Number(mainStore.balance) >= amount) ||
              amount <= 0 ||
              recipientAmount <= 0 ||
              editing ||
              editing2
            }
            contentStyle={{ height: 50 }}
            labelStyle={{ fontSize: 18, paddingTop: 10 }}
            style={styles.button}
          >
            تأكيد
          </Button>

          <Modal
            visible={modal2Visible}
            transparent={true}
            animationType="fade"
          >
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              activeOpacity={1}
              onPress={() => setModal2Visible(false)}
              onRequestClose={() => setModal2Visible(false)}
            >
              <View
                padding={8}
                style={{
                  width: "90%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  icon="information-outline"
                  color={"red"}
                  size={50}
                />
                <Text
                  paddingBottom={10}
                  style={{
                    textAlign: "center",
                    fontWeight: Global.fontWeight,
                    lineHeight: 23,
                    marginHorizontal: 20,
                    marginBottom: 20,
                  }}
                >
                  {SorF}
                </Text>
              </View>

              <Button
                mode="contained"
                style={{
                  alignSelf: "center",
                  width: "90%",
                  height: 50,
                  justifyContent: "center",
                  margin: 10,
                  fontFamily: "Tajawal-Bold",
                }}
                onPress={() =>
                  mainStore.errorMessage !== ""
                    ? setModal2Visible(false)
                    : (setModal2Visible(false),
                      navigation.navigate("HomeScreen"))
                }
              >
                موافق
              </Button>
            </TouchableOpacity>
          </Modal>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingBottom: 100,
    backgroundColor: Global.backgroundColor,
  },
  logo: {
    alignSelf: "center",
    marginBottom: 25,
    //marginTop: 50,
    width: 110,
    height: 110,
    resizeMode: "contain",
  },
  label: {
    marginBottom: 10,
    fontWeight: Global.fontWeight,
    lineHeight: 20,
  },
  title: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: Global.fontWeight,
  },
  title2: {
    fontSize: 16,
    marginBottom: 15,
    marginTop: -5,
    fontWeight: Global.fontWeight,
    direction: "rtl",
  },
  input: {
    marginBottom: 20,
    textAlign: "auto",
  },
  button: { borderRadius: 5 },
  hintBox: {
    borderRadius: 10,
    padding: 8,
  },
  labelText: {
    color: Global.graycolor,
    fontSize: 15,
    fontWeight: Global.fontWeight,
    lineHeight: 20,
    alignSelf: "flex-end",
  },
  alert: {
    fontSize: 16,
    marginStart: 5,
  },
  recipientAmount: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "auto",
  },
  ios: {
    fontWeight: Global.fontWeight,
    fontSize: 15,
    fontFamily: "Tajawal",
    color: Global.textcolor,
  },
});

export default observer(UsdtWithdraw);
