import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  Modal,
  Image,
  ScrollView,
} from "react-native";
import {
  TextInput,
  Button,
  Title,
  Text,
  HelperText,
  IconButton,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import Global from "../../Global";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const ResetPassword2 = ({ navigation, route, props }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [password, setpassword] = useState("");
  const [passwordconfirm, setpasswordconfirm] = useState("");
  const [SorF, setSorF] = useState("");

  const submitPassword = async () => {
    await mainStore.resetPassword(
      props.phoneNumber,
      password,
      passwordconfirm,
      props.verificationCode
    );
    mainStore.errorMessage !== "" && mainStore.loading === false
      ? setSorF(mainStore.errorMessage)
      : setSorF("تم تحديث كلمة المرور بنجاح");
    setModal2Visible(true);
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        // paddingHorizontal: 16,
        //  backgroundColor: Global.backgroundColor,
      }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.container}>
        <Icon name="lock-reset" size={100} color={Global.graycolor} />

        <Text style={styles.txt}>
          تم تأكيد رقمك بنجاح والآن يمكنك تغيير كلمة المرور
        </Text>

        <TextInput
          label="كلمة المرور الجديدة"
          value={password}
          onChangeText={setpassword}
          style={styles.input}
          mode="outlined"
        />

        <TextInput
          label="تأكيد كلمة المرور"
          value={passwordconfirm}
          onChangeText={setpasswordconfirm}
          style={styles.input}
          mode="outlined"
        />

        <Button mode="contained" onPress={submitPassword} style={styles.button}>
          تأكيد
        </Button>
        <Modal visible={modal2Visible} transparent={true} animationType="fade">
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            activeOpacity={1}
            onPress={() => setModal2Visible(false)}
            onRequestClose={() => setModal2Visible(false)}
          >
            <View
              padding={8}
              style={{
                width: "auto",
                maxWidth: "80%",
                minWidth: "60%",
                backgroundColor: Global.backgroundColor,
                borderRadius: 10,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                icon="alert-circle"
                color={"red"}
                size={50}
                style={styles.icon}
              />
              <Text
                style={{
                  marginHorizontal: 20,
                  marginBottom: 10,
                  writingDirection: "rtl",
                }}
              >
                {SorF}
              </Text>
              <Button
                mode="contained"
                onPress={() =>
                  mainStore.errorMessage !== ""
                    ? setModal2Visible(false)
                    : (setModal2Visible(false), navigation.goBack())
                }
                style={{ alignSelf: "center", marginBottom: 20 }}
              >
                حسنًا
              </Button>
            </View>
          </TouchableOpacity>
        </Modal>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    backgroundColor: Global.backgroundColor,
  },
  input: {
    width: "100%",
    marginTop: 20,
    textAlign: "auto",
  },
  button: {
    marginTop: 20,
  },
  txt: {
    fontWeight: Global.fontWeight,
    marginTop: 20,
    fontSize: 17,
  },
});

export default observer(ResetPassword2);
