import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  Modal,
  Platform,
  Text as Txt,
} from "react-native";
import {
  Button,
  TextInput,
  Text,
  IconButton,
  Snackbar,
} from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import Global from "../../Global";
import CountryFlag from "react-native-country-flag";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

function ConfirmationScreen({ navigation }) {
  const [modal2Visible, setModal2Visible] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [changePhone, setChangePhone] = useState(false);
  const [counter, setCounter] = useState(90);
  const [phoneNumber, setPhoneNumber] = useState(
    mainStore.isTest ? "00000" : ""
  );
  const [CountryCode, setCountryCode] = useState("");
  const [show, setShow] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  useEffect(() => {
    setCountryCode(mainStore.callingCode.replace("+", ""));
  }, [mainStore.callingCode]);
  useEffect(() => {
    mainStore.isVerified === 1 && navigation.navigate("HomeScreen");
  });
  useEffect(() => {
    mainStore.getUserdata();
    mainStore.isVerified === 0 && mainStore.sendOtp();
    const preventback = navigation.addListener("beforeRemove", (e) => {
      // Prevent the back action
      e.preventDefault();
      AsyncStorage.removeItem("accesstoken");
      mainStore.logout();
    });
    return preventback;
  }, []);

  useEffect(() => {
    let interval = null;
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [counter]);

  const verify = async () => {
    await mainStore.verifyOtp(confirmationCode);
    if (mainStore.verified) {
      await mainStore.getUserdata();
      navigation.navigate("HomeScreen");
    } else {
      setModal2Visible(true);
    }
  };
  const handleResend = () => {
    setResendDisabled(true);
    mainStore.sendOtp();
    console.log(mainStore.accessToken);
    setCounter(90);
  };
  const callingCodeToIso = require("../../callingcode.json");

  const getIsoCode = (callingCode) => {
    return callingCodeToIso[callingCode];
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        // paddingHorizontal: 16,
        //  backgroundColor: Global.backgroundColor,
      }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.container}>
        {!changePhone && (
          <>
            <Image
              source={require("../../assets/logo.webp")}
              style={styles.logo}
            />
            <Text style={styles.title}>أدخل رمز التحقق</Text>
            <TextInput
              label="رمز التحقق"
              value={confirmationCode}
              onChangeText={setConfirmationCode}
              keyboardType="numeric"
              style={styles.input}
              mode="outlined"
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginEnd: 50,
                marginRight: -15,
                marginTop: -10,
                alignSelf: "flex-start",
              }}
            >
              <IconButton icon="information-outline" size={20} />
              <Txt
                style={{
                  color: Global.textcolor,
                  fontWeight: Global.fontWeight,
                  lineHeight: 20,
                  fontFamily: "Tajawal",
                }}
              >
                تأكد أن الرقم مرتبط بـ WhatsApp ليصلك كود التحقق
              </Txt>
            </View>
            <Button
              mode="contained"
              onPress={verify}
              disabled={!(confirmationCode && resendDisabled)}
              style={styles.confirmButton}
            >
              تأكيد
            </Button>
            <Button
              onPress={handleResend}
              disabled={resendDisabled}
              style={styles.resendButton}
            >
              {resendDisabled
                ? `إعادة الإرسال خلال ${counter} ثانية`
                : "أعد إرسال الرمز"}
            </Button>
            <Text style={{ marginBottom: -10 }}>أو</Text>
            <Button onPress={setChangePhone} style={styles.resendButton}>
              تعديل رقم الهاتف
            </Button>
            <Text
              style={{
                color: Global.graycolor,
                marginTop: -5,
                marginBottom: 5,
                writingDirection: "rtl",
              }}
            >
              {"الحالي هو:"}
            </Text>
            <Text
              style={{
                writingDirection: "ltr",
                color: Global.graycolor,
              }}
            >
              {mainStore.phone}
            </Text>
          </>
        )}
        {changePhone && (
          <>
            <Image
              source={require("../../assets/logo.webp")}
              style={styles.logo}
            />
            <Text style={styles.title}>تعديل رقم الهاتف</Text>
            <View style={styles.inputContainer}>
              <TextInput
                label="رقم هاتفك"
                mode="outlined"
                keyboardType="phone-pad"
                value={phoneNumber}
                onChangeText={setPhoneNumber}
                style={styles.phoneNumberInput}
              />
              <TextInput
                label="رمز البلد"
                placeholder="00"
                placeholderTextColor={"gray"}
                value={CountryCode}
                onChangeText={setCountryCode}
                style={styles.countryCodeInput}
                keyboardType="phone-pad"
                mode="outlined"
                contentStyle={{ marginLeft: 60, marginRight: -10 }}
              />
              <Text
                style={[
                  {
                    alignSelf: "center",
                    fontSize: 30,
                    position: "absolute",
                    left: 55,
                    fontWeight: Global.fontWeight,
                    color: Global.textcolor,
                    bottom: 6,
                  },
                  Platform.OS === "ios" && {},
                ]}
              >
                +{" "}
              </Text>
              {getIsoCode(CountryCode) !== undefined && (
                <CountryFlag
                  style={{
                    alignSelf: "center",
                    position: "absolute",
                    left: 10,
                    bottom: 13,
                  }}
                  isoCode={
                    getIsoCode(CountryCode) === undefined
                      ? ""
                      : getIsoCode(CountryCode)
                  }
                  size={25}
                />
              )}
            </View>
            <View
              style={{
                display:
                  !/^[0-9]*$/.test(phoneNumber) || phoneNumber[0] === "0"
                    ? "flex"
                    : "none",
                flexDirection: "row",
                alignItems: "center",
                marginTop: -20,
                marginBottom: 5,
                // marginHorizontal: '-25%',
              }}
            >
              <IconButton
                iconColor="red"
                icon="information-outline"
                size={20}
              />
              <Text
                style={{
                  color: "red",
                  fontWeight: Global.fontWeight,
                  lineHeight: 20,
                }}
              >
                {phoneNumber[0] === "0"
                  ? "الرجاء إدخال رقم هاتفك بدون صفر في بدايته"
                  : "يمنع إدخال الحروف والمسافات والرموز"}
              </Text>
            </View>
            <Button
              mode="contained"
              onPress={async () => {
                await mainStore.updateMobile("+" + CountryCode + phoneNumber);
                if (mainStore.errorMessage === "") {
                  setResendDisabled(false);
                  clearInterval();
                  await mainStore.getUserdata();
                  setSnackbarVisible(true);
                  setChangePhone(false);
                } else {
                  setModal2Visible(true);
                }
              }}
              style={styles.confirmButton}
            >
              تأكيد
            </Button>
            <Text style={{ marginTop: 10 }}>أو</Text>
            <Button
              onPress={() => setChangePhone(false)}
              style={styles.resendButton}
            >
              إدخال كود التحقق
            </Button>
          </>
        )}
        <Modal visible={modal2Visible} transparent={true} animationType="fade">
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            activeOpacity={1}
            onPress={() => setModal2Visible(false)}
            onRequestClose={() => setModal2Visible(false)}
          >
            <View
              padding={8}
              style={{
                width: "auto",
                maxWidth: "80%",
                minWidth: "50%",
                backgroundColor: Global.backgroundColor,
                borderRadius: 10,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                icon="alert-circle"
                color={"red"}
                size={50}
                style={styles.icon}
              />
              <Text
                style={{
                  marginHorizontal: 20,
                  marginBottom: 10,
                  writingDirection: "rtl",
                }}
              >
                {mainStore.errorMessage}
              </Text>
              <Button
                mode="contained"
                onPress={() => setModal2Visible(false)}
                style={{ alignSelf: "center", marginBottom: 20 }}
              >
                حسنًا
              </Button>
            </View>
          </TouchableOpacity>
        </Modal>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={() => setSnackbarVisible(false)}
          duration={5000}
          style={{
            backgroundColor: "lightgray",
            // bottom: 50,
            // right: 10,
          }}
        >
          <Text
            style={{ color: "black", lineHeight: 23, writingDirection: "rtl" }}
          >
            تم تحديث رقم هاتفك بنجاح، انقر على "أعد إرسال الرمز" عندما تكون
            جاهزًا!
          </Text>
        </Snackbar>
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Global.backgroundColor,
  },
  title: {
    marginBottom: 16,
    fontSize: 24,
    fontWeight: Global.fontWeight,
  },
  input: {
    width: "100%",
    marginBottom: 16,
  },
  confirmButton: {
    width: "100%",
    marginBottom: 8,
  },
  resendButton: {
    marginTop: 8,
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 16,
  },
  countryCodeInput: {
    flex: 1.55,
  },
  phoneNumberInput: {
    flex: 3,
    marginLeft: 10,
    textAlign: "left",
  },
  inputContainer: {
    flexDirection: "row",
    marginBottom: 20,
    width: "100%",
  },
});
export default observer(ConfirmationScreen);
