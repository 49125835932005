import React, { useContext, useRef, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Share as Sharee,
  Animated,
  ScrollView,
  Image,
  Linking,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import {
  Title,
  Text,
  IconButton,
  Button,
  Snackbar,
  ActivityIndicator,
  MD2Colors,
  Dialog,
  Portal,
  Divider,
  TextInput,
} from "react-native-paper";
import { Platform } from "react-native";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import { useIsFocused, useNavigationState } from "@react-navigation/native";
import Global from "../../Global";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
const BankDetailsScreen = ({ navigation, route }) => {
  const isFocused = useIsFocused();
  const previousRouteName = useNavigationState(
    (state) => state.routes[state.index - 1]?.name
  );
  const [activeback, setactiveback] = useState(false);
  const [reference, setReference] = useState(route.params[0]);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const onDismissSnackBar = () => setSnackbarVisible(false);
  useEffect(() => {
    //  console.error(route.params[1]);
    const preventback = navigation.addListener("beforeRemove", (e) => {
      // Prevent the back action
      if (!activeback && previousRouteName.includes("Deposit")) {
        e.preventDefault();
        navigation.navigate("HomeScreen");
      }
    });
    // Clean up the listener on unmount
    return preventback;
  }, [navigation, activeback, mainStore.loading]);
  useEffect(() => {
    isFocused ? mainStore.stackState("opened") : mainStore.stackState("closed");
  }, [isFocused, mainStore.stackOpened]);
  const formatDetails = (detailsObject) => {
    const detailItems = [];
    // console.error(reference);
    for (let [key, value] of Object.entries(detailsObject)) {
      detailItems.push(
        <TouchableOpacity
          style={{
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
          onPress={() => {
            Clipboard.setString(`${key}: ${value}`);
            setSnackbarVisible(true);
          }}
          key={key}
        >
          <Text
            style={{
              color: Global.textcolor,
              fontWeight: Global.fontWeight,
              marginBottom: 5,
              textAlign: "left",
              lineHeight: 25,
              fontSize: 16,
              direction: "rtl",
            }}
          >
            {Platform.OS === "android"
              ? `${key}:\n${value}`
              : `:${key}\n${value}`}
          </Text>
          <Icon name="content-copy" size={24} color={Global.iconcolor} />
        </TouchableOpacity>
      );
    }
    detailItems.push(
      <TouchableOpacity
        onPress={() => {
          copyToClipboard(mainStore.bankDetails);
          setSnackbarVisible(true);
        }}
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            color: Global.textcolor,
            fontWeight: Global.fontWeight,
            marginBottom: 5,
            textAlign: "left",
            lineHeight: 25,
            fontSize: 16,
            direction: "rtl",
          }}
        >
          {Platform.OS === "android"
            ? `Reference:\n${reference}`
            : `:رقم المعاملة\n${mainStore.latestTransactions[0].id}`}
        </Text>
        <Icon name="content-copy" size={24} color={Global.iconcolor} />
      </TouchableOpacity>
    );
    return detailItems;
  };

  const copyToClipboard = (detailsObject) => {
    const s = mainStore.latestTransactions[0]?.deposit?.deposit_method?.name;
    const slug =
      s === "Wise & Revolut"
        ? mainStore.latestTransactions[0]?.deposit?.reference
        : s;
    let message = `مرحبًا، أنا ${mainStore.userName}\nلقد طلبت إيداع ${
      Global.arabicToNumeric(
        Math.abs(
          mainStore.latestTransactions[0].deposit.paying_amount
        ).toString()
      ) +
      " " +
      mainStore.latestTransactions[0]?.deposit?.paying_currency.toLocaleUpperCase()
    } عبر ${slug}\nرقم المعاملة: ${mainStore.latestTransactions[0].id}.`;
    setSnackbarVisible(true);
    let clipboardString = "";

    for (let [key, value] of Object.entries(detailsObject)) {
      clipboardString += `${key}: ${value}\n`;
    }
    clipboardString += `Reference: ${reference}\n`;
    Clipboard.setString(message);
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: Global.fontWeight,
            direction: "rtl",
          }}
        >
          معلومات الحساب للتحويل:
        </Text>
        <View style={styles.infoBox}>
          <View>
            {formatDetails(mainStore.bankDetails)}
            <Button
              contentStyle={{ direction: "ltr" }}
              icon="content-copy"
              mode="text"
              width={150}
              style={{
                borderRadius: 10,
                alignSelf: "center",
              }}
              labelStyle={{ fontSize: 16 }}
              title="Copy to Clipboard"
              onPress={() => copyToClipboard(mainStore.bankDetails)}
            >
              نسخ الكل
            </Button>
          </View>
        </View>
        {route.params[1].deposit.by_user_status === "pending" && (
          <View>
            <Button
              mode="contained"
              onPress={async () => {
                // await mainStore.confirmDeposit(route.params[1].deposit.id);
                // setactiveback(true);
                // navigation.navigate('HomeScreen');
                // await Global.checkIfShouldRequestReview();
                const s =
                  mainStore.latestTransactions[0]?.deposit?.deposit_method
                    ?.name;
                const slug =
                  s === "Wise & Revolut"
                    ? mainStore.latestTransactions[0]?.deposit?.reference
                    : s;
                let message = `مرحبًا، أنا ${
                  mainStore.userName
                }\nلقد طلبت إيداع ${
                  Global.arabicToNumeric(
                    Math.abs(
                      mainStore.latestTransactions[0].deposit.paying_amount
                    ).toString()
                  ) +
                  " " +
                  mainStore.latestTransactions[0]?.deposit?.paying_currency.toLocaleUpperCase()
                } عبر ${slug}\nرقم المعاملة: ${
                  mainStore.latestTransactions[0].id
                }.`;

                // Encode the message to make it URL-safe
                let encodedMessage = encodeURIComponent(message);

                // Specify the phone number (without +, spaces, or dashes)
                let phoneNumber = mainStore.contact.whatsapp;

                // Construct the WhatsApp URL with the phone number
                let whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

                // Open WhatsApp with the encoded message
                const canOpen = await Linking.canOpenURL(whatsappUrl);
                if (phoneNumber) {
                  Linking.openURL(whatsappUrl);
                } else {
                  const telegramBotUsername = mainStore.contact.telegram;
                  const telegramUrl = `https://t.me/${telegramBotUsername}?start=${encodedMessage}`;

                  const canOpenTelegram = await Linking.canOpenURL(telegramUrl);
                  if (canOpenTelegram) {
                    Linking.openURL(telegramUrl);
                  } else {
                    console.log("Cannot open WhatsApp or Telegram");
                  }
                }
              }}
              style={{ marginBottom: 15, borderRadius: 15 }}
              contentStyle={{ height: 50 }}
              labelStyle={{ fontSize: 18 }}
            >
              تواصل معنا
            </Button>
            {/*   <Button
              mode="outlined"
              onPress={async () => {
                setactiveback(true);
                navigation.navigate("HomeScreen");
              }}
              style={{
                marginBottom: 15,
                borderColor: Global.primary,
                borderRadius: 15,
              }}
              contentStyle={{ height: 50 }}
              labelStyle={{
                fontSize: 18,
                lineHeight: 30,
                color: Global.textcolor,
              }}
            >
              سأرسل لاحقًا{" "}
            </Button> */}
            <Button
              mode="outlined"
              onPress={async () => {
                await mainStore.cancelDeposit(route.params[1].deposit.id);
                setactiveback(true);
                navigation.navigate("HomeScreen");
              }}
              style={{
                marginBottom: 15,
                borderColor: Global.primary,
                borderRadius: 15,
              }}
              contentStyle={{ height: 50 }}
              labelStyle={{ fontSize: 18, color: "red" }}
            >
              إلغاء الحوالة
            </Button>
          </View>
        )}

        <Snackbar
          visible={snackbarVisible}
          onDismiss={onDismissSnackBar}
          duration={2000}
          style={{
            backgroundColor: "lightgray",
            left: 10,
            bottom: 20,
          }}
        >
          <Text style={{ color: "black" }}>
            تم نسخ المعلومات إلى الحافظة بنجاح!
          </Text>
        </Snackbar>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: Global.backgroundColor,
    paddingTop: 20,
  },
  title: {
    fontSize: 27,
    fontWeight: "bold",
    marginBottom: 20,
  },
  viewShotContainer: {
    alignItems: "center",
  },
  label: {
    fontWeight: Global.fontWeight,
    marginTop: 10,
    color: Global.graycolor,
  },
  addressRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoBox: {
    backgroundColor: Global.cardcolor,
    padding: 10,
    width: "100%",
    alignSelf: "center",
    borderRadius: 5,
    borderWidth: 1,
    margin: 10,
  },
  shareButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    width: "100%",
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
});
export default observer(BankDetailsScreen);
