import React, { useEffect, useState } from "react";
import { ScrollView, Image, Platform, StyleSheet, View } from "react-native";
import {
  Title,
  Text,
  Divider,
  Button,
  Text as Txt,
  ActivityIndicator,
  MD2Colors,
} from "react-native-paper";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import { useIsFocused, useNavigationState } from "@react-navigation/native";
import Global from "../../Global";
import ResultScreen from "../ResultScreen";

const ReviewScreen = ({ route, navigation, props, setSuccsess, setStep }) => {
  const isFocused = useIsFocused();
  const previousRouteName = props.previousRouteName;

  useEffect(() => {
    console.error(choice);
    //isFocused ? mainStore.stackState('opened') : mainStore.stackState('closed');
  }, [isFocused]);
  const {
    amount,
    recipientName,
    recipientAmount,
    currency,
    phone,
    city,
    method,
    country,
    currencyAr,
    choice,
    calcMethod,
  } = props;
  // ملاحظة للمستقبل: قد يفيدك أخذ اسم المستلم من الستور مباشرةً في تجنب بعض المشاكل
  const handleConfirm = async () => {
    previousRouteName === "SendToWalletScreen"
      ? await mainStore.internalTransfer(phone, amount * 100)
      : await mainStore.externalTransfer(
          recipientName,
          phone,
          amount,
          method === 1 || calcMethod === "localSy" ? city : country,
          currency,
          method,
          choice === "" ? undefined : choice
        );

    /*     previousRouteName === 'SendToSyScreen' &&
      (await mainStore.externalTransfer(
        recipientName,
        phone,
        amount,
        'syria',
        'syp',
        'sawa',
      ));
    previousRouteName === 'ManualToSyScreen' &&
      (await mainStore.externalTransfer(
        recipientName,
        phone,
        amount,
        city,
        currency === 'usd_alhor' ? 'usd' : 'syp',
        'agent',
      ));
    previousRouteName === 'SendToEgScreen' &&
      (await mainStore.externalTransfer(
        phone,
        phone,
        amount,
        'egypt',
        'egp',
        'vfcash',
      )); */
    console.log(mainStore.transferStatus);
    setSuccsess(2);
    setStep(4);
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View
      style={{
        paddingHorizontal:
          previousRouteName === "SendToWalletScreen" ? 16 : undefined,
        flex: 1,
        backgroundColor: Global.backgroundColor,
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <Image
          source={require("../../assets/logo.webp")}
          style={{
            width: 125,
            height: 125,
            marginBottom: 16,
            alignSelf: "center",
          }}
        />
        <Text style={{ marginBottom: 10, fontSize: 16, marginTop: 10 }}>
          :ستقوم بإرسال مبلغ
        </Text>
        <View
          style={{
            backgroundColor: Global.cardcolor,
            borderRadius: 15,
            padding: "4%",
            marginBottom: 10,
          }}
        >
          <Text style={{ fontSize: 17 }}>
            ${Global.arabicToNumeric(amount)}
          </Text>
        </View>
        <Text style={{ marginBottom: 10, fontSize: 16 }}>:إلى المستلم</Text>
        <View
          style={{
            backgroundColor: Global.cardcolor,
            borderRadius: 15,
            padding: "4%",
            marginBottom: 10,
          }}
        >
          <Text style={{ fontSize: 17 }}>{recipientName}</Text>
        </View>
        <Text style={styles.text}>
          {previousRouteName === "SendToSyScreen"
            ? "حين تتم العملية بنجاح ستحصل على وصل استلام يمكنك تحميله فورًا"
            : ""}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={styles.subtitle}>:مراجعة سريعة</Text>
          <Button
            onPress={() => {
              setSuccsess(false);
              setStep(1);
            }}
            style={{ marginTop: 20 }}
          >
            تعديل
          </Button>
        </View>
        <Divider style={styles.divider} />
        <View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.label}>:المبلغ المرسل</Text>
            <Text style={styles.value}>${Global.arabicToNumeric(amount)}</Text>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.label}>:سيحصل المستلم على</Text>
            <Text style={styles.value}>
              {previousRouteName === "SendToWalletScreen"
                ? "$" + Global.arabicToNumeric(amount)
                : `${Global.arabicToNumeric(recipientAmount)} ${
                    currencyAr.split(" ")[0]
                  }`}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
          {previousRouteName !== "SendToWalletScreen" && (
            <Button
              mode="outlined"
              style={styles.button}
              onPress={() => {
                setStep(2);
              }}
              contentStyle={{ height: 40 }}
              labelStyle={{ fontSize: 18 }}
            >
              السابق
            </Button>
          )}

          <Button
            mode="contained"
            style={styles.button}
            onPress={handleConfirm}
            contentStyle={{ height: 40 }}
            labelStyle={{ fontSize: 16 }}
          >
            تأكيد وإرسال
          </Button>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //paddingHorizontal: 10,
    flex: 1,
    backgroundColor: Global.backgroundColor,
  },
  header: {
    // fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 16,
    marginTop: 16,
    fontWeight: Global.fontWeight,
  },
  text: {
    marginBottom: 10,
    lineHeight: 22,
    fontWeight: Global.fontWeight,
  },
  bigtxt: {
    fontSize: 18,
    fontWeight: Global.fontWeight,
  },
  subtitle: {
    fontSize: 19,
    marginBottom: 8,
    marginTop: 20,
    fontWeight: Global.fontWeight,
  },
  divider: {
    height: 1,

    marginBottom: 16,
  },
  label: {
    fontSize: 16,
    color: "gray",
    fontWeight: Global.fontWeight,
    marginBottom: 10,
  },
  value: {
    marginBottom: 16,
    fontSize: 20,
    fontWeight: Global.fontWeight,
    fontFamily: "Tajawal",
    writingDirection: "rtl",
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
    borderRadius: 5,
    marginVertical: 10,
    marginBottom: 20,
  },
});

export default observer(ReviewScreen);
