import React, { useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Animated,
  Platform,
  Image,
  Text as Txt,
} from "react-native";
import {
  Avatar,
  Button,
  Divider,
  MD3Colors,
  ProgressBar,
  Text,
  useTheme,
} from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Global from "../Global";
import { useIsFocused } from "@react-navigation/core";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";

const InvitedScreen = ({ navigation }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const friends = mainStore.referrals;
  useEffect(() => {
    mainStore.getReferrals();
  }, []);

  const FriendItem = ({ friend }) => {
    const progress = new Animated.Value(0);

    React.useEffect(() => {
      Animated.timing(progress, {
        toValue: friend.has_sent_first_transfer === true ? "100" : "50",
        duration: 1000,
        useNativeDriver: false,
      }).start();
    }, [friend.progress]);

    const progressBarWidth = progress.interpolate({
      inputRange: [0, 100],
      outputRange: ["0%", "100%"],
    });

    return (
      <View style={styles.friendItemContainer}>
        <Text style={styles.friendName}>{friend.name}</Text>
        <Divider style={styles.divider} />

        <View style={styles.progressBarContainer}>
          <Animated.View
            style={[styles.progressBar, { width: progressBarWidth }]}
          />
          <Text style={styles.completedTasks}>
            {friend.has_sent_first_transfer === true
              ? "سجّل وحوّل"
              : "سجّل فقط"}
          </Text>
          {friend.has_sent_first_transfer === true && (
            <MaterialCommunityIcons
              name="gift"
              size={24}
              color="#eeee"
              style={styles.giftIcon}
            />
          )}
        </View>
      </View>
    );
  };
  const isFocused = useIsFocused();
  useEffect(() => {
    isFocused ? mainStore.stackState("opened") : mainStore.stackState("closed");
  }, [isFocused]);
  if (mainStore.referrals !== "done" && mainStore.referrals.length === 0) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      {friends[0] === "done" ? (
        <View style={styles.emptyStateContainer}>
          <MaterialCommunityIcons
            name="emoticon-sad-outline"
            size={100}
            color="gray"
          />
          <Text style={styles.emptyStateText}>
            يبدو أنك لم تقم بدعوة أي أصدقاء بعد، يمكنك دائمًا دعوة أصدقائك
            للانضمام إلى تطبيقنا وربح 5 دولار مقابل كل صديق يقوم بحوالة واحدة
            بعد الانضمام!
          </Text>
          <Button
            onPress={() => navigation.navigate("InviteFriendScreen")}
            labelStyle={{ fontSize: 25, padding: 20 }}
          >
            {" "}
            ادع صديقًا!
          </Button>
        </View>
      ) : (
        <View>
          <Image
            source={{
              uri: "https://cdn-icons-png.flaticon.com/512/6021/6021967.png",
            }}
            style={styles.giftIcon2}
          />
          <Txt style={styles.accepted}>
            تهانينا! لديك بعض الأصدقاء الذين استجابوا لدعوتك:
          </Txt>

          <FlatList
            data={friends}
            renderItem={({ item }) => <FriendItem friend={item} />}
            ItemSeparatorComponent={<Divider style={styles.divider} />}
            keyExtractor={(item) => item.id.toString()}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: Global.backgroundColor,
  },
  emptyStateContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 24,
    backgroundColor: Global.backgroundColor,
  },
  emptyStateText: {
    textAlign: "center",
    marginTop: 16,
    fontSize: 18,
    marginBottom: 20,
    color: Global.graycolor,
    lineHeight: 25,
    fontWeight: Global.fontWeight,
  },
  friendItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  friendName: {
    fontSize: 18,
    flex: 1,
    fontWeight: Global.fontWeight,
  },
  progressBarContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 2,
  },
  progressBar: {
    height: 40,
    borderRadius: 5,
    backgroundColor: "#1585b1",
  },
  completedTasks: {
    marginLeft: 8,
    fontSize: 14,
    color: "white",
    position: "absolute",
    right: 0,
    fontWeight: Global.fontWeight,
  },
  giftIcon: {
    marginLeft: -40,
  },
  giftIcon2: {
    width: 150,
    height: 150,
    alignSelf: "center",
    marginTop: 70,
  },
  divider: {
    marginBottom: 10,
  },
  accepted: {
    fontWeight: Global.fontWeight,
    marginVertical: 20,
    fontSize: 18,
    alignSelf: "center",
    justifyContent: "center",
    fontFamily: "Tajawal",
    color: Global.textcolor,
  },
});

export default observer(InvitedScreen);
