import React, { useEffect } from "react";
import {
  View,
  Linking,
  StyleSheet,
  Platform,
  Share,
  Image,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { Text, IconButton, Title, Snackbar, Button } from "react-native-paper";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import mainStore from "../stores/MainStore";
import Global from "../Global";
import { useIsFocused } from "@react-navigation/core";
import { ScrollView } from "react-native-gesture-handler";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const InviteFriends = ({ navigation }) => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const shareAffiliateCode = async (message) => {
    try {
      await Share.share({
        message,
      });
    } catch (error) {
      console.log("Error sharing:", error);
    }
  };
  const userAffiliateCode = mainStore.referralCode;
  const onDismissSnackBar = () => setSnackbarVisible(false);

  const shareOnTwitter = async (message) => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}`;
    try {
      await Linking.openURL(url);
    } catch (error) {
      console.log("Error opening Twitter:", error);
    }
  };

  const shareOnWhatsApp = async (message) => {
    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;
    try {
      await Linking.openURL(url);
    } catch (error) {
      console.log("Error opening WhatsApp:", error);
    }
  };

  const sharingMessage = `أنا أستخدم يامرسال لإرسال حوالاتي بكل أريحية وأمان، لقد رأيت كيف أنه أرخص بكثير من الطرق الاعتيادية.
انضم الآن للتطبيق وشاركني هذه التجربة:
https://yamersal.com/refer-friends/
كود الإحالة الخاص بي: ${userAffiliateCode}`;

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        // paddingHorizontal: 16,
        //  backgroundColor: Global.backgroundColor,
      }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.container}>
        <Text style={styles.title}>دعوة صديق</Text>
        <Image
          source={require("../assets/logo.webp")}
          style={{
            width: 100,
            height: 100,
            alignSelf: "center",
            marginBottom: 20,
          }}
        />
        <Text style={styles.affiliateCode}>{userAffiliateCode}</Text>
        <Text style={styles.instructions}>
          قم بمشاركة كود الإحالة مع أصدقائك وبمجرد أن يتم تسجيل مستخدم جديد عبر
          الكود الخاص بك ويرسل أول حوالة عبر يامرسال ستحصل على 5$ في محفظتك
        </Text>
        <View style={styles.shareOptions}>
          <IconButton
            icon="share"
            iconColor={Global.iconcolor}
            size={40}
            onPress={() => shareAffiliateCode(sharingMessage)}
          />
          <IconButton
            icon="twitter"
            iconColor={Global.iconcolor}
            size={40}
            onPress={() => shareOnTwitter(sharingMessage)}
          />
          <IconButton
            icon="whatsapp"
            iconColor={Global.iconcolor}
            size={40}
            onPress={() => shareOnWhatsApp(sharingMessage)}
          />
          <IconButton
            icon="content-copy"
            iconColor={Global.iconcolor}
            size={40}
            onPress={async () => {
              await Clipboard.setString(userAffiliateCode);
              console.log("Affiliate code copied to clipboard");
              setSnackbarVisible(true);
            }}
          />
        </View>
        <Button
          onPress={() => navigation.navigate("InvitedScreen")}
          labelStyle={{ fontSize: 25, padding: 20 }}
        >
          قائمة المدعوين
        </Button>
      </View>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={onDismissSnackBar}
        duration={2000}
        style={{
          backgroundColor: "lightgray",
          //  alignSelf: "center",
          //width: "100%",
        }}
      >
        <Text style={{ color: "black", writingDirection: "rtl" }}>
          تم نسخ كود الإحالة إلى الحافظة بنجاح!
        </Text>
      </Snackbar>
    </KeyboardAwareScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: Global.backgroundColor,
    justifyContent: "center",
    //alignItems: "center",
  },
  title: {
    fontSize: 34,
    textAlign: "center",
    marginBottom: 25,
    color: Global.textcolor,
  },
  affiliateCode: {
    fontSize: 40,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    color: Global.textcolor,
  },
  instructions: {
    textAlign: "center",
    marginBottom: 16,
    fontSize: 16,
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    lineHeight: 22,
    color: Global.textcolor,
  },
  shareOptions: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
});
export default observer(InviteFriends);
