import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  View,
  StyleSheet,
  Text as Txt,
  Platform,
  Image,
  TouchableHighlight,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  TouchableOpacity,
  Modal,
  Dimensions,
} from "react-native";
import {
  ProgressBar,
  TextInput,
  Button,
  IconButton,
  Text,
  Menu,
  Provider,
  Divider,
  SegmentedButtons,
  Portal,
  Chip,
  Avatar,
  Snackbar,
  Dialog,
  List,
  Searchbar,
} from "react-native-paper";
import Global from "../../Global";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import SkeletonPlaceholder from "../../react-native-skeleton-placeholder-master/src/skeleton-placeholder";
import Octicons from "react-native-vector-icons/Octicons";
import debounce from "lodash.debounce";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import ReviewScreen from "./ReviewScreen";
import ResultScreen from "../ResultScreen";
import { observer } from "mobx-react-lite";
import mainStore from "../../stores/MainStore";
import { FlatList } from "react-native";

const cities = [
  { name: "حلب", englishName: "Aleppo", id: 1, currency: "usd_aleppo" },
  { name: "دمشق", englishName: "Damascus", id: 2, currency: "usd_damascus" },
  {
    name: "ريف دمشق",
    englishName: "Rif Damascus",
    id: 20,
    currency: "usd_damascus",
    villages: [
      { name: "الكسوة", currency: "usd_damascus" },
      { name: "دوما", currency: "usd_damascus" },
      { name: "عرطوز", currency: "usd_damascus" },
      { name: "صحنايا", currency: "usd_damascus" },
      { name: "داريا", currency: "usd_damascus" },
      { name: "معضمية", currency: "usd_damascus" },
      { name: "التل", currency: "usd_damascus" },
      { name: "جرمانا", currency: "usd_damascus" },
      { name: "قدسيا", currency: "usd_damascus" },
      { name: "القدم", currency: "usd_damascus" },
      { name: "النبك", currency: "usd_damascus" },
      { name: "دير عطية", currency: "usd_damascus" },
      { name: "يبرود", currency: "usd_damascus" },
      { name: "قاره", currency: "usd_damascus" },
    ],
  },
  { name: "حمص", englishName: "Homs", id: 3, currency: "usd_homs" },
  {
    name: "ريف حمص",
    englishName: "Rif Homs",
    id: 21,
    currency: "usd_rifhoms",
    villages: [
      { name: "الرستن", currency: "usd_rifhoms" },
      { name: "تلبيسة", currency: "usd_rifhoms" },
      { name: "الحولة", currency: "usd_rifhoms" },
    ],
  },
  { name: "حماة", englishName: "Hama", id: 4, currency: "usd_hama" },
  {
    name: "ريف حماة",
    englishName: "Rif Hama",
    id: 21,
    currency: "usd_hama",
    villages: [{ name: "السلمية", currency: "usd_hama" }],
  },
  { name: "اللاذقية", englishName: "Latakia", id: 5, currency: "usd_latakia" },
  { name: "طرطوس", englishName: "Tartous", id: 6, currency: "usd_tartous" },
  {
    name: "ريف طرطوس",
    englishName: "Rif Tartous",
    id: 22,
    currency: "usd_tartous",
    villages: [
      { name: "صافيتا", currency: "usd_tartous" },
      { name: "بانياس", currency: "usd_tartous" },
    ],
  },
  { name: "درعا", englishName: "Daraa", id: 8, currency: "usd_daraa" },
  {
    name: "ريف درعا",
    englishName: "Rif Daraa",
    id: 23,
    currency: "usd_daraa",
    villages: [
      { name: "طفس", currency: "usd_daraa" },
      { name: "جاسم", currency: "usd_daraa" },
      { name: "نوى", currency: "usd_daraa" },
      { name: "داعل", currency: "usd_daraa" },
      { name: "المزريب", currency: "usd_daraa" },
      { name: "الحراك", currency: "usd_daraa" },
      { name: "الصنمين", currency: "usd_daraa" },
      { name: "تسيل", currency: "usd_daraa" },
      { name: "سحم الجولان", currency: "usd_daraa" },
      { name: "بصره الشام", currency: "usd_daraa" },
      { name: "الحارة", currency: "usd_daraa" },
      { name: "ازرع", currency: "usd_daraa" },
    ],
  },
  {
    name: "القنيطرة",
    englishName: "Quneitra",
    id: 9,
    currency: "usd_quneitra",
  },
  { name: "السويداء", englishName: "Suwayda", id: 10, currency: "usd_suwayda" },
  {
    name: "جبلة",
    englishName: "Jableh",
    id: 11,
    currency: "usd_jableh",
  },
  {
    name: "دير الزور",
    englishName: "Deir ez-Zor",
    id: 13,
    currency: "usd_deirezzor",
  },
];
const MySkeletonPlaceholder = ({
  width = 150,
  height = 20,
  borderRadius = 5,
}) => {
  return (
    <SkeletonPlaceholder
      borderRadius={borderRadius}
      speed={300}
      backgroundColor={Global.placeholder}
    >
      <SkeletonPlaceholder.Item width={width} height={height} />
    </SkeletonPlaceholder>
  );
};
const ExternalTransferScreen = ({ navigation, route }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [step, setStep] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [editing, setediting] = useState(false);
  const [editing2, setediting2] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [ShowAddContact, setShowAddContact] = useState(false);
  const [total, setTotal] = useState("");
  const [selectedCity, setSlectedCity] = useState(cities[1]);
  const [selectedCountry, setSelectedCountry] = useState(
    mainStore.externalCountries[0]
  );
  const [selectedMethod, setSelectedMethod] = useState(
    mainStore.externalCountries[0]?.externalTransferMethods[0]
  );
  const [selectedChoice, setSelectedChoice] = useState();
  const [amountFoucs, setAmountFoucs] = useState(false);
  const [rAmountFoucs, setRAmountFoucs] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "دولار أمريكي",
    value: "usd",
    symbol: "USD",
  });
  const [isInfoModalVisivle, setInfoModalVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [recipientName, setRecipientName] = useState(
    mainStore.isTest ? `تجربة ${selectedMethod.name}` : ""
  );
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(mainStore.isTest ? "111" : "");
  const [bankNo, setBankNo] = useState("");
  const [iban, setIban] = useState("");
  const [amount, setAmount] = useState("150");
  const [recipientAmount, setRecipientAmount] = useState(" ");
  const [userNotes, setUserNotes] = useState("");

  const [succsess, setSuccsess] = useState(0);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [countryDialogVisible, setCountryDialogVisible] = useState(false);
  const [methodDialogVisible, setMethodDialogVisible] = useState(false);
  const [choicesDialogVisible, setChoicesDialogVisible] = useState(false);
  const [contacts, setContacts] = useState(mainStore.recipients);
  const [reversedContacts, setReversedContacts] = useState([]);
  const [allContacts, setAllContacts] = useState(mainStore.recipients);
  const [searchQuery, setSearchQuery] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const isMaxAmount =
    (selectedMethod.calc_method === "syriatelcash" &&
      recipientAmount > 525000) ||
    (selectedMethod.calc_method === "localSy" && recipientAmount > 2900000);
  useEffect(() => {
    setReversedContacts([...contacts].reverse());
  }, [contacts]); // Re-calculate when 'contacts' changes
  useEffect(() => {
    mainStore.getBalance();

    //  mainStore.getExternalCountries();
  }, []);
  useEffect(() => {
    setAllContacts(mainStore.recipients);

    //  mainStore.getExternalCountries();
  }, [contacts]);

  const steps = [
    "تفاصيل الحوالة",
    "معلومات المستلم",
    "مراجعة تفاصيل الحوالة",
    "نتيجة العملية",
  ];

  const handleSubmit = async () => {
    if (step < 3 && !ShowAddContact) {
      step === 1 && (await mainStore.getRecipients(selectedCountry.id));
      setContacts(mainStore.recipients);
      setStep(step + 1);
    } else {
      await mainStore.addRecipient(
        recipientName,
        phoneNumber +
          (selectedMethod.calc_method === "bankTransferUAE" ||
          selectedMethod.calc_method === "bankIQ"
            ? " - " + bankNo + (iban ? " - " : "") + iban
            : ""),
        selectedCountry.id
      );
      await mainStore.getRecipients(selectedCountry.id);
      setContacts(mainStore.recipients);
      setShowAddContact(false);
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setShowAddContact(false);

    if (step > 1 && !ShowAddContact) {
      setStep(step - 1);
    }
  };
  const handleTextChange = (text) => {
    // Remove any spaces from the text before updating the state
    const normalizedText = text.replace(/\s/g, "").replace(/\D/g, "");
    setPhoneNumber(normalizedText);
  };
  const formatPhoneNumber = (phone) => {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Split the digits into groups of 3, separated by spaces
    //const formattedNumber = digitsOnly.replace(/(\d{3})(?=\d)/g, '$1 ');
    const formattedNumber =
      digitsOnly.length === 10
        ? digitsOnly.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4")
        : digitsOnly.length === 11
        ? digitsOnly.substring(0, 4) + " " + digitsOnly.substring(4)
        : digitsOnly.replace(/(\d{3})(?=\d)/g, "$1 ");

    return formattedNumber;
  };
  const debouncedTextChange = debounce(async (amount) => {
    setediting(true);
    if (!editing2) {
      setShowSkeleton(true);
      await mainStore.calc(
        amount,
        selectedMethod.calc_method === "agent" ? selectedCity.currency : "usd",
        selectedMethod.calc_method === "fadelUSD"
          ? "usd"
          : selectedCountry.cur_code_en.toLowerCase(),
        "external_transfer",
        selectedMethod.calc_method,
        true
      );

      mainStore.feeDetails.receiving_amount < 0 || amount === ""
        ? setRecipientAmount("0")
        : setRecipientAmount(
            Number(mainStore.feeDetails.receiving_amount).toFixed(2).toString()
          );
      setTotal(
        Math.max((amount - mainStore.feeDetails.fee).toFixed(2), 0).toString()
      );
      setShowSkeleton(false);
    }
    setediting(false);
  }, 500);
  const debouncedTextChange2 = debounce(async (amount) => {
    setediting2(true);

    if (!editing) {
      setShowSkeleton(true);
      await mainStore.calc(
        amount,
        selectedMethod.calc_method === "agent" ? selectedCity.currency : "usd",
        selectedMethod.calc_method === "fadelUSD"
          ? "usd"
          : selectedCountry.cur_code_en.toLowerCase(),
        "external_transfer",
        selectedMethod.calc_method,
        false
      );
      mainStore.feeDetails.receiving_amount < 0 || recipientAmount === ""
        ? setAmount("0")
        : setAmount(
            Number(mainStore.feeDetails.sending_amount).toFixed(2).toString()
          );
      setTotal(
        Math.max(
          (
            mainStore.feeDetails.sending_amount - mainStore.feeDetails.fee
          ).toFixed(2),
          0
        ).toString()
      );
      setShowSkeleton(false);
    }
    setediting2(false);
  }, 500);
  useEffect(() => {
    debouncedTextChange(amount, selectedCity.currency);
    return () => {
      debouncedTextChange.cancel();
    };
  }, [selectedCity.currency, selectedCountry?.id, selectedMethod?.id]);

  useEffect(() => {
    debouncedTextChange(amount, selectedCity.currency);

    return () => {
      debouncedTextChange2.cancel();
    };
  }, []);

  useEffect(() => {
    if (route.params !== undefined) {
      console.log(route.params);
      setCountryCode(route.params.countryCode);
      setPhoneNumber(route.params.number);
    }
  }, [route.params]);
  // For Country Dialog
  const showCountryDialog = () => {
    Keyboard.dismiss();
    setCountryDialogVisible(true);
  };
  const hideCountryDialog = () => setCountryDialogVisible(false);
  const selectCountry = (country) => {
    setSelectedCountry(country);
    setSelectedMethod(country.externalTransferMethods[0]);
    country?.externalTransferMethods[0]?.choices !== null &&
      setSelectedChoice(
        Object.values(country?.externalTransferMethods[0]?.choices)[0]
      );
    hideCountryDialog();
  };
  // For Method Dialog
  const showMethodDialog = () => {
    Keyboard.dismiss();
    setMethodDialogVisible(true);
  };
  const hideMethodDialog = () => {
    setMethodDialogVisible(false);
  };
  const selectMethod = (method) => {
    setSelectedMethod(method);
    method?.choices !== null &&
      setSelectedChoice(Object.values(method?.choices)[0]);
    hideMethodDialog();
  };
  // For choices Dialog
  const showChoicesDialog = () => {
    Keyboard.dismiss();
    setChoicesDialogVisible(true);
  };
  const hideChoicesDialog = () => setChoicesDialogVisible(false);
  const selectChoice = (choice) => {
    setSelectedChoice(choice);
    hideChoicesDialog();
  };
  const showDialog = () => {
    Keyboard.dismiss();
    setDialogVisible(true);
  };
  const hideDialog = () => setDialogVisible(false);
  const selectCity = (city) => {
    setSlectedCity(city);
    hideDialog();
  };
  const Avatar = ({ name }) => {
    const initials = name[0].toUpperCase();

    const [backgroundColor, setBackgroundColor] = useState(randomDarkColor());

    function randomDarkColor() {
      const colors = ["3", "4", "5", "6", "7", "8", "9", "A", "B", "C"];
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += colors[Math.floor(Math.random() * colors.length)];
      }
      return color;
    }

    return (
      <View
        style={{
          width: 50,
          height: 50,
          borderRadius: 25,
          backgroundColor: Global.cardcolor,
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Text style={{ color: Global.textcolor, fontSize: 18 }}>
          {initials}
        </Text>
      </View>
    );
  };
  const renderContactItem = ({ item }) => {
    const name = item.name;
    const phoneNumber = item.phone;
    const toggleMenu = () => {
      // Toggle based on current state
      setMenuVisible((prevState) => ({
        ...prevState,
        [item.id]: !prevState[item.id],
      }));
    };
    return (
      <TouchableOpacity
        style={{
          paddingVertical: 12,
          paddingHorizontal: 10,
          borderBottomWidth: 0.5,
          borderBottomColor: "gray",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onPress={() => {
          setRecipientName(name);
          setPhoneNumber(phoneNumber);
          handleSubmit();
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <Avatar name={name} />
          <View>
            <Text
              style={{
                fontSize: 16,
                color: Global.textcolor,
                alignSelf: "flex-start",
              }}
            >
              {name}
            </Text>
            <Text
              style={{
                color: Global.graycolor,
                marginTop: 5,
                alignSelf: "flex-start",
              }}
            >
              {phoneNumber}
            </Text>
          </View>
        </View>
        <Menu
          contentStyle={{
            // backgroundColor: Global.backgroundColor,
            alignSelf: "center",
          }}
          style={
            {
              // marginTop: 30,
              //alignSelf: "center",
              //marginLeft: "20%",
            }
          }
          visible={menuVisible[item.id] || false} // Default to false if undefined
          onDismiss={() =>
            setMenuVisible((prev) => ({ ...prev, [item.id]: false }))
          }
          anchor={
            <IconButton
              onPress={toggleMenu}
              icon={"dots-vertical"}
              style={{ marginEnd: -10 }}
              mode="text"
              textColor="red"
            ></IconButton>
          }
        >
          <Menu.Item
            onPress={async () => {
              await mainStore.deleteRecipient(item.id);
              await mainStore.getRecipients(selectedCountry.id);
              setContacts(mainStore.recipients);
            }}
            title="حذف المستلم"
          />
        </Menu>
      </TouchableOpacity>
    );
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    if (query === "") {
      setContacts(allContacts);
    } else {
      const filteredContacts = allContacts.filter((contact) => {
        const lowerQuery = query.toLowerCase();
        const displayName = contact.name
          ? contact.name.toLowerCase().includes(lowerQuery)
          : false;
        const phone = contact.phone
          ? contact.phone.toLowerCase().includes(lowerQuery)
          : false;
        return displayName || phone;
      });
      setContacts(filteredContacts);
    }
  };
  if (mainStore.externalCountries.length === 0 || mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <Portal>
          <Dialog
            style={{ maxHeight: Global.isios ? "100%" : "90%" }}
            visible={countryDialogVisible}
            onDismiss={hideCountryDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر بلدًا للتحويل:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {mainStore.externalCountries.map((country, index) => (
                <React.Fragment key={country.id}>
                  <Dialog.Content>
                    <TouchableOpacity onPress={() => selectCountry(country)}>
                      <View style={{ flexDirection: "row" }}>
                        <Image
                          source={{
                            uri: `https://api.yamersal.com/images/${country.flag}`,
                          }}
                          style={{
                            width: 35,
                            height: 35,
                            resizeMode: "contain",
                            marginLeft: 10,
                          }}
                        />
                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          {country.name}
                        </Text>
                      </View>
                      {index < mainStore.externalCountries.length - 1 && (
                        <Divider
                          style={{
                            marginTop: 20,
                            backgroundColor: Global.graycolor,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideCountryDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            style={{ width: "95%", alignSelf: "center", maxHeight: "90%" }}
            visible={methodDialogVisible}
            onDismiss={hideMethodDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر وسيلة التحويل:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {selectedCountry.externalTransferMethods.map((method, index) =>
                method.calc_method === false ? undefined : (
                  <React.Fragment key={method.id}>
                    <Dialog.Content>
                      <TouchableOpacity
                        onPress={() => {
                          method.calc_method === false
                            ? setModal2Visible(true)
                            : selectMethod(method);
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <View>
                            <Text style={{ fontSize: 18 }}>{method.name}</Text>
                            <Text
                              style={{
                                fontSize: 16,
                                marginTop: 10,
                                writingDirection: "rtl",
                              }}
                            >
                              {method.calc_method === "sawa"
                                ? Global.arabicToNumeric(
                                    mainStore.slider?.find(
                                      (item) =>
                                        item?.title === "شركات تحت 3 مليون"
                                    )?.ask
                                  ) +
                                  " - " +
                                  Global.arabicToNumeric(
                                    method.exchangeRate.ask
                                  ) +
                                  " "
                                : Global.arabicToNumeric(
                                    method.exchangeRate.ask
                                  ) + " "}
                              {method.calc_method === "fadelUSD"
                                ? "دولار"
                                : selectedCountry.cur_code_ar}
                            </Text>
                          </View>
                          <View
                            style={{
                              alignItems: "flex-end",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                name="alarm"
                                size={20}
                                color={Global.graycolor}
                                style={{ marginHorizontal: 5 }}
                              />
                              <Text
                                style={{
                                  fontSize: 15,
                                  color: Global.graycolor,
                                  alignSelf: "center",
                                  marginTop: 5,
                                  writingDirection: "rtl",
                                }}
                              >
                                {" " + method.estimated_arrival_time}
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: Global.graycolor,
                                  alignSelf: "center",
                                  marginTop: 5,
                                }}
                              >
                                الحد الأدنى:{" "}
                                {method.min_amount.toLocaleString("en")}{" "}
                                {method.calc_method === "fadelUSD"
                                  ? "دولار"
                                  : selectedCountry.cur_code_ar}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {index <
                          selectedCountry.externalTransferMethods.length -
                            1 && (
                          <Divider
                            style={{
                              marginTop: 20,
                              paddingTop: 0.6,
                              backgroundColor: Global.graycolor,
                            }}
                          />
                        )}
                      </TouchableOpacity>
                    </Dialog.Content>
                  </React.Fragment>
                )
              )}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideMethodDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            visible={dialogVisible}
            onDismiss={hideDialog}
            style={{ height: "90%", backgroundColor: Global.cardcolor }}
          >
            <Dialog.Title
              style={{
                writingDirection: "rtl",
              }}
            >
              اختر المحافظة
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {cities.map((city, index) => (
                <React.Fragment key={city.id}>
                  <Dialog.Content>
                    {city.name.includes("ريف") ? (
                      <List.Section key={index}>
                        <List.Accordion
                          style={{
                            backgroundColor: Global.cardcolor,
                            marginVertical: -17,
                          }}
                          titleNumberOfLines={2}
                          title={city.name}
                          titleStyle={{
                            lineHeight: 22,
                            alignSelf: "center",
                            marginLeft: -25,
                          }}
                        >
                          {cities[index].villages.map((village, index2) => (
                            <TouchableOpacity
                              style={{ marginTop: index2 === 0 ? 20 : 15 }}
                              onPress={() => selectCity(village)}
                            >
                              <Text style={{ alignSelf: "center" }}>
                                {village.name}
                              </Text>
                              {index2 < cities[index].villages.length - 1 && (
                                <Divider
                                  style={{
                                    marginTop: 20,
                                    backgroundColor: Global.graycolor,
                                  }}
                                />
                              )}
                            </TouchableOpacity>
                          ))}
                        </List.Accordion>
                        <Divider
                          style={{
                            marginTop: 20,
                            backgroundColor: Global.graycolor,
                          }}
                        />
                      </List.Section>
                    ) : (
                      <TouchableOpacity onPress={() => selectCity(city)}>
                        <Text style={{ alignSelf: "center" }}>{city.name}</Text>
                        {index < cities.length - 1 && (
                          <Divider
                            style={{
                              marginTop: 20,
                              backgroundColor: Global.graycolor,
                            }}
                          />
                        )}
                      </TouchableOpacity>
                    )}
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            style={{ maxHeight: Global.isios ? "100%" : "90%" }}
            visible={choicesDialogVisible}
            onDismiss={hideChoicesDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر التفضيل المناسب:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {selectedMethod?.choices
                ? Object.values(selectedMethod.choices).map((choice, index) => (
                    <React.Fragment key={index}>
                      <Dialog.Content>
                        <TouchableOpacity onPress={() => selectChoice(choice)}>
                          <Text style={{ alignSelf: "center", fontSize: 16 }}>
                            {choice}
                          </Text>
                          {index <
                            Object.values(selectedMethod.choices).length -
                              1 && (
                            <Divider style={{ marginTop: 20, paddingTop: 1 }} />
                          )}
                        </TouchableOpacity>
                      </Dialog.Content>
                    </React.Fragment>
                  ))
                : null}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideChoicesDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        {step === 1 && (
          <View style={{ marginTop: 20 }}>
            <Text style={styles.label}>المبلغ والبلد</Text>
            <TextInput
              value={amountFoucs ? amount : Global.arabicToNumeric(amount)}
              onChangeText={(text) => {
                const normalizedText = text.replace(/,/g, ".");
                setAmount(normalizedText);
                debouncedTextChange(normalizedText);
              }}
              onFocus={() => {
                setAmountFoucs(true);
              }}
              onBlur={() => {
                setAmountFoucs(false);
              }}
              placeholder="250.5"
              placeholderTextColor={Global.placeholder}
              style={styles.input}
              left={<TextInput.Icon icon="currency-usd" />}
              mode="outlined"
              keyboardType="decimal-pad"
              right={
                <TextInput.Icon
                  style={{ width: 200 }}
                  onPress={showCountryDialog}
                  icon={() => (
                    <View>
                      <View style={{ flexDirection: "row", marginStart: -10 }}>
                        <Image
                          source={{
                            uri: `https://api.yamersal.com/images/${selectedCountry.flag}`,
                          }}
                          style={{
                            width: 35,
                            height: 35,
                            resizeMode: "contain",
                            marginRight: 50,
                          }}
                        />
                        <Text
                          style={{
                            marginRight: 10,
                            fontSize: 15,
                            marginTop:
                              selectedCountry.name.split(" ").length === 2
                                ? 5
                                : 10,
                            maxWidth: 55,
                          }}
                        >
                          {selectedCountry.name}
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            marginRight: 7,
                            marginTop: Global.isios ? 2.5 : 2.5,
                            transform: [{ scaleX: 1.5 }],
                          }}
                        >
                          ⌄
                        </Text>
                      </View>
                    </View>
                  )}
                />
              }
            />

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
                marginTop: -10,
                paddingStart: 50,
                backgroundColor: "#FFFEE8",
                borderColor: "#FF8C42",
                borderRadius: 5,
                borderWidth: 0.5,
                display:
                  recipientAmount < selectedMethod.min_amount &&
                  recipientAmount > 0
                    ? "flex"
                    : "none",
              }}
            >
              <IconButton icon="alert" iconColor="#ebb703" size={25} />
              <Text
                style={{
                  fontWeight: Global.fontWeight,
                  color: "black",
                  writingDirection: "rtl",
                }}
              >
                {"الحد الأدنى للتحويل عبر " +
                  selectedMethod.name +
                  " هو " +
                  Global.arabicToNumeric(selectedMethod.min_amount) +
                  " "}
                {selectedMethod.calc_method === "fadelUSD"
                  ? "دولار"
                  : selectedCountry.cur_code_ar}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
                marginTop: -10,
                paddingStart: 50,
                backgroundColor: "#FFFEE8",
                borderColor: "#FF8C42",
                borderRadius: 5,
                borderWidth: 0.5,
                display: isMaxAmount ? "flex" : "none",
              }}
            >
              <IconButton icon="alert" iconColor="#ebb703" size={25} />
              <Text
                style={{
                  fontWeight: Global.fontWeight,
                  color: "black",
                  writingDirection: "rtl",
                }}
              >
                {"الحد الأعلى للتحويل عبر " +
                  selectedMethod.name +
                  " هو " +
                  (selectedMethod.calc_method === "syriatelcash"
                    ? "525,000"
                    : "2,900,000") +
                  " "}
                {selectedCountry.cur_code_ar}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                marginBottom: 20,
              }}
            >
              <Text style={[styles.alert]}>:رصيدك الحالي</Text>
              <TouchableOpacity
                style={{
                  borderBottomWidth: 1,
                  borderColor: Global.primary,
                }}
                onPress={() => {
                  const normalizedText = mainStore.balance.replace(/,/g, "");
                  setAmount(normalizedText);
                  debouncedTextChange(normalizedText);
                }}
              >
                <Txt
                  style={{
                    fontSize: 17,
                    fontWeight: "bold",
                    color: Global.primary,
                  }}
                >
                  ${Global.arabicToNumeric(mainStore.balance)}
                </Txt>
              </TouchableOpacity>
            </View>
            <Text style={styles.label}>تفضيلات الحوالة</Text>
            <View style={{ flexDirection: "row" }}>
              <Button
                icon={() => (
                  <Icon
                    name="chevron-down"
                    size={30}
                    color={Global.textcolor}
                    style={{ marginTop: -5, marginHorizontal: -10 }}
                  />
                )}
                labelStyle={{
                  fontSize: 18,
                  lineHeight: 22,

                  // maxWidth: 100,
                  // flexWrap: "wrap-reverse",
                }}
                contentStyle={{
                  marginVertical: 7.5,
                  direction: "ltr",
                  marginHorizontal: -15,
                }}
                style={{
                  borderRadius: 10,
                  flex: 1,
                  alignSelf: "stretch",
                  fontWeight: "bold",
                }}
                mode="outlined"
                onPress={showMethodDialog}
              >
                {selectedMethod.name}
              </Button>
              {selectedMethod.calc_method === "agent" && (
                <Button
                  icon={() => (
                    <Icon
                      name="chevron-down"
                      size={30}
                      color={Global.textcolor}
                      style={{ marginTop: -5, marginHorizontal: -10 }}
                      //  style={{ alignSelf: "flex-start" }}
                    />
                  )}
                  labelStyle={{ fontSize: 18, lineHeight: 22 }}
                  contentStyle={{
                    marginVertical: 7.5,
                    direction: "ltr",
                    marginHorizontal: -15,
                  }}
                  style={{
                    marginEnd: 10,
                    borderRadius: 10,
                    flex: 1,
                    alignSelf: "stretch",
                    fontWeight: "bold",
                  }}
                  mode="outlined"
                  onPress={showDialog}
                >
                  {`${selectedCity.name}`}
                </Button>
              )}
              {!Array.isArray(selectedMethod.choices) &&
                selectedMethod.choices !== null && (
                  <Button
                    icon={() => (
                      <Icon
                        name="chevron-down"
                        size={30}
                        color={Global.textcolor}
                        style={{ marginTop: -5, marginHorizontal: 0 }}
                      />
                    )}
                    labelStyle={{
                      fontSize: 18,
                      lineHeight: 22,
                    }}
                    contentStyle={{
                      marginVertical: 7.5,
                      direction: "ltr",
                      marginHorizontal: -15,
                    }}
                    style={{
                      marginEnd: 10,
                      borderRadius: 10,
                      flex: 1,
                      alignSelf: "stretch",
                      fontWeight: "bold",
                    }}
                    mode="outlined"
                    onPress={showChoicesDialog}
                  >
                    {selectedChoice}
                  </Button>
                )}
            </View>
            <View>
              {selectedMethod.calc_method === "localSy" && (
                <Button
                  icon={() => (
                    <Icon
                      name="chevron-down"
                      size={30}
                      color={Global.textcolor}
                      style={{ marginTop: -5, marginHorizontal: -10 }}
                      //  style={{ alignSelf: "flex-start" }}
                    />
                  )}
                  labelStyle={{
                    fontSize: 18,
                    lineHeight: 22,
                  }}
                  contentStyle={{
                    marginVertical: 7.5,
                    direction: "ltr",
                    marginHorizontal: -15,
                  }}
                  style={{
                    // marginEnd: 10,
                    borderRadius: 10,
                    flex: 1,
                    alignSelf: "stretch",
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                  mode="outlined"
                  onPress={showDialog}
                >
                  {`${selectedCity.name}`}
                </Button>
              )}
            </View>
            <View
              style={{
                display:
                  selectedCountry.name === "تركيا" ||
                  selectedCountry.name === "الشمال السوري"
                    ? "flex"
                    : "none",
                marginTop: 20,
              }}
            >
              <Text style={styles.label}>تفاصيل أخرى {"(اختياري)"}</Text>
              <TextInput
                value={userNotes}
                onChangeText={setUserNotes}
                placeholder="تحديد أدق للمنطقة"
                placeholderTextColor={Global.placeholder}
                style={styles.recipientAmount}
                mode="outlined"
              />
            </View>
            <View style={styles.hintBox}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 13,
                  alignItems: "flex-end",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                  <Icon
                    name="minus-circle-outline"
                    size={21}
                    style={{ marginLeft: 3, color: Global.graycolor }}
                  />
                  {showSkeleton ? (
                    <MySkeletonPlaceholder />
                  ) : (
                    <Txt style={styles.ios}>
                      {" "}
                      {mainStore.feeDetails.fee.toLocaleString("en") +
                        " " +
                        selectedCurrency.label}
                    </Txt>
                  )}
                </View>
                <Text style={styles.labelText}>أجور المعاملة</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  marginBottom: 13,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Icon
                    name="pause-circle-outline"
                    size={21}
                    style={{
                      transform: [{ rotate: "90deg" }],
                      marginLeft: 6,
                      color: Global.graycolor,
                    }}
                  />
                  {showSkeleton ? (
                    <MySkeletonPlaceholder />
                  ) : (
                    <Txt style={styles.ios}>
                      {Global.arabicToNumeric(total) +
                        " " +
                        selectedCurrency.label}
                    </Txt>
                  )}
                </View>
                <Text style={styles.labelText}>الإجمالي بعد الأجور</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  //marginBottom: 5,
                  alignItems: "flex-end",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: Global.isios ? "flex-end" : "center",
                  }}
                >
                  <Octicons
                    name="x-circle"
                    size={17}
                    style={{
                      marginLeft: 6,
                      color: Global.graycolor,
                      marginRight: 2,
                    }}
                  />
                  {showSkeleton ? (
                    <MySkeletonPlaceholder />
                  ) : (
                    <Txt style={styles.ios}>
                      {selectedMethod.calc_method === "fadelUSD"
                        ? "1 دولار"
                        : `${Global.arabicToNumeric(
                            mainStore.feeDetails.exchangeRate
                          )} ${selectedCountry.currency_ar}`}
                    </Txt>
                  )}
                </View>
                <Text style={styles.labelText}>سعر صرف الدولار </Text>
              </View>
            </View>

            <Text
              style={{
                marginBottom: 10,
                fontWeight: Global.fontWeight,
                fontSize: 16,
              }}
            >
              :سيحصل المستلم على
            </Text>
            <TextInput
              value={
                rAmountFoucs
                  ? recipientAmount
                  : Global.arabicToNumeric(recipientAmount)
              }
              onChangeText={(text) => {
                const normalizedText = text.replace(/,/g, ".");
                setRecipientAmount(normalizedText);
                debouncedTextChange2(normalizedText);
              }}
              onFocus={() => {
                setRAmountFoucs(true);
              }}
              onBlur={() => {
                setRAmountFoucs(false);
              }}
              placeholder="250.5"
              placeholderTextColor={Global.placeholder}
              style={styles.recipientAmount}
              left={
                <TextInput.Affix
                  text={
                    selectedMethod.calc_method === "fadelUSD"
                      ? "دولار"
                      : selectedCountry.currency_ar
                  }
                />
              }
              mode="outlined"
              keyboardType="decimal-pad"
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
                paddingStart: 50,
                backgroundColor: "#FFFEE8",
                borderColor: "#FF8C42",
                borderRadius: 5,
                borderWidth: 0.5,
                display:
                  selectedMethod.exchangeRate.target_currency === "usd" &&
                  recipientAmount % 1 !== 0
                    ? "flex"
                    : "none",
              }}
            >
              <IconButton icon="alert" iconColor="#ebb703" size={25} />
              <Text
                style={{
                  fontWeight: Global.fontWeight,
                  color: "gray",
                  lineHeight: 23,
                  maxWidth: Dimensions.get("window").width * 0.7,
                  writingDirection: "rtl",
                }}
              >
                يرجى إخال مبلغ الاستلام بدون فواصل{" "}
                <Text
                  style={{
                    color: "#263e5b",
                    lineHeight: 23,
                  }}
                  onPress={() => {
                    if (Number(mainStore.balance) <= amount) {
                      // Round down to the nearest integer
                      setRecipientAmount(
                        Math.floor(recipientAmount).toString()
                      );
                      debouncedTextChange2(
                        Math.floor(recipientAmount).toString()
                      );
                    } else {
                      // Round up to the nearest integer
                      setRecipientAmount(Math.ceil(recipientAmount).toString());
                      debouncedTextChange2(
                        Math.ceil(recipientAmount).toString()
                      );
                    }
                  }}
                >
                  انقر هنا للتقريب
                </Text>
                .
              </Text>
            </View>
            <View
              style={{
                // paddingStart: 20,
                width: "100%",
                alignSelf: "center",
                borderRadius: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <IconButton icon="information-outline" size={25} />
                <Text
                  style={{
                    color: Global.textcolor,
                    fontWeight: Global.fontWeight,
                    lineHeight: 25,
                    writingDirection: "rtl",
                  }}
                >
                  {selectedMethod.notes}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  display:
                    (selectedCity.name === "القنيطرة" ||
                      selectedCity.name === "اللاذقية") &&
                    selectedMethod.name === "تسليم باليد"
                      ? "flex"
                      : "none",
                }}
              >
                <IconButton icon="information-outline" size={25} />
                <Text
                  style={{
                    color: Global.textcolor,
                    fontWeight: Global.fontWeight,
                    lineHeight: 25,
                    writingDirection: "rtl",
                  }}
                >
                  {selectedCity.name === "القنيطرة"
                    ? "يوجد أجور داخلية لكل مليون 15 ألف ليرة سورية خاصة بالقنيطرة"
                    : "يوجد أجور توصيل ثابتة حوالي 35 ألف للحوالات تحت الـ 3 مليون في اللاذقية"}
                </Text>
              </View>
            </View>
          </View>
        )}
        {step === 2 && (
          <View style={{ marginTop: 20 }}>
            {!ShowAddContact ? (
              <>
                <Text style={styles.label}>إلى من ترغب بالإرسال؟</Text>
                <Searchbar
                  value={searchQuery}
                  onChangeText={handleSearchChange}
                  placeholderTextColor={Global.graycolor}
                  right={() => (
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        icon={"close"}
                        iconColor={Global.primary}
                        size={30}
                        onPress={() => {
                          setSearchQuery("");
                          handleSearchChange("");
                        }}
                      />
                    </View>
                  )}
                  placeholder="ابحث عن مستلم"
                  style={{
                    marginVertical: 10,
                    display:
                      mainStore.recipients.length === 0 ? "none" : "flex",
                    backgroundColor: "transparent",
                    borderColor: Global.textcolor,
                    borderWidth: 1,
                  }}
                />
                <TouchableOpacity
                  onPress={setShowAddContact}
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginVertical: 25,
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "#ebb703",
                      marginStart: 20,
                    }}
                    icon={"plus"}
                    iconColor={"white"}
                    size={30}
                  />
                  <Text>أضف مستلم جديد</Text>
                </TouchableOpacity>
                <Text
                  style={
                    ([styles.label],
                    {
                      display:
                        mainStore.recipients.length === 0 ? "none" : "flex",
                    })
                  }
                >
                  الكل
                </Text>
                <Divider />
                <FlatList
                  data={reversedContacts}
                  renderItem={renderContactItem}
                  keyExtractor={(item, index) => item.id || index.toString()}
                  contentContainerStyle={{ paddingVertical: 20 }}
                />
              </>
            ) : (
              <View>
                <Text style={styles.label}>
                  {selectedCountry.name === "سوريا"
                    ? "الاسم الثلاثي للمستلم:"
                    : "اسم المستلم:"}
                </Text>
                <TextInput
                  placeholder={
                    selectedCountry.name === "أوروبا" ||
                    selectedCountry.name === "المغرب" ||
                    selectedCountry.name === "الجزائر" ||
                    selectedCountry.name === "تونس"
                      ? "Ahmed Salem"
                      : "مثلًا: أحمد محمد فؤاد"
                  }
                  placeholderTextColor={Global.placeholder}
                  value={recipientName}
                  onChangeText={(text) => {
                    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F]/g;
                    // Replace Arabic characters with an empty string
                    const filteredText = text.replace(arabicRegex, "");
                    const noArabic =
                      selectedCountry.name === "المغرب" ||
                      selectedCountry.name === "الجزائر" ||
                      selectedCountry.name === "تونس";

                    setRecipientName(noArabic ? filteredText : text);
                  }}
                  style={styles.input}
                  mode="outlined"
                  right={<TextInput.Icon icon="account-tie" />}
                />
                <Text style={styles.label}>
                  {selectedCountry.name === "روسيا" ||
                  selectedMethod.calc_method === "bankIQ"
                    ? "رقم البطاقة للمستلم:"
                    : selectedMethod.calc_method === "bankTransferUAE"
                    ? "اسم البنك:"
                    : selectedChoice === "Wise"
                    ? "الـ IBAN أو Wise Email:"
                    : selectedChoice === "Revolut"
                    ? "الـ IBAN أو Revtag:"
                    : selectedMethod.calc_method === "externalBank"
                    ? "رقم الـ IBAN:"
                    : "رقم هاتف المستلم:"}
                </Text>
                <View style={styles.phoneInputContainer}>
                  <TextInput
                    value={phoneNumber}
                    keyboardType={
                      selectedCountry.name === "سوريا" ? "phone-pad" : "default"
                    }
                    placeholder={
                      selectedCountry.name === "روسيا" ||
                      selectedMethod.calc_method === "bankIQ"
                        ? "1234 5678 1111 1111"
                        : selectedMethod.calc_method === "bankTransferUAE"
                        ? "بنك الإمارات مثلا"
                        : selectedCountry.name === "أوروبا"
                        ? "LT123456789985"
                        : "0912345678"
                    }
                    placeholderTextColor={Global.placeholder}
                    onChangeText={setPhoneNumber}
                    style={styles.phoneNumberInput}
                    mode="outlined"
                  />
                </View>
                {selectedMethod.calc_method === "bankIQ" && (
                  <>
                    <Text style={styles.label}>رقم الحساب:</Text>
                    <TextInput
                      placeholder={"1234567890"}
                      placeholderTextColor={Global.placeholder}
                      value={bankNo}
                      onChangeText={(text) => setBankNo(text)}
                      style={styles.input}
                      mode="outlined"
                    />
                  </>
                )}
                {selectedMethod.calc_method === "bankTransferUAE" && (
                  <>
                    <Text style={styles.label}>رقم الحساب:</Text>
                    <TextInput
                      placeholder={"1234567890"}
                      placeholderTextColor={Global.placeholder}
                      value={bankNo}
                      onChangeText={(text) => setBankNo(text)}
                      style={styles.input}
                      mode="outlined"
                    />
                    <Text style={styles.label}>رقم الـ IBAN:</Text>
                    <TextInput
                      placeholder={"AE07 0331 2345 6789 0123 456"}
                      placeholderTextColor={Global.placeholder}
                      value={iban}
                      onChangeText={(text) => setIban(text)}
                      style={styles.input}
                      mode="outlined"
                    />
                  </>
                )}
                <View
                  style={{
                    display: selectedCountry.name === "سوريا" ? "flex" : "none",
                  }}
                >
                  <Text style={styles.label}>انتبه لما يلي:</Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 20,
                      backgroundColor: "#FFFEE8",
                      borderColor: "#FF8C42",
                      borderRadius: 5,
                      borderWidth: 0.5,
                      display: true ? "flex" : "none",
                    }}
                  >
                    <IconButton icon="alert" iconColor="#ebb703" size={25} />
                    <Text
                      style={{
                        fontWeight: Global.fontWeight,
                        color: "black",
                        lineHeight: 20,
                        maxWidth: Dimensions.get("window").width * 0.7,
                      }}
                    >
                      الاسم الثلاثي يجب أن يكون مطابقًا للهوية
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 20,
                      backgroundColor: "#FFFEE8",
                      borderColor: "#FF8C42",
                      borderRadius: 5,
                      borderWidth: 0.5,
                      display:
                        selectedMethod.calc_method === "agent"
                          ? "flex"
                          : "none",
                    }}
                  >
                    <IconButton icon="alert" iconColor="#ebb703" size={25} />
                    <Text
                      style={{
                        fontWeight: Global.fontWeight,
                        color: "black",
                        lineHeight: 20,
                        maxWidth: Dimensions.get("window").width * 0.7,
                      }}
                    >
                      يجب أن يكون رقم الهاتف متصلًا بحساب واتس أب فعال
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 20,
                      backgroundColor: "#FFFEE8",
                      borderColor: "#FF8C42",
                      borderRadius: 5,
                      borderWidth: 0.5,
                      display:
                        selectedMethod.calc_method === "agent"
                          ? "flex"
                          : "none",
                    }}
                  >
                    <IconButton icon="alert" iconColor="#ebb703" size={25} />
                    <Text
                      style={{
                        fontWeight: Global.fontWeight,
                        color: "black",
                        lineHeight: 20,
                        maxWidth: Dimensions.get("window").width * 0.7,
                      }}
                    >
                      أي تعديل على الاسم أو الرقم سيؤدي لتأخير تسليم الحوالة
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 20,
                      backgroundColor: "#FFFEE8",
                      borderColor: "#FF8C42",
                      borderRadius: 5,
                      borderWidth: 0.5,
                      display:
                        selectedMethod.calc_method === "localSy"
                          ? "flex"
                          : "none",
                    }}
                  >
                    <IconButton icon="alert" iconColor="#ebb703" size={25} />
                    <Text
                      style={{
                        fontWeight: Global.fontWeight,
                        color: "black",
                        lineHeight: 20,
                        maxWidth: Dimensions.get("window").width * 0.7,
                      }}
                    >
                      إلغاء الحوالة عبر "إشعار داخلي" غير ممكن، ويمكن التعديل
                      بشكل مأجور
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        )}
        {step === 3 && (
          <View style={{ marginTop: 20 }}>
            <ReviewScreen
              props={{
                previousRouteName: selectedMethod.route_name,
                amount: amount,
                recipientName: recipientName,
                recipientAmount: recipientAmount,
                phone:
                  phoneNumber +
                  (selectedMethod.calc_method === "bankTransferUAE" ||
                  selectedMethod.calc_method === "bankIQ"
                    ? " - " + bankNo + (iban ? " - " : "") + iban
                    : ""),
                city: selectedCity.name,
                country: selectedCountry.name,
                currency:
                  selectedMethod.calc_method === "fadelUSD"
                    ? "usd"
                    : selectedCountry.cur_code_en.toLocaleLowerCase(),
                currencyAr:
                  selectedMethod.calc_method === "fadelUSD"
                    ? "دولار"
                    : selectedCountry.currency_ar,
                method: selectedMethod.id,
                choice:
                  !Array.isArray(selectedMethod.choices) &&
                  selectedMethod.choices !== null
                    ? selectedCountry.name === "تركيا" ||
                      selectedCountry.name === "الشمال السوري"
                      ? selectedChoice + " - " + userNotes
                      : selectedChoice
                    : "",
                calcMethod: selectedMethod.calc_method,
              }}
              setStep={setStep}
              setSuccsess={setSuccsess}
            />
          </View>
        )}
        {step === 4 && (
          <View style={{ marginTop: 20 }}>
            <ResultScreen
              props={{
                time: selectedMethod.estimated_arrival_time,
                previousRouteName:
                  selectedMethod.calc_method === "sawa"
                    ? selectedMethod.route_name
                    : "externalTransfer",
              }}
              navigation={navigation}
            />
          </View>
        )}
        {step < 3 && (
          <View style={styles.buttonsContainer}>
            {step > 1 && (
              <Button
                labelStyle={{ fontSize: 16 }}
                contentStyle={{ height: 40 }}
                disabled={step > 3 ? false : buttonDisabled && false}
                mode="outlined"
                onPress={handleBack}
                style={styles.button}
              >
                السابق
              </Button>
            )}

            <Button
              disabled={
                !(Number(mainStore.balance) >= amount) ||
                amount <= 0 ||
                recipientAmount <= 0 ||
                recipientAmount < selectedMethod.min_amount ||
                isMaxAmount ||
                editing ||
                editing2 ||
                (selectedMethod.exchangeRate.target_currency === "usd" &&
                  recipientAmount % 1 !== 0) ||
                (step === 2 && phoneNumber === "") ||
                (step === 2 &&
                  selectedCountry.name !== "مصر" &&
                  recipientName === "")
              }
              labelStyle={{ fontSize: 16 }}
              contentStyle={{ height: 40 }}
              mode="contained"
              onPress={handleSubmit}
              style={[
                styles.button,
                { display: step == 2 && !ShowAddContact ? "none" : "flex" },
              ]}
            >
              {ShowAddContact ? "إضافة" : "التالي"}
            </Button>
          </View>
        )}
        <Modal visible={modal2Visible} transparent={true} animationType="fade">
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            activeOpacity={1}
            onPress={() => setModal2Visible(false)}
            onRequestClose={() => setModal2Visible(false)}
          >
            <View
              padding={8}
              style={{
                width: "90%",
                backgroundColor: Global.backgroundColor,
                borderRadius: 10,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton icon="information-outline" color={"red"} size={50} />
              <Text
                paddingBottom={10}
                style={{
                  textAlign: "center",
                  fontWeight: Global.fontWeight,
                  lineHeight: 23,
                  marginHorizontal: 20,
                  marginBottom: 20,
                }}
              >
                الإشعار الداخلي متوقف حاليًا بسبب قرار البنك المركزي الأخير
              </Text>
            </View>

            <Button
              mode="contained"
              style={{
                alignSelf: "center",
                width: "90%",
                height: 50,
                justifyContent: "center",
                margin: 10,
                fontFamily: "Tajawal-Bold",
              }}
              onPress={() => setModal2Visible(false)}
            >
              موافق
            </Button>
          </TouchableOpacity>
        </Modal>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 0,
    flex: 1,
    backgroundColor: Global.backgroundColor,
  },
  title: {
    fontSize: 19,
    marginBottom: 10,
  },

  timerText: {
    color: Global.graycolor,
    marginBottom: 5,
    marginTop: -5,
    fontSize: 13,
  },
  infoBox: {
    backgroundColor: Global.cardcolor,
    padding: 10,
    width: "100%",
    alignSelf: "center",
    borderRadius: 5,
    borderWidth: 1,
    margin: 10,
    borderColor: Global.graycolor,
  },
  imageContainer: {
    marginVertical: 24,
    alignItems: "center",
  },
  image: {
    width: 200,
    height: 200,
  },

  input2: {
    flex: 1,
  },
  stepIndicators: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 20,
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
    borderRadius: 5,
  },
  stepText: {
    fontSize: 18,
    marginBottom: 10,
  },
  chipsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
  },
  modal: {
    backgroundColor: Global.backgroundColor,
    padding: 20,
    marginLeft: "5%",
    marginRight: "5%",
    marginBottom: "40%",
  },

  contentContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  timeOption: {
    width: "100%",
    paddingVertical: 12,
    borderTopColor: Global.textcolor,
    borderTopWidth: 0.3,
  },
  input: {
    fontWeight: "bold",
    fontSize: 20,
    padding: 7.5,
    marginBottom: 20,
    width: "100%",
    textAlign: "auto",
    flex: 1.6,
  },
  phoneNumberInput: {
    fontWeight: "bold",
    fontSize: 20,
    padding: 7.5,
    //marginBottom: 10,
    width: "100%",
    textAlign: "left",
    //marginRight: 10,
  },
  phoneInputContainer: {
    flexDirection: "row",
    marginBottom: Global.isios ? 30 : 20,
  },

  hintBox: {
    borderRadius: 5,
    padding: 10,
    marginVertical: 20,
    //marginTop: -10,
    //borderWidth: 1,
    //borderStyle: 'dashed',
    borderColor: Global.textcolor,
  },
  labelText: {
    color: Global.graycolor,
    fontSize: 15,
    fontWeight: Global.fontWeight,
  },
  ios: {
    fontWeight: Global.fontWeight,
    fontSize: 15,
    color: Global.textcolor,
    fontFamily: "Tajawal",
  },
  alert: {
    fontSize: 16,
    marginStart: 5,
  },
  recipientAmount: {
    fontWeight: "bold",
    fontSize: 20,
    padding: 7.5,
    textAlign: "auto",
  },

  label: {
    marginBottom: 10,
    fontWeight: Global.fontWeight,
    fontSize: 16,
    lineHeight: 22,
    writingDirection: "rtl",
  },
});

export default observer(ExternalTransferScreen);
