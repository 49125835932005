/* eslint-disable no-unused-expressions */

import React, { useContext, useRef, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Share as Sharee,
  Animated,
  KeyboardAvoidingView,
  Keyboard,
  ScrollView,
  Image,
  Modal,
  Text as Txt,
  Dimensions,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import {
  Title,
  Text,
  IconButton,
  Button,
  Snackbar,
  ActivityIndicator,
  MD2Colors,
  Dialog,
  Portal,
  Divider,
  TextInput,
  SegmentedButtons,
  configureFonts,
  Chip,
} from "react-native-paper";
import { Platform } from "react-native";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import { useIsFocused } from "@react-navigation/native";
import Global from "../../Global";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import debounce from "lodash.debounce";
import Octicons from "react-native-vector-icons/Octicons";
import SkeletonPlaceholder from "../../react-native-skeleton-placeholder-master/src/skeleton-placeholder";

const MySkeletonPlaceholder = ({
  width = 150,
  height = 20,
  borderRadius = 5,
}) => {
  return (
    <SkeletonPlaceholder
      borderRadius={borderRadius}
      speed={300}
      backgroundColor={Global.placeholder}
    >
      <SkeletonPlaceholder.Item width={width} height={height} />
    </SkeletonPlaceholder>
  );
};
const CURRENCIES = [
  { label: "يورو", value: "eur", symbol: "EUR" },
  { label: "دولار أمريكي", value: "usd", symbol: "USD" },
  { label: "جنيه استرليني", value: "gbp", symbol: "GBP" },
];
const ElectronicBankDepositScreen = ({ navigation }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [editing, setediting] = useState(false);
  const [editing2, setediting2] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [amount, setAmount] = useState("150");
  const [reference, setReference] = useState("");
  const [SorF, setSorF] = useState("");
  const [viewBankDetails, setviewBankDetails] = useState(false);
  const [recipientAmount, setRecipientAmount] = useState(" ");
  const [total, setTotal] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCIES[0]);
  const [amountFoucs, setAmountFoucs] = useState(false);
  const [rAmountFoucs, setRAmountFoucs] = useState(false);
  const debouncedTextChange = debounce(async (amount, Currency) => {
    setediting(true);
    if (!editing2) {
      setShowSkeleton(true);
      await mainStore.calc(
        amount,
        Currency,
        "usd",
        "deposit",
        "bank-transfer",
        true
      );
      mainStore.feeDetails.receiving_amount < 0 || amount === ""
        ? setRecipientAmount("0")
        : setRecipientAmount(
            Number(mainStore.feeDetails.receiving_amount).toFixed(2).toString()
          );
      setTotal(
        Math.max((amount - mainStore.feeDetails.fee).toFixed(2), 0).toString()
      );
      setShowSkeleton(false);
    }
    setediting(false);
  }, 500);
  const debouncedTextChange2 = debounce(async (amount, Currency) => {
    setediting2(true);

    if (!editing) {
      setShowSkeleton(true);
      await mainStore.calc(
        amount,
        Currency,
        "usd",
        "deposit",
        "bank-transfer",
        false
      );
      mainStore.feeDetails.receiving_amount < 0 || recipientAmount === ""
        ? setAmount("0")
        : setAmount(
            Number(mainStore.feeDetails.sending_amount).toFixed(2).toString()
          );

      setTotal(
        Math.max(
          (
            mainStore.feeDetails.sending_amount - mainStore.feeDetails.fee
          ).toFixed(2),
          0
        ).toString()
      );
      setShowSkeleton(false);
    }
    setediting2(false);
  }, 500);
  useEffect(() => {
    debouncedTextChange(amount, selectedCurrency.value);
    return () => {
      debouncedTextChange.cancel();
    };
  }, [selectedCurrency.value]);
  useEffect(() => {
    debouncedTextChange(amount, selectedCurrency.value);

    return () => {
      debouncedTextChange2.cancel();
    };
  }, []);

  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);

  useEffect(() => {
    //    // amount === '' && debouncedTextChange('0', selectedCurrency.value);
  }, [amount]);

  useEffect(() => {
    //    //recipientAmount === '' && debouncedTextChange2('0', selectedCurrency.value);
  }, [recipientAmount]);

  useEffect(() => {
    let randomString = "Y";
    for (let i = 0; i < 6; i++) {
      randomString += Math.floor(Math.random() * 10);
    }
    setReference(randomString);
  }, []);

  const showDialog = () => {
    Keyboard.dismiss();
    setDialogVisible(true);
  };
  const hideDialog = () => setDialogVisible(false);
  const selectCurrency = (currency) => {
    setSelectedCurrency(currency);
    hideDialog();
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    isFocused ? mainStore.stackState("opened") : mainStore.stackState("closed");
  }, [mainStore.address, isFocused]);
  useEffect(() => {
    value != "Wise" && setSelectedCurrency(CURRENCIES[0]);
  });

  const [modal2Visible, setModal2Visible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState("");
  const [border, setBorder] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const onDismissSnackBar = () => setSnackbarVisible(false);
  const walletAddress = mainStore.address;
  const networkName = "Tron (TRC20)";
  const minDepositAmount = selectedCurrency.symbol === "TRY" ? 350 : 20;
  const depositTime = "24 ساعة ضمن أيام العمل";
  const viewShotRef = useRef();

  if (mainStore.loading || !mainStore.transactionsLoaded) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "height" : "height"}
      style={{ flex: 1, backgroundColor: Global.backgroundColor }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        style={{ flex: 1, backgroundColor: Global.backgroundColor }}
      >
        <View style={styles.container}>
          <Portal>
            <Dialog visible={dialogVisible} onDismiss={hideDialog}>
              <Dialog.Title>اختر العملة</Dialog.Title>
              <ScrollView showsVerticalScrollIndicator={false}>
                {CURRENCIES.map((currency, index) => (
                  <React.Fragment key={currency.symbol}>
                    <Dialog.Content>
                      <TouchableOpacity
                        onPress={() => selectCurrency(currency)}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            display:
                              index !== 0 && value !== "Wise" ? "none" : "flex",
                          }}
                        >
                          {currency.label} / {currency.symbol}
                        </Text>
                        {index < CURRENCIES.length - 1 && (
                          <Divider
                            style={{
                              marginTop: 20,
                              display: value !== "Wise" ? "none" : "flex",
                            }}
                          />
                        )}
                      </TouchableOpacity>
                    </Dialog.Content>
                  </React.Fragment>
                ))}
              </ScrollView>
              <Dialog.Actions style={{ justifyContent: "center" }}>
                <Button onPress={hideDialog}>إلغاء</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
          <Text
            style={{
              fontSize: 17,
              marginVertical: 15,
              fontWeight: Global.fontWeight,
              writingDirection: "rtl",
            }}
          >
            اختيار وسيلة الإيداع:
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Chip
              mode={Global.isDark ? "flat" : "outlined"}
              selected={value === "Wise"}
              showSelectedOverlay={true}
              selectedColor={value === "Wise" ? Global.primary : undefined}
              elevated={true}
              textStyle={{ fontSize: 18, alignSelf: "center" }}
              style={{
                flex: 1,
                marginStart: 10,
                alignContent: "center",
                alignItems: "center",
                padding: 5,
                paddingEnd: 10,
              }}
              icon={() => (
                <Image
                  source={{
                    uri: Global.wise,
                  }}
                  style={{
                    width: 30,
                    height: 30,
                    resizeMode: "contain",
                  }}
                />
              )}
              onPress={() => setValue("Wise")}
            >
              Wise
            </Chip>
            <Chip
              mode={Global.isDark ? "flat" : "outlined"}
              selected={value === "Revolut"}
              showSelectedOverlay={true}
              selectedColor={value === "Revolut" ? Global.primary : undefined}
              elevated={true}
              textStyle={{ fontSize: 18, alignSelf: "center" }}
              style={{
                flex: 1,
                alignContent: "center",
                alignItems: "center",
                padding: 5,
                paddingEnd: 10,
              }}
              icon={() => (
                <Image
                  source={{
                    uri: Global.revolut,
                  }}
                  style={{
                    width: 30,
                    height: 30,
                    resizeMode: "contain",
                  }}
                />
              )}
              onPress={() => setValue("Revolut")}
            >
              Revolut
            </Chip>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Chip
              mode={Global.isDark ? "flat" : "outlined"}
              selected={value === "N26"}
              showSelectedOverlay={true}
              selectedColor={value === "N26" ? Global.primary : undefined}
              elevated={true}
              showSelectedCheck={false}
              icon={() => (
                <View
                  style={{
                    width: 40,
                    height: 40,
                  }}
                />
              )}
              textStyle={{
                fontSize: 18,
                alignSelf: "center",
              }}
              style={{
                flex: 1,
                marginStart: 10,
                alignContent: "center",
                alignItems: "center",
              }}
              onPress={() => setValue("N26")}
            >
              N26
            </Chip>
            <Chip
              mode={Global.isDark ? "flat" : "outlined"}
              selected={value === "Paysera"}
              showSelectedOverlay={true}
              selectedColor={value === "Paysera" ? Global.primary : undefined}
              elevated={true}
              showSelectedCheck={false}
              icon={() => (
                <View
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              )}
              textStyle={{ fontSize: 18, alignSelf: "center" }}
              style={{
                flex: 1,
                alignContent: "center",
                alignItems: "center",
              }}
              onPress={() => setValue("Paysera")}
            >
              Paysera
            </Chip>
          </View>
          <Text
            style={{
              fontSize: 17,
              marginVertical: 15,
              fontWeight: Global.fontWeight,
            }}
          >
            :المبلغ المراد إيداعه
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <TextInput
              label="المبلغ"
              value={amountFoucs ? amount : Global.arabicToNumeric(amount)}
              onChangeText={(text) => {
                const normalizedText = text.replace(/,/g, ".");
                setAmount(normalizedText);
                debouncedTextChange(normalizedText, selectedCurrency.value);
              }}
              onFocus={() => {
                setAmountFoucs(true);
              }}
              onBlur={() => {
                setAmountFoucs(false);
              }}
              // contentStyle={styles.sendButtonContent}
              style={{
                flex: 1,
                alignSelf: "center",
                textAlign: "auto",
                fontSize: 20,
              }}
              // right={<TextInput.Icon icon="currency-usd" />}
              keyboardType="decimal-pad"
              mode="outlined"
            />

            <Button
              labelStyle={{ fontSize: 18, marginRight: 10 }}
              contentStyle={{ height: 50, direction: "ltr", marginLeft: -5 }}
              style={{
                borderRadius: 0,
                alignSelf: "center",
                width: 90,
                marginBottom: -5,
                marginRight: 10,
                height: 47,
              }}
              mode="contained"
              onPress={showDialog}
              icon={() => (
                <Icon
                  name="chevron-down"
                  size={30}
                  color={Global.buttonTextColor}
                  style={{ marginTop: -5, marginLeft: -10 }}
                />
              )}
            >
              {`${selectedCurrency.symbol}`}
            </Button>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
              marginTop: 10,
              paddingStart: 50,
              backgroundColor: "#FFFEE8",
              borderColor: "#FF8C42",
              borderRadius: 5,
              borderWidth: 0.5,
              display: amount % 1 !== 0 ? "flex" : "none",
            }}
          >
            <IconButton icon="alert" iconColor="#ebb703" size={25} />
            <Text
              style={{
                fontWeight: Global.fontWeight,
                color: "gray",
                lineHeight: 23,
                maxWidth: Dimensions.get("window").width * 0.7,
                writingDirection: "rtl",
              }}
            >
              يرجى إخال مبلغ الإرسال بدون فواصل{" "}
              <Text
                style={{
                  color: "#263e5b",
                  lineHeight: 23,
                }}
                onPress={() => {
                  setAmount(Math.round(amount).toString());
                  debouncedTextChange(
                    Math.round(amount).toString(),
                    selectedCurrency.value
                  );
                }}
              >
                انقر هنا للتقريب
              </Text>
              .
            </Text>
          </View>
          <View style={styles.hintBox}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 13,
                alignItems: "flex-end",
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                <Icon
                  name="minus-circle-outline"
                  size={21}
                  style={{ marginLeft: 3, color: Global.graycolor }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {mainStore.feeDetails.fee.toLocaleString("en") +
                      " " +
                      selectedCurrency.label}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>أجور المعاملة</Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: 13,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <Icon
                  name="pause-circle-outline"
                  size={21}
                  style={{
                    transform: [{ rotate: "90deg" }],
                    marginLeft: 6,
                    color: Global.graycolor,
                  }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {Global.arabicToNumeric(total) +
                      " " +
                      selectedCurrency.label}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>الإجمالي بعد الأجور</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 5,
                alignItems: "flex-end",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: Global.isios ? "flex-end" : "center",
                }}
              >
                <Octicons
                  name="x-circle"
                  size={17}
                  style={{
                    marginLeft: 4,
                    color: Global.graycolor,
                    marginRight: 2,
                  }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {" "}
                    {mainStore.feeDetails.exchangeRate + " دولار"}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>سعر الصرف</Text>
            </View>
          </View>
          <Text
            style={{
              fontSize: 17,
              fontWeight: Global.fontWeight,
              marginBottom: 10,
            }}
          >
            :ستحصل على
          </Text>
          <TextInput
            value={
              rAmountFoucs
                ? recipientAmount
                : Global.arabicToNumeric(recipientAmount)
            }
            onChangeText={(text) => {
              const normalizedText = text.replace(/,/g, ".");
              setRecipientAmount(normalizedText);
              debouncedTextChange2(normalizedText, selectedCurrency.value);
            }}
            onFocus={() => {
              setRAmountFoucs(true);
            }}
            onBlur={() => {
              setRAmountFoucs(false);
            }}
            placeholderTextColor={Global.placeholder}
            style={styles.recipientAmount}
            left={<TextInput.Affix text="دولار أمريكي" />}
            mode="outlined"
            keyboardType="decimal-pad"
          />
          <Button
            mode="contained"
            onPress={async () => {
              await mainStore.getBankDetails(value);
              if (true) {
                setModalVisible(true);
              } else {
                await mainStore.Deposit(
                  amount,
                  "wise",
                  value,
                  selectedCurrency.value
                );
                await mainStore.getLatestTransactions();
                mainStore.errorMessage !== ""
                  ? (setSorF(mainStore.errorMessage), setModal2Visible(true))
                  : navigation.navigate("BankDetailsScreen", [
                      reference,
                      mainStore.latestTransactions[0],
                    ]);
              }
            }}
            disabled={
              recipientAmount <= 0 ||
              editing ||
              amount % 1 !== 0 ||
              editing2 ||
              !value ||
              amount < 20
            }
            contentStyle={{ height: 50 }}
            labelStyle={{ fontSize: 18, paddingTop: 10 }}
            style={{ marginTop: 20, borderRadius: 5 }}
          >
            تأكيد
          </Button>
          <View
            style={{
              width: "100%",
              alignSelf: "center",
              borderRadius: 5,
              marginVertical: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <IconButton icon="information-outline" size={20} />
              <Text
                style={{
                  color: Global.textcolor,
                  fontWeight: Global.fontWeight,
                  lineHeight: 20,
                  writingDirection: "rtl",
                }}
              >
                الحد الأدنى للتحويل: {minDepositAmount}{" "}
                {selectedCurrency.symbol}
              </Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <IconButton icon="information-outline" size={20} />
              <Text
                style={{
                  color: Global.textcolor,
                  fontWeight: Global.fontWeight,
                  lineHeight: 20,
                }}
              >
                سيتم الإيداع في محفظتك فور تأكيد وصول الحوالة إلى البنك
                الإلكتروني الذي وضحته في الأعلى
              </Text>
            </View>
          </View>
          <Modal
            visible={modal2Visible}
            transparent={true}
            animationType="fade"
          >
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              activeOpacity={1}
              onPress={() => setModal2Visible(false)}
              onRequestClose={() => setModal2Visible(false)}
            >
              <View
                padding={8}
                style={{
                  width: "90%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  icon="information-outline"
                  color={"red"}
                  size={50}
                />
                <Text
                  paddingBottom={10}
                  style={{
                    textAlign: "center",
                    fontWeight: Global.fontWeight,
                    lineHeight: 23,
                    marginHorizontal: 20,
                    marginBottom: 20,
                  }}
                >
                  {SorF}
                </Text>
              </View>

              <Button
                mode="contained"
                style={{
                  alignSelf: "center",
                  width: "90%",
                  height: 50,
                  justifyContent: "center",
                  margin: 10,
                  fontFamily: "Tajawal-Bold",
                }}
                onPress={() =>
                  mainStore.errorMessage !== ""
                    ? setModal2Visible(false)
                    : (setModal2Visible(false),
                      navigation.navigate("HomeScreen"))
                }
              >
                موافق
              </Button>
            </TouchableOpacity>
          </Modal>
          <Modal
            visible={modalVisible}
            animationType={"fade"}
            transparent={true}
          >
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              activeOpacity={1}
              onPress={() => setModalVisible(false)}
              onRequestClose={() => setModalVisible(false)}
            >
              <View
                padding={8}
                style={{
                  maxWidth: "90%",
                  backgroundColor: Global.backgroundColor,
                  borderRadius: 10,
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <IconButton
                  icon="information-outline"
                  color={"red"}
                  size={50}
                />

                <Txt
                  style={{
                    fontSize: 15,
                    textAlign: "center",
                    //paddingBottom: 10,
                    lineHeight: 25,
                    fontFamily: "Tajawal",
                    color: Global.textcolor,
                    marginHorizontal: 15,
                  }}
                >
                  في المرحلة التالية سيكون مطلوبًا منكم التواصل معنا حتى نزودكم
                  بالحساب اللازم للتحويل عبر {value}
                </Txt>
                <Text
                  style={{
                    fontSize: 15,
                    textAlign: "center",
                    paddingVertical: 10,
                    lineHeight: 23,
                    color: "red",
                    marginHorizontal: 15,
                  }}
                >
                  عند تأكيد العملية يعني أنك جاهز للإيداع في الحال
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "80%",
                  justifyContent: "center",
                }}
              >
                <Button
                  labelStyle={{ fontSize: 16 }}
                  mode="contained"
                  style={{
                    width: "45%",
                    borderRadius: 10,
                    //  flex: 1,
                    // alignSelf: "stretch",
                    height: 50,
                    justifyContent: "center",
                    margin: 10,
                  }}
                  onPress={async () => {
                    setModalVisible(false);
                    await mainStore.Deposit(
                      amount,
                      "wise",
                      value,
                      selectedCurrency.value
                    );
                    await mainStore.getLatestTransactions();
                    mainStore.errorMessage !== ""
                      ? (setSorF(mainStore.errorMessage),
                        setModal2Visible(true))
                      : navigation.navigate("BankDetailsScreen", [
                          reference,
                          mainStore.latestTransactions[0],
                        ]);
                  }}
                >
                  تأكيد
                </Button>
                <Button
                  labelStyle={{ fontSize: 16 }}
                  mode="contained-tonal"
                  style={{
                    width: "45%",
                    borderRadius: 10,
                    height: 50,
                    justifyContent: "center",
                    margin: 10,
                  }}
                  onPress={() => setModalVisible(false)}
                >
                  إلغاء
                </Button>
              </View>
            </TouchableOpacity>
          </Modal>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: Global.backgroundColor,
    paddingTop: 20,
  },
  title: {
    fontSize: 27,
    fontWeight: "bold",
    marginBottom: 20,
  },
  viewShotContainer: {
    alignItems: "center",
  },
  label: {
    fontWeight: Global.fontWeight,
    marginTop: 10,
    color: Global.graycolor,
  },
  addressRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoBox: {},
  shareButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    width: "100%",
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
  recipientAmount: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "auto",
  },
  hintBox: {
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  labelText: {
    color: Global.graycolor,
    fontSize: 16,
    fontWeight: Global.fontWeight,
  },
  ios: {
    fontWeight: Global.fontWeight,
    fontSize: 16,
    color: Global.textcolor,
    fontFamily: "Tajawal",
  },
});

export default observer(ElectronicBankDepositScreen);
