import AsyncStorage from "@react-native-async-storage/async-storage";
import { Appearance, Platform, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";

const colorScheme = Appearance.getColorScheme();
var Global = {
  isDark: colorScheme === "dark" ? true : false,
  backgroundColor: colorScheme === "dark" ? "#192029" : "white",
  modaloverlay: "rgba(0,0,0,0.7)",
  textcolor: colorScheme === "dark" ? "white" : "black",
  buttonTextColor: colorScheme === "dark" ? "#192029" : "white",
  graycolor: colorScheme === "dark" ? "#8f9cab" : "gray",
  primary: colorScheme === "dark" ? "#ebb703" : "#263e5b",
  callingcode: colorScheme === "dark" ? "#1C1B1F" : "#263e5b",
  overlay:
    colorScheme === "dark" ? "rgba(100, 100, 100, 0.3)" : "rgba(0, 0, 0, 0.3)",

  secondary: colorScheme === "dark" ? "#f9c307" : "#263e5b",
  bordercolor: colorScheme === "dark" ? "gray" : "#eee",
  // primary: colorScheme === 'dark' ? '#1585b1' : '#1585b1',
  iconcolor: colorScheme === "dark" ? "lightgray" : "#444",
  cardcolor: colorScheme === "dark" ? "#333" : "#eee",
  slaydcolor: colorScheme === "dark" ? "#192029" : "#white",
  inputleft: colorScheme === "dark" ? "#eee" : "#333",
  amountbackground: colorScheme === "dark" ? "#333" : "#fff",
  placeholder: colorScheme === "dark" ? "gray" : "lightgray",
  fontWeight: Platform.OS === "ios" ? "900" : "normal",
  statusbar: colorScheme === "dark" ? "light-content" : "dark-content",
  wise:
    colorScheme === "dark"
      ? "https://companieslogo.com/img/orig/WISE.L.D-892d6805.png?t=1688555693"
      : "https://cdn.icon-icons.com/icons2/3915/PNG/512/wise_logo_icon_249172.png",
  revolut:
    colorScheme === "dark"
      ? "https://cryptomeister.com/wp-content/uploads/revolut-logo.png"
      : "https://static-00.iconduck.com/assets.00/revolut-icon-403x512-rj1zx5yy.png",
  currency: "",
  country: "",
  isios: Platform.OS === "ios" ? true : false,
  isWebIos: /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()),
  arabicToNumeric(arabicNumber) {
    if (arabicNumber !== undefined && arabicNumber !== null) {
      const numberConverted = Number(
        arabicNumber
          .toString()
          .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632)
      );
      return numberConverted.toLocaleString("en");
    }
  },
  async getLastRequestDate() {
    try {
      const value = await AsyncStorage.getItem("@lastReviewRequest");
      return value != null ? parseInt(value) : null;
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  excludedCountries: [
    "AI",
    "AQ",
    "AS",
    "AX",
    "BL",
    "BM",
    "BV",
    "CC",
    "CK",
    "CX",
    "FK",
    "FO",
    "GF",
    "GG",
    "GI",
    "GL",
    "GP",
    "GS",
    "GU",
    "IM",
    "IO",
    "JE",
    "MF",
    "MH",
    "MP",
    "MQ",
    "MS",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "RE",
    "SH",
    "SJ",
    "TF",
    "TK",
    "TV",
    "UM",
    "VA",
    "VG",
    "VI",
    "WF",
    "YT",
    "AD",
    "AI",
    "AN",
    "AS",
    "AW",
    "AX",
    "BB",
    "BL",
    "BM",
    "BQ",
    "BV",
    "CC",
    "CK",
    "CW",
    "CX",
    "DM",
    "FO",
    "GD",
    "GF",
    "GG",
    "GL",
    "GP",
    "GS",
    "GU",
    "JE",
    "KI",
    "KN",
    "KY",
    "LC",
    "LI",
    "MF",
    "MH",
    "MO",
    "MP",
    "MS",
    "MT",
    "MU",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "PW",
    "RE",
    "SH",
    "SJ",
    "SM",
    "SX",
    "TF",
    "TK",
    "TO",
    "TV",
    "UM",
    "VC",
    "VG",
    "VI",
    "VU",
    "WF",
    "YT",
    "AD",
    "AI",
    "AN",
    "AS",
    "AW",
    "AX",
    "BB",
    "BL",
    "BM",
    "BQ",
    "BV",
    "CC",
    "CK",
    "CW",
    "CX",
    "DM",
    "FO",
    "GD",
    "GF",
    "GG",
    "GL",
    "GP",
    "GS",
    "GU",
    "JE",
    "KI",
    "KN",
    "KY",
    "LC",
    "LI",
    "MF",
    "MH",
    "MO",
    "MP",
    "MS",
    "MT",
    "MU",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "PW",
    "RE",
    "SH",
    "SJ",
    "SM",
    "SX",
    "TF",
    "TK",
    "TO",
    "TV",
    "UM",
    "VC",
    "VG",
    "VI",
    "VU",
    "WF",
    "YT",
    "AD",
    "AI",
    "AN",
    "AS",
    "AW",
    "AX",
    "BB",
    "BL",
    "BM",
    "BQ",
    "BV",
    "CC",
    "CK",
    "CW",
    "CX",
    "DM",
    "FO",
    "GD",
    "GF",
    "GG",
    "GL",
    "GP",
    "GS",
    "GU",
    "JE",
    "KI",
    "KN",
    "KY",
    "LC",
    "LI",
    "MF",
    "MH",
    "MO",
    "MP",
    "MS",
    "MT",
    "MU",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "PW",
    "RE",
    "SH",
    "SJ",
    "SM",
    "SX",
    "TF",
    "TK",
    "TO",
    "TV",
    "UM",
    "VC",
    "VG",
    "VI",
    "VU",
    "WF",
    "YT",
    "AD",
    "AI",
    "AN",
    "AS",
    "AW",
    "AX",
    "BB",
    "BL",
    "BM",
    "BQ",
    "BV",
    "CC",
    "CK",
    "CW",
    "CX",
    "DM",
    "FO",
    "GD",
    "GF",
    "GG",
    "GL",
    "GP",
    "GS",
    "GU",
    "JE",
    "KI",
    "KN",
    "KY",
    "LC",
    "LI",
    "MF",
    "MH",
    "MO",
    "MP",
    "MS",
    "MT",
    "MU",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "PW",
    "RE",
    "SH",
    "SJ",
    "SM",
    "SX",
    "TF",
    "TK",
    "TO",
    "TV",
    "UM",
    "VC",
    "VG",
    "VI",
    "VU",
    "WF",
    "YT",
    "AD",
    "AI",
    "AN",
    "AS",
    "AW",
    "AX",
    "BB",
    "BL",
    "BM",
    "BQ",
    "BV",
    "CC",
    "CK",
    "CW",
    "CX",
    "DM",
    "FO",
    "GD",
    "GF",
    "GG",
    "GL",
    "GP",
    "GS",
    "GU",
    "JE",
    "KI",
    "KN",
    "KY",
    "LC",
    "LI",
    "MF",
    "MH",
    "MO",
    "MP",
    "MS",
    "MT",
    "MU",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "PW",
    "RE",
    "SH",
    "SJ",
    "SM",
    "SX",
    "TF",
    "TK",
    "TO",
    "TV",
    "UM",
    "VC",
    "VG",
    "VI",
    "VU",
    "WF",
    "YT",
    "AI",
    "AQ",
    "AS",
    "AX",
    "BL",
    "BM",
    "BV",
    "CC",
    "CK",
    "CX",
    "FK",
    "FO",
    "GF",
    "GG",
    "GI",
    "GL",
    "GP",
    "GS",
    "GU",
    "IM",
    "IO",
    "JE",
    "MF",
    "MH",
    "MP",
    "MS",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "RE",
    "SH",
    "SJ",
    "TF",
    "TK",
    "TV",
    "UM",
    "VA",
    "VG",
    "VI",
    "WF",
    "YT",
    "AD",
    "AI",
    "AN",
    "AS",
    "AW",
    "AX",
    "BB",
    "BL",
    "BM",
    "BQ",
    "BV",
    "CC",
    "CK",
    "CW",
    "CX",
    "DM",
    "FO",
    "GD",
    "GF",
    "GG",
    "GL",
    "GP",
    "GS",
    "GU",
    "JE",
    "KI",
    "KN",
    "KY",
    "LC",
    "LI",
    "MF",
    "MH",
    "MO",
    "MP",
    "MS",
    "MT",
    "MU",
    "NC",
    "NF",
    "NR",
    "NU",
    "PF",
    "PM",
    "PN",
    "PW",
    "RE",
    "SH",
    "SJ",
    "SM",
    "SX",
    "TF",
    "TK",
    "TO",
    "TV",
    "UM",
    "VC",
    "VG",
    "VI",
    "VU",
    "WF",
    "YT",
    "AF",
    "AO",
    "BF",
    "BI",
    "BJ",
    "BW",
    "BZ",
    "CD",
    "CF",
    "CG",
    "CI",
    "CM",
    "CV",
    "DJ",
    "DM",
    "ER",
    "ET",
    "GA",
    "GM",
    "GN",
    "GQ",
    "GW",
    "KM",
    "LR",
    "LS",
    "MG",
    "ML",
    "MR",
    "MU",
    "MW",
    "MZ",
    "NA",
    "NE",
    "RW",
    "SC",
    "SD",
    "SL",
    "SN",
    "SO",
    "SS",
    "ST",
    "SZ",
    "TD",
    "TG",
    "TL",
    "TN",
    "TZ",
    "UG",
    "ZM",
    "ZW",
  ],
  countryCodes: [
    "93", // Afghanistan
    "355", // Albania
    "213", // Algeria
    "1", // American Samoa
    "376", // Andorra
    "244", // Angola
    "1", // Anguilla
    "1", // Antigua and Barbuda
    "54", // Argentina
    "374", // Armenia
    "297", // Aruba
    "61", // Australia
    "43", // Austria
    "994", // Azerbaijan
    "1", // Bahamas
    "973", // Bahrain
    "880", // Bangladesh
    "1", // Barbados
    "375", // Belarus
    "32", // Belgium
    "501", // Belize
    "229", // Benin
    "1", // Bermuda
    "975", // Bhutan
    "591", // Bolivia
    "387", // Bosnia and Herzegovina
    "267", // Botswana
    "55", // Brazil
    "1", // British Indian Ocean Territory
    "1", // British Virgin Islands
    "673", // Brunei
    "359", // Bulgaria
    "226", // Burkina Faso
    "257", // Burundi
    "855", // Cambodia
    "237", // Cameroon
    "1", // Canada
    "238", // Cape Verde
    "1", // Cayman Islands
    "236", // Central African Republic
    "235", // Chad
    "56", // Chile
    "86", // China
    "61", // Christmas Island
    "61", // Cocos Islands
    "57", // Colombia
    "269", // Comoros
    "682", // Cook Islands
    "506", // Costa Rica
    "385", // Croatia
    "53", // Cuba
    "599", // Curacao
    "357", // Cyprus
    "420", // Czech Republic
    "243", // Democratic Republic of the Congo
    "45", // Denmark
    "253", // Djibouti
    "1", // Dominica
    "1", // Dominican Republic
    "670", // East Timor
    "593", // Ecuador
    "20", // Egypt
    "503", // El Salvador
    "240", // Equatorial Guinea
    "291", // Eritrea
    "372", // Estonia
    "251", // Ethiopia
    "500", // Falkland Islands
    "298", // Faroe Islands
    "679", // Fiji
    "358", // Finland
    "33", // France
    "689", // French Polynesia
    "241", // Gabon
    "220", // Gambia
    "995", // Georgia
    "49", // Germany
    "233", // Ghana
    "350", // Gibraltar
    "30", // Greece
    "299", // Greenland
    "1", // Grenada
    "1", // Guam
    "502", // Guatemala
    "44", // Guernsey
    "224", // Guinea
    "245", // Guinea-Bissau
    "592", // Guyana
    "509", // Haiti
    "504", // Honduras
    "852", // Hong Kong
    "36", // Hungary
    "354", // Iceland
    "91", // India
    "62", // Indonesia
    "98", // Iran
    "964", // Iraq
    "353", // Ireland
    "44", // Isle of Man
    "972", // Israel
    "39", // Italy
    "225", // Ivory Coast
    "1", // Jamaica
    "81", // Japan
    "44", // Jersey
    "962", // Jordan
    "7", // Kazakhstan
    "254", // Kenya
    "686", // Kiribati
    "383", // Kosovo
    "965", // Kuwait
    "996", // Kyrgyzstan
    "856", // Laos
    "371", // Latvia
    "961", // Lebanon
    "266", // Lesotho
    "231", // Liberia
    "218", // Libya
    "423", // Liechtenstein
    "370", // Lithuania
    "352", // Luxembourg
    "853", // Macau
    "389", // Macedonia
    "261", // Madagascar
    "265", // Malawi
    "60", // Malaysia
    "960", // Maldives
    "223", // Mali
    "356", // Malta
    "692", // Marshall Islands
    "222", // Mauritania
    "230", // Mauritius
    "262", // Mayotte
    "52", // Mexico
    "691", // Micronesia
    "373", // Moldova
    "377", // Monaco
    "976", // Mongolia
    "382", // Montenegro
    "1", // Montserrat
    "212", // Morocco
    "258", // Mozambique
    "95", // Myanmar
    "264", // Namibia
    "674", // Nauru
    "977", // Nepal
    "31", // Netherlands
    "599", // Netherlands Antilles
    "687", // New Caledonia
    "64", // New Zealand
    "505", // Nicaragua
    "227", // Niger
    "234", // Nigeria
    "683", // Niue
    "672", // Norfolk Island
    "850", // North Korea
    "1", // Northern Mariana Islands
    "47", // Norway
    "968", // Oman
    "92", // Pakistan
    "680", // Palau
    "970", // Palestine
    "507", // Panama
    "675", // Papua New Guinea
    "595", // Paraguay
    "51", // Peru
    "63", // Philippines
    "64", // Pitcairn
    "48", // Poland
    "351", // Portugal
    "1", // Puerto Rico
    "974", // Qatar
    "242", // Republic of the Congo
    "262", // Reunion
    "40", // Romania
    "7", // Russia
    "250", // Rwanda
    "590", // Saint Barthelemy
    "290", // Saint Helena
    "1", // Saint Kitts and Nevis
    "1", // Saint Lucia
    "590", // Saint Martin
    "508", // Saint Pierre and Miquelon
    "1", // Saint Vincent and the Grenadines
    "685", // Samoa
    "378", // San Marino
    "239", // Sao Tome and Principe
    "966", // Saudi Arabia
    "221", // Senegal
    "381", // Serbia
    "248", // Seychelles
    "232", // Sierra Leone
    "65", // Singapore
    "1", // Sint Maarten
    "421", // Slovakia
    "386", // Slovenia
    "677", // Solomon Islands
    "252", // Somalia
    "27", // South Africa
    "82", // South Korea
    "211", // South Sudan
    "34", // Spain
    "94", // Sri Lanka
    "249", // Sudan
    "597", // Suriname
    "47", // Svalbard and Jan Mayen
    "268", // Swaziland
    "46", // Sweden
    "41", // Switzerland
    "963", // Syria
    "886", // Taiwan
    "992", // Tajikistan
    "255", // Tanzania
    "66", // Thailand
    "228", // Togo
    "690", // Tokelau
    "676", // Tonga
    "1", // Trinidad and Tobago
    "216", // Tunisia
    "90", // Turkey
    "993", // Turkmenistan
    "1", // Turks and Caicos Islands
    "688", // Tuvalu
    "1", // U.S. Virgin Islands
    "256", // Uganda
    "380", // Ukraine
    "971", // United Arab Emirates
    "44", // United Kingdom
    "1", // United States
    "598", // Uruguay
    "998", // Uzbekistan
    "678", // Vanuatu
    "39", // Vatican
    "58", // Venezuela
    "84", // Vietnam
    "681", // Wallis and Futuna
    "212", // Western Sahara
    "967", // Yemen
    "260", // Zambia
    "263", // Zimbabwe
  ],
};
export default Global;
