import React, { useState, useEffect } from "react";
import { View, StyleSheet, Modal, TouchableOpacity } from "react-native";
import { TextInput, Text, Button, IconButton } from "react-native-paper";
import Global from "../Global";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import mainStore from "../stores/MainStore";
import { useNavigation } from "@react-navigation/native";

const SuggestionsScreen = () => {
  const navigation = useNavigation();
  const [text, setText] = useState();
  const [wordCount, setWordCount] = useState(0);
  const [warning, setWarning] = useState("");
  const [modal2Visible, setModal2Visible] = useState(false);

  useEffect(() => {
    const words = text?.trim().split(/\s+/);
    const count = text === "" ? 0 : words?.length;
    setWordCount(count);

    if (count < 3 || count > 200) {
      setWarning("يجب أن تكون رسالتكم بين 3 و 200 كلمة");
    } else {
      setWarning("");
    }
  }, [text]);

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        // paddingHorizontal: 16,
        //  backgroundColor: Global.backgroundColor,
      }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.container}>
        <Text style={{ marginBottom: 10 }}>
          كيف يمكن لـ يامرسال أن يكون أفضل من أجلكم؟
        </Text>
        <TextInput
          label="الاقتراح أو الشكوى"
          value={text}
          onChangeText={(text) => setText(text)}
          multiline={true}
          style={styles.input}
          mode="outlined"
          contentStyle={{ paddingBottom: 100 }}
        />
        <Text
          style={[
            styles.wordCount,
            wordCount < 3 || wordCount > 200
              ? styles.warningText
              : styles.normalText,
          ]}
        >
          عدد الكلمات: {wordCount} {warning && ` - ${warning}`}
        </Text>
        <Button
          icon="send"
          mode="contained"
          onPress={async () => {
            await mainStore.feedback(text);
            setModal2Visible(true);
          }}
          disabled={!!warning || !text}
        >
          إرسال
        </Button>
        <Modal visible={modal2Visible} transparent={true} animationType="fade">
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            activeOpacity={1}
            onPress={() => setModal2Visible(false)}
            onRequestClose={() => setModal2Visible(false)}
          >
            <View
              padding={8}
              style={{
                width: "90%",
                backgroundColor: Global.backgroundColor,
                borderRadius: 10,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton icon="information-outline" color={"red"} size={50} />
              <Text
                paddingBottom={10}
                style={{
                  textAlign: "center",
                  fontWeight: Global.fontWeight,
                  lineHeight: 23,
                  marginHorizontal: 20,
                  marginBottom: 20,
                  writingDirection: "rtl",
                }}
              >
                تلقينا رسالتكم بنجاح، شكرًا لاهتمامكم بـ يامرسال.
              </Text>
            </View>

            <Button
              mode="contained"
              style={{
                alignSelf: "center",
                width: "90%",
                height: 50,
                justifyContent: "center",
                margin: 10,
                fontFamily: "Tajawal-Bold",
              }}
              onPress={() => {
                setModal2Visible(false);
                navigation.navigate("HomeScreen");
              }}
            >
              موافق
            </Button>
          </TouchableOpacity>
        </Modal>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: Global.backgroundColor,
  },
  input: {
    minHeight: 150, // Ensures a large area for text input
    textAlignVertical: "top", // Aligns text to the top in multiline mode
    marginBottom: 20,
    textAlign: "auto",
  },
  inputWarning: {
    borderColor: "red",
  },
  wordCount: {
    marginTop: 8,
    marginBottom: 16,
    lineHeight: 23,
    display: "none",
  },
  normalText: {
    color: Global.textcolor,
  },
  warningText: {
    color: "red",
  },
});

export default SuggestionsScreen;
