/* eslint-disable react/no-unstable-nested-components */

import React, { useState, useMemo, useEffect } from "react";
import {
  SafeAreaView,
  View,
  StyleSheet,
  FlatList,
  Platform,
  TouchableOpacity,
  Text as Txt,
  Dimensions,
  Image,
} from "react-native";
import {
  List,
  Avatar,
  Text,
  Button,
  Menu,
  IconButton,
} from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Global from "../Global";
const calculateTotal = (index) => {
  const length = mainStore.transactions.length;
  let total = 0;
  mainStore.transactions.slice(index, length + 1).forEach((transaction) => {
    total += +transaction.amount;
  });
  return total / 100; // If the amounts are in cents
};
const Transaction = ({ transaction, index }) => {
  const { created_at, amount, type } = transaction;
  const navigation = useNavigation();
  const userStatus = transaction.deposit?.by_user_status;
  const Total = calculateTotal(index);
  const calcMethod =
    transaction.external_transfer?.external_transfer_method?.calc_method;

  let isSuccess = true;
  let status = null;
  for (const key in transaction) {
    if (transaction[key]?.status) {
      status = transaction[key].status;
      if (status !== "success") {
        isSuccess = false;
        break;
      }
    }
  }
  const handleItemPress = () => {
    // Navigate to the TransactionDetails screen with the selected transaction
    calcMethod === "sawa" || calcMethod === "agent"
      ? navigation.navigate("TransactionDetails2", transaction)
      : navigation.navigate("TransactionDetailsScreen", transaction);
  };

  return (
    <List.Item
      title={() => (
        <Text
          style={[
            {
              fontSize: 16,
              color: Global.textcolor,
              direction: "rtl",
              alignSelf: "flex-start",
            },
            styles.ios,
          ]}
        >
          {transaction.meta?.description
            ? transaction.meta.description
            : transaction?.external_transfer
            ? transaction.external_transfer.receiver_name
            : transaction.deposit?.deposit_method != null
            ? "إيداع عبر " + transaction.deposit.deposit_method.name
            : type === "deposit"
            ? transaction.related_to.Name
            : transaction.top_up !== null
            ? "شحن رصيد/ وحدات"
            : transaction.related_to === null && type === "withdraw"
            ? "سحب عبر USDT"
            : transaction.related_to.Name}
        </Text>
      )}
      descriptionStyle={{ alignSelf: Global.isios ? "flex-start" : "auto" }}
      description={
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignContent: "flex-start",
            alignSelf: "flex-start",
            flexWrap: "wrap-reverse",
            maxWidth: 200,
            direction: "rtl",
          }}
        >
          <Text
            style={[
              {
                fontSize: 13,
                color: Global.graycolor,
                fontWeight: Global.fontWeight,
                marginTop: 5,
                marginHorizontal: 5,
                textAlign: "right",
              },
            ]}
          >
            {moment(created_at)
              .format("DD/MM/YYYY, h:mm a")
              .toLocaleUpperCase()}
          </Text>

          {(!isSuccess ||
            transaction?.external_transfer?.is_picked_up ||
            !transaction.confirmed) && (
            <View
              style={{
                marginTop: 5,
                borderWidth: 0.5,
                borderColor: "#b38c04",
                alignSelf: "flex-start",
                borderRadius: 3,
                padding: 3,
              }}
            >
              <Text
                style={{
                  fontWeight: Global.fontWeight,
                  fontSize: 11,
                  color:
                    userStatus === "canceled" || status === "cancelled"
                      ? "red"
                      : status === "processing" ||
                        transaction?.external_transfer?.is_picked_up
                      ? "green"
                      : "#b38c04",
                  // alignSelf: 'center',
                  // justifyContent: 'center',
                }}
              >
                {userStatus === "canceled" || status === "cancelled"
                  ? "ملغاة"
                  : status === "processing"
                  ? "تتم المعالجة"
                  : transaction?.external_transfer?.is_picked_up
                  ? "مستلمة"
                  : "قيد الانتظار"}
              </Text>
            </View>
          )}
        </View>
      }
      left={() => (
        <Avatar.Icon
          icon={type === "withdraw" ? "arrow-up" : "arrow-down"}
          color={type === "withdraw" ? "red" : "green"}
          style={{ backgroundColor: "transparent", marginRight: -35 }}
          size={30}
        />
      )}
      style={{
        backgroundColor: Global.backgroundColor,
      }}
      onPress={() => handleItemPress()}
      right={() => (
        <View
          style={{
            flexDirection: "column",
            // alignContent: 'center',
            justifyContent: "center",
            marginRight: -20,
            marginLeft: -10,
          }}
        >
          {transaction.external_transfer === null &&
          transaction.top_up === null ? (
            <Text
              style={{
                fontWeight: Global.fontWeight,
                fontSize: 18,
                color: type === "withdraw" ? "red" : "green",
                marginLeft: 10,
                alignSelf: "flex-end",
              }}
            >
              {type === "deposit" ? "+" : "-"}{" "}
              {Global.arabicToNumeric(
                (amount / 100).toFixed(2).toString()
              ).split("-")}
              {" $"}
            </Text>
          ) : transaction.top_up !== null ? (
            <Text
              style={{
                alignSelf: "flex-end",
                fontSize: 18,
                color: "red",
                fontWeight: Global.fontWeight,
                marginLeft: 10,
              }}
            >
              {Global.arabicToNumeric(
                transaction.top_up.top_up_package.mobile_balance
              )}
              {" وحدة"}
            </Text>
          ) : (
            <Text
              style={{
                alignSelf: "flex-end",
                fontSize: 18,
                color: type === "withdraw" ? "red" : "green",
                fontWeight: Global.fontWeight,
                lineHeight: 25,
                marginLeft: 10,
              }}
            >
              {Global.arabicToNumeric(
                transaction.external_transfer?.receiving_amount
              )}{" "}
              {transaction.external_transfer?.external_transfer_method
                ?.exchangeRate?.code_ar === "ج. م"
                ? "جنيه"
                : transaction.external_transfer?.receiving_currency === "usd"
                ? "$"
                : transaction.external_transfer?.receiving_currency === "eur"
                ? "€"
                : transaction.external_transfer?.external_transfer_method
                    ?.exchangeRate?.code_ar}
            </Text>
          )}
          {transaction.deposit?.deposit_method != null && (
            <Text
              style={{
                alignSelf: "flex-end",
                marginTop: Global.isios ? 5 : 0,
                marginLeft: 10,
              }}
            >
              {Global.arabicToNumeric(transaction.deposit.paying_amount) +
                " " +
                transaction.deposit.paying_currency.toLocaleUpperCase()}
            </Text>
          )}
          {transaction.top_up !== null && (
            <Text
              style={{
                alignSelf: "flex-end",
                marginTop: Global.isios ? 5 : 0,
                marginLeft: 10,
              }}
            >
              {"- " +
                Global.arabicToNumeric(
                  transaction.top_up.top_up_package.amount
                ) +
                " $"}
            </Text>
          )}
          {transaction.external_transfer !== null && (
            <Text
              style={{
                alignSelf: Global.isios ? "flex-end" : "flex-end",
                marginTop: Global.isios ? 5 : 0,
                marginLeft: 10,
              }}
            >
              {type === "deposit" ? "+" : "-"}{" "}
              {Global.arabicToNumeric(
                (amount / 100).toFixed(2).toString()
              ).split("-")}
              {" $"}
            </Text>
          )}
        </View>
      )}
    />
  );
};

const TransactionScreen = () => {
  const [filteredtransactions, setFilteredtransactions] = useState(
    mainStore.transactions
  );
  const [sortMenuVisible, setSortMenuVisible] = useState(false);
  const [filterMenuVisible, setfilterMenuVisible] = useState(false);
  const [filter, setFilter] = useState("");

  useMemo(() => {
    let filtered = mainStore.transactions;
    if (filter) {
      filtered = mainStore.transactions.filter(
        filter === true
          ? (transaction) =>
              (transaction.meta?.description &&
                transaction.confirmed === false) ||
              transaction.withdrawal?.status === "pending" ||
              transaction.deposit?.status === "pending" ||
              transaction.top_up?.status === "pending" ||
              transaction.external_transfer?.status === "pending"
          : (transaction) => transaction.type === filter
      );
    }
    setFilteredtransactions(filtered);
  }, [filter, mainStore.transactions]);

  const sortByDate = () => {
    const sorted = [...filteredtransactions].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setFilteredtransactions(sorted);
    setSortMenuVisible(false);
  };

  const sortByType = () => {
    const sorted = [...filteredtransactions].sort((a, b) =>
      a.type.localeCompare(b.type)
    );
    setFilteredtransactions(sorted);
    setSortMenuVisible(false);
  };

  const sortByAmount = () => {
    const sorted = [...filteredtransactions].sort(
      (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
    );

    setFilteredtransactions(sorted);
    setSortMenuVisible(false);
  };
  useEffect(() => {
    mainStore.getTransactions();
  }, [mainStore.transactions.length]);
  if (mainStore.transactionsLoaded === false) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>سجل المعاملات</Text>
        <Menu
          visible={filterMenuVisible}
          onDismiss={() => setfilterMenuVisible(false)}
          anchor={
            <IconButton
              onPress={() => setfilterMenuVisible(true)}
              icon="filter"
              size={30}
              iconColor={Global.iconcolor}
            />
          }
        >
          <Menu.Item
            onPress={() => {
              setFilter("withdraw");
              setfilterMenuVisible(false);
            }}
            title="المرسل فقط"
          />
          <Menu.Item
            onPress={() => {
              setFilter("deposit");
              setfilterMenuVisible(false);
            }}
            title="الوارد فقط"
          />
          <Menu.Item
            onPress={async () => {
              setFilter(true);
              setfilterMenuVisible(false);
            }}
            title="قيد الانتظار/ الملغى"
          />
          <Menu.Item
            onPress={() => {
              setFilter("");
              setfilterMenuVisible(false);
            }}
            title="أظهر الجميع"
          />
        </Menu>
        <Menu
          visible={sortMenuVisible}
          onDismiss={() => setSortMenuVisible(false)}
          anchor={
            <Text
              onPress={() => setSortMenuVisible(true)}
              style={styles.sortMenuButton}
            >
              فرز حسب
            </Text>
          }
        >
          <Menu.Item onPress={sortByDate} title="حسب التاريخ (افتراضي)" />
          <Menu.Item onPress={sortByType} title="حسب النوع" />
          <Menu.Item onPress={sortByAmount} title="حسب المبلغ" />
        </Menu>
      </View>
      <FlatList
        data={filteredtransactions}
        renderItem={({ item, index }) => (
          <Transaction transaction={item} index={index} />
        )}
        keyExtractor={(item) => item.id.toString()}
        showsVerticalScrollIndicator={false}
        style={{ width: "100%", paddingHorizontal: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 0,
    backgroundColor: Global.backgroundColor,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    margin: 16,
    marginBottom: 0,
  },
  title: {
    fontSize: 24,
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    color: Global.textcolor,
  },
  sortMenuButton: {
    fontSize: 18,
    color: Global.primary,
  },
  avatar: {
    backgroundColor: "transparent",
  },
  ios: {
    fontWeight: Global.fontWeight,
    marginBottom: Platform.OS === "ios" ? 6 : 0,
  },
});

export default observer(TransactionScreen);
