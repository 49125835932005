import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { makeAutoObservable, runInAction } from "mobx";
//import {create} from 'mobx-persist';
//import {createContext} from 'react';
import OneSignal from "react-onesignal";
import Global from "../Global";
axios.defaults.timeout = 60000;

class MainStore {
  isTest = false;
  registerSuccessed = false;
  userName = "";
  loggedIn = true;
  phone = "";
  email = "";
  password = "";
  accessToken = "";
  balance = "";
  balanceConverted = "";
  khamsatImageUrl = "";
  banks = "";
  transactions = [];
  latestTransactions = [];
  transactionsLoaded = false;
  FAQs = [];
  externalCountries = [];
  depositMethods = [];
  sendMethods = [];
  bankDetails = [];
  referrals = [];
  slider = [];
  introModals = [];
  packages = [];
  articles = [];
  recipients = [];
  address = "";
  loading = false;
  loadingFee = false;
  transferStatus = "";
  withdrawStatus = "";
  otpSended = false;
  verified = false;
  referralCode = "";
  stackOpened = false;
  errorMessage = "";
  networkError = "";
  receiver = "";
  isUser = "";
  isVerified = "";
  userId = "";
  sypAsk = "";
  sypBid = "";
  egpAsk = "";
  egpBid = "";
  deleted = false;
  contact = "";
  helpLinks = "";
  country = "";
  callingCode = "";
  backgroundColor = undefined;
  domain = this.isTest
    ? "https://test.yamersal.com"
    : "https://api.yamersal.com";
  feeDetails = {
    fee: 0,
    sending_amount: "",
    sending_currency: "",
    receiving_amount: 0,
    receiving_currency: "",
    exchangeRate: "",
  };
  cardAmount = {
    fiat_amount: "",
    crypto_amount: "",
    crypto_currency: "",
  };
  constructor() {
    makeAutoObservable(this);
  }
  load(loading) {
    this.loading = loading;
  }
  clearError() {
    this.networkError = "";
  }
  logout() {
    this.loading = true;
    this.loggedIn = false;
    this.deleted = false;
    this.isVerified = "";
    this.isUser = "";
    this.balance = "";
  }
  async register(
    name,
    email,
    password,
    password_confirmation,
    mobile_number,
    referral_code
  ) {
    this.loading = true;
    this.errorMessage = "";
    try {
      const response = await axios.post(this.domain + "/api/register", {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        mobile_number: mobile_number,
        referral_code: referral_code,
      });
      try {
        await AsyncStorage.removeItem("accesstoken");
        await AsyncStorage.setItem("accesstoken", response.data.access_token);
        this.getAccesstoken();
        this.userName = response.data.user.name;
      } catch (e) {}
      this.loggedIn = true;
      this.registerSuccessed = true;
      console.log(`from sigup: ${response.data.access_token}`);
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async login(mobile_number, password) {
    this.loading = true;
    this.errorMessage = "";
    try {
      const response = await axios.post(this.domain + "/api/login", {
        mobile_number: mobile_number,
        password: password,
      });
      try {
        //await               AsyncStorage.removeItem('accesstoken');
        await AsyncStorage.setItem("accesstoken", response.data.access_token);
        this.getAccesstoken(response.data);
        this.userName = response.data.user.name;
      } catch (e) {}

      console.log(response.data.access_token);

      runInAction(() => {
        this.loggedIn = true;
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
      this.loading = false;
    }
  }
  async getBanks(currency) {
    this.banks = "";
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(
        this.domain + "/api/card-banks/" + currency,

        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        this.banks = response.data;
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async getRecipients(country_id) {
    this.recipients = [];
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/recipients",
        {
          country_id: country_id,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        this.recipients = response.data.success;
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async addRecipient(name, phone, country_id) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/recipient/store",
        {
          name: name,
          phone: phone,
          country_id: country_id,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        // this.getRecipients(country_id);
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async deleteRecipient(recipient_id) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/recipient/destroy",
        {
          recipient_id: recipient_id,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        // this.getRecipients(country_id);
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async getTransactions() {
    this.transactionsLoaded = false;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/transactions", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.transactions = response.data.data;
      this.transactionsLoaded = true;
    } catch (error) {
      console.error(error);
      this.errorMessage = error.message;
      this.transactionsLoaded = true;
    }
  }
  async getArticles() {
    try {
      const response = await axios.get(
        "https://academy.yamersal.com/wp-json/wp/v2/posts?categories=123&per_page=20"
      );
      this.articles = response.data;
    } catch (error) {
      console.error(error);
      this.errorMessage = error.message;
    }
  }
  async getLatestTransactions() {
    this.transactionsLoaded = false;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(
        this.domain + "/api/transactions/latest",
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      this.latestTransactions = response.data.data;
      this.transactionsLoaded = true;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getFaq() {
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/faqs", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.FAQs = response.data;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getExternalCountries() {
    this.externalCountries = [];
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/countries", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.externalCountries = response.data.data;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getPackages() {
    this.packages = [];
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/topups/packages", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.packages = response.data;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async chargeMobileBalance(mobile_number, number_type, top_up_package_id) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/topups/topup",
        {
          mobile_number: mobile_number,
          number_type: number_type,
          top_up_package_id: top_up_package_id,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        console.log(response.status);
        this.getLatestTransactions();
        this.getBalance();
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async getReferrals() {
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/user/referrals", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.referrals = response.data.data;
      response.data.data.length === 0 && this.referrals.push("done");
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getDepositMethods() {
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/deposit/methods", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.depositMethods = response.data.map((item) => item);
      console.log(this.depositMethods);
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async getSendMethods() {
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/send/methods", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
        params: {
          is_ios: Global.isios,
        },
      });
      this.sendMethods = response.data.map((item) => item);
      console.log(this.sendMethods);
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async getAddress(network) {
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/usdt/generate", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
        params: {
          network: network,
        },
      });
      this.address = response.data.usdtWallet;
      console.log(response.data);
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
  }
  async getCountry() {
    this.errorMessage = "";
    try {
      // Fetching country
      const value = await AsyncStorage.getItem("accesstoken");
      const countryResponse = await axios.get(
        this.domain + "/api/visitor-country",
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
          timeout: 5000,
        }
      );
      this.country = countryResponse.data.country;

      // Fetching currency and calling code based on country
      const currencyResponse = await axios.get(
        `https://restcountries.com/v3.1/alpha/${this.country}`,
        {
          timeout: 5000,
        }
      );
      const data = currencyResponse.data;
      Global.currency =
        data[0].currencies && Object.keys(data[0].currencies)[0];
      const rootCode = data[0].idd && data[0].idd.root;
      const suffixes = data[0].idd && data[0].idd.suffixes;
      const callingCode =
        suffixes && suffixes.length === 1 ? rootCode + suffixes[0] : rootCode;
      this.callingCode = callingCode;
    } catch (error) {
      this.callingCode = "+1";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        this.errorMessage = error.response.data.message;
        console.error(error.response.data.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  }
  async getConvertedBalance() {
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(
        this.domain + "/api/wallet/balance-converted",
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      console.log(`this is a ${this.accessToken}`);
      this.balanceConverted = response.data;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getBalance() {
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/wallet/balance", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      console.log(`this is a ${this.accessToken}`);
      this.saveBalance(response.data);
      await this.getConvertedBalance();
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getAccesstoken(responseData) {
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      if (value !== null) {
        console.log(`this is ${value}`);
        //this.userName = responseData.user.name;
        this.accessToken = value;
        // return value;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }
  saveData(responseData) {
    this.userName = responseData.user.name;
    this.accessToken = responseData.accessToken;
    //this.accessToken = responseData.accessToken;
  }
  saveBalance(responseData) {
    this.balance = responseData.balance;
  }
  async feedback(comment) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/feedback",
        {
          comment: comment,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        console.error(response);
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async internalTransfer(receiver_mobile_number, amount) {
    this.loading = true;
    this.transferStatus = "";
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/transfer",
        {
          receiver_mobile_number: receiver_mobile_number,
          amount: amount,
        },

        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        this.transferStatus = response.status;
        console.log(response.status);
        this.getLatestTransactions();
        this.getBalance();
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async externalTransfer(
    receiver_name,
    receiver_phone,
    amount,
    country,
    target_currency,
    external_transfer_method_id,
    choice
  ) {
    this.loading = true;
    this.transferStatus = "";
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/send/external-transfer",
        {
          receiver_name: receiver_name,
          receiver_phone: receiver_phone,
          amount: amount,
          country: country,
          target_currency: target_currency,
          external_transfer_method_id: external_transfer_method_id,
          choice: choice,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        this.transferStatus = response.status;
        console.log(response.status);
        this.getLatestTransactions();
        this.getBalance();
      });
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
    this.loading = false;
  }
  async modify(external_transfer_id, request_type, new_value) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/external-transfer/modify",
        {
          external_transfer_id: external_transfer_id,
          request_type: request_type,
          new_value: new_value,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        // console.log(response.status);
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async withdraw(amount, method, address, network) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/withdraw",
        {
          amount: amount,
          method: method,
          address: address,
          network: network,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(() => {
        this.withdrawStatus = response.status;
        console.log(response.status);
        this.getLatestTransactions();
        this.getBalance();
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }

  async Deposit(amount, method, reference, base_currency) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/deposit",
        {
          amount: amount,
          method: method,
          reference: reference,
          base_currency: base_currency,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(async () => {
        this.getBalance();
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async vodafoneDeposit(amount) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/deposit/vodafone",
        {
          amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      runInAction(async () => {
        this.getBalance();
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async sendOtp() {
    console.error("sended otp");
    this.loading = true;
    this.errorMessage = "";
    console.log(`from otp:${this.accessToken}`);
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/send-otp",
        {},
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );
      runInAction(() => {
        this.otpSended = true;
        console.log(response.status);
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async verifyOtp(confirmation_code) {
    this.errorMessage = "";
    this.loading = true;
    this.verified = false;
    try {
      const response = await axios.post(
        this.domain + "/api/verify-otp",
        {
          confirmation_code: confirmation_code,
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );
      runInAction(() => {
        this.verified = true;
        console.log(response.status);
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async uploadReceipt(imageObject) {
    try {
      this.errorMessage = "";
      this.khamsatImageUrl = "";
      const formData = new FormData();

      // Extracting the first asset from the image object
      const imageFile = imageObject.assets[0];

      // Check if the URI is a Base64 encoded string
      if (imageFile.uri.startsWith("data:image")) {
        // Convert Base64 string to Blob
        const base64ToBlob = (base64, type) => {
          const binaryString = window.atob(base64.split(",")[1]);
          const size = binaryString.length;
          const bytes = new Uint8Array(size);

          for (let i = 0; i < size; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          return new Blob([bytes], { type });
        };
        const mimeType = imageFile.uri.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
        )[1];
        const blob = base64ToBlob(imageFile.uri, mimeType);

        // Append Blob to FormData
        // formData.append('image', {
        //   name: imageFile.fileName,
        //   type: mimeType,
        //   uri:blob
        // });
        formData.append("image", blob, "upload." + mimeType.split("/")[1]);
      } else {
        // Handle other types of URIs if necessary
      }

      // Axios request configuration
      const config = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          //  "Content-Type": "multipart/form-data",
        },
      };

      this.loading = true;

      // Making the request using Axios
      const response = await axios.post(
        this.domain + "/api/deposit/upload-receipt",
        formData,
        config
      );
      console.log(formData);
      this.khamsatImageUrl = this.domain + "/" + response.data.path;
      console.log(response.data.path);
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    } finally {
      this.loading = false;
    }
  }

  // Helper function to convert Base64 string to Blob

  async updateMobile(mobile_number) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const response = await axios.post(
        this.domain + "/api/user/update-mobile-number",
        {
          mobile_number: mobile_number,
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async getUserdata() {
    this.errorMessage = "";
    try {
      this.loading = true;
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/user", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.phone = response.data.mobile_number;
      this.email = response.data.email;
      this.referralCode = response.data.referral_code;
      this.userName = response.data.name;
      this.isVerified = response.data.is_verified;
      this.userId = response.data.id;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async onesignalRegister() {
    this.errorMessage = "";
    try {
      const player_id = await OneSignal.User.PushSubscription.id;
      console.error("player id=" + player_id);
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/user/register-device",
        {
          player_id: player_id,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      console.log("this is from onesignal:" + response.data);
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  stackState(state) {
    state === "opened" ? (this.stackOpened = true) : (this.stackOpened = false);
  }
  async checkMobile(mobile_number) {
    this.isUser = "";
    this.receiver = "";
    this.loading = true;
    this.errorMessage = "";
    try {
      const response = await axios.post(
        this.domain + "/api/user/check-mobile-number",
        {
          mobile_number: mobile_number,
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );
      runInAction(() => {
        this.receiver = response.data.user;
        this.isUser = response.data.status;
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async sendWhatsapp(mobile_number) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const response = await axios.post(
        this.domain + "/api/password/send-code-whatsapp",
        {
          mobile_number: mobile_number,
        }
      );
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async confirmCode(mobile_number, code) {
    this.errorMessage = "";
    try {
      const response = await axios.post(
        this.domain + "/api/password/confirm-code",
        {
          mobile_number: mobile_number,
          code: code,
        }
      );
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async resetPassword(mobile_number, password, password_confirmation, code) {
    this.errorMessage = "";
    try {
      const response = await axios.post(this.domain + "/api/password/reset", {
        mobile_number: mobile_number,
        password: password,
        password_confirmation: password_confirmation,
        code: code,
      });
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  /*  async getExchange(currency) {
    this.loading = true;
    this.errorMessage = "";
    try {
      const response = await axios.get(
        this.domain + `/api/exchange-rate/usd/${currency}`
      );
      currency === "egp"
        ? ((this.egpAsk = response.data.ask), (this.egpBid = response.data.bid))
        : ((this.sypAsk = response.data.ask),
          (this.sypBid = response.data.bid));
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
    this.loading = false;
  } */
  async getExchangeSlider() {
    this.loading = true;
    this.errorMessage = "";
    const value = await AsyncStorage.getItem("accesstoken");

    try {
      const response = await axios.get(
        this.domain + `/api/exchange-rates/slider`,
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
          params: {
            is_ios: Global.isios,
          },
        }
      );
      this.slider = response.data;
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
    this.loading = false;
    //console.error(this.slider);
  }
  async getIntroModals() {
    this.loading = true;
    this.errorMessage = "";
    const value = await AsyncStorage.getItem("accesstoken");

    try {
      const response = await axios.get(this.domain + `/api/intro-modals`, {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.introModals = response.data;
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
    this.loading = false;
    //console.error(this.slider);
  }
  async getBankDetails(currency) {
    this.loading = true;
    this.errorMessage = "";
    this.bankDetails = "";
    try {
      this.loading = true;
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(
        this.domain + `/api/bank-details/${currency}`,
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );

      this.bankDetails = response.data.details;
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
    this.loading = false;
  }
  async deleteAccount() {
    this.loading = true;
    this.errorMessage = "";
    this.deleted = false;
    try {
      this.loading = true;
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.delete(
        this.domain + `/api/user/close-account`,
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );
      this.deleted = true;
    } catch (error) {
      this.errorMessage = error.response.data.message;
      console.error(error.response.data.message);
    }
    this.loading = false;
  }
  async calc(
    sending_amount,
    base_currency,
    target_currency,
    process,
    method,
    is_send
  ) {
    this.loadingFee = true;
    this.errorMessage = "";
    this.feeDetails.receiving_amount = "";
    this.feeDetails.sending_amount = "";
    this.feeDetails.sending_currency = "";
    if (is_send) {
      try {
        const response = await axios.post(
          this.domain + "/api/calc",
          {
            sending_amount: sending_amount,
            base_currency: base_currency,
            target_currency: target_currency,
            process: process,
            method: method,
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );
        runInAction(() => {
          this.feeDetails = response.data;
        });
      } catch (error) {
        console.error(error.response.data.message);
        this.errorMessage = error.response.data.message;
      }
    } else {
      try {
        const response = await axios.post(
          this.domain + "/api/calc",
          {
            receiving_amount: sending_amount,
            base_currency: base_currency,
            target_currency: target_currency,
            process: process,
            method: method,
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );
        runInAction(() => {
          this.feeDetails = response.data;
        });
      } catch (error) {
        console.error(error.response.data.message);
        this.errorMessage = error.response.data.message;
      }
    }
    this.loadingFee = false;
    console.error(this.feeDetails);
  }
  async calcCard(amount, fiat_currency, is_send) {
    this.cardAmount.fiat_amount = "";
    this.cardAmount.crypto_amount = "";
    this.cardAmount.crypto_currency = "";
    this.loadingFee = true;
    this.errorMessage = "";
    if (is_send) {
      try {
        const response = await axios.post(
          "https://api.neocrypto.net/api/purchase/request/?partner=onramper",
          {
            fiat_currency: fiat_currency,
            crypto_currency: "USDT_TRC20",
            fiat_amount: amount,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        runInAction(() => {
          this.cardAmount = response.data;
          //   console.error(this.feeDetails);
        });
      } catch (error) {
        console.error(error.response.data.message);
        this.errorMessage = error.response.data.message;
        //this.feeDetails.fiat_amount = '';
        //  this.feeDetails.crypto_amount = 0;
      }
    } else {
      try {
        const response = await axios.post(
          "https://api.neocrypto.net/api/purchase/request/?partner=onramper",
          {
            fiat_currency: fiat_currency,
            crypto_currency: "USDT_TRC20",
            crypto_amount: amount,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        runInAction(() => {
          this.cardAmount = response.data;
          // console.error(this.feeDetails);
        });
      } catch (error) {
        console.error(error.response.data.message);
        this.errorMessage = error.response.data.message;
        // this.feeDetails.fiat_amount = 0;
        // this.feeDetails.crypto_amount = '';
      }
    }
    this.loadingFee = false;
    console.error(this.cardAmount);
  }
  async getContact() {
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/contact", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.contact = response.data;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async getHelpLinks() {
    this.errorMessage = "";
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.get(this.domain + "/api/help-links", {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      });
      this.helpLinks = response.data;
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
  }
  async cancelDeposit(id) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const value = await AsyncStorage.getItem("accesstoken");
      const response = await axios.post(
        this.domain + "/api/deposit/cancel",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );
      this.getLatestTransactions();
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
  async confirmDeposit(id) {
    this.errorMessage = "";
    this.loading = true;
    try {
      const response = await axios.post(
        this.domain + "/api/deposit/confirm",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );
      this.getLatestTransactions();
    } catch (error) {
      !error.response
        ? console.error(error.message)
        : console.error(error.response.data.message);
      !error.response
        ? (this.networkError = error.message)
        : (this.errorMessage = error.response.data.message);
    }
    this.loading = false;
  }
}

const mainStore = new MainStore();

export default mainStore;
