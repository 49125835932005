import React, { useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Platform,
  Image,
  TouchableHighlight,
  ScrollView,
  Text,
  Dimensions,
  Linking,
} from "react-native";
import {
  Button,
  Headline,
  IconButton,
  Snackbar,
  Text as Ptext,
} from "react-native-paper";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useIsFocused } from "@react-navigation/native";
import Global from "../Global";
import { useNavigationState } from "@react-navigation/native";
//import Share from "react-native-share";
//import ViewShot from "react-native-view-shot";
import Clipboard from "@react-native-clipboard/clipboard";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from "react-native-reanimated";

const TransactionDetailsScreen = ({ route, navigation }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const transaction = route.params;
  const userStatus = transaction.deposit?.by_user_status;
  const receiptImage =
    mainStore.domain +
    "/storage/" +
    transaction.external_transfer?.receipt_image;
  const isFocused = useIsFocused();
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const onDismissSnackBar = () => setSnackbarVisible(false);
  const [isSharing, setIsSharing] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [External, setExternal] = useState(
    transaction.external_transfer?.country
  );
  const [TransferNo, setTransferNo] = useState("");
  const previousRouteName = useNavigationState(
    (state) => state.routes[state.index - 1]?.name
  );
  let isSuccess = true;
  let status = null;

  for (const key in transaction) {
    if (transaction[key]?.status) {
      status = transaction[key].status;
      if (status !== "success") {
        isSuccess = false;
        break;
      }
    }
  }
  useEffect(() => {
    // Text.defaultProps = Text.defaultProps || {};
    // Text.defaultProps.allowFontScaling = false;
  }, []);

  useEffect(() => {
    isFocused ? mainStore.stackState("opened") : mainStore.stackState("closed");
  }, [isFocused, mainStore.stackOpened]);
  useEffect(() => {
    const preventback = navigation.addListener("beforeRemove", (e) => {
      // Prevent the back action

      if (!previousRouteName.includes("MyTabs")) {
        e.preventDefault();
        navigation.navigate("HomeScreen");
      }
    });
    // Clean up the listener on unmount
    return preventback;
  }, [navigation]);
  const rotation = useSharedValue(0);
  const copy = () => {
    Clipboard.setString(
      transaction?.external_transfer?.external_transfer_method?.slug === "sawa"
        ? transaction.external_transfer.receipt_number
        : transaction.id
    );
    setSnackbarVisible(true);
  };
  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ rotateY: `${rotation.value}deg` }],
    };
  });
  const viewShotRef = useRef();
  /* const shareScreenshot = async () => {
    setIsSharing(true);
    if (viewShotRef.current) {
      try {
        const uri = await viewShotRef.current.capture();
        //const message = `وصل استلام الحوالة`;
        const options = {
          title: "Share Screenshot",
          // message,
          url: "file://" + uri,
          type: "image/png",
        };
        await Share.open(options);
      } catch (error) {
        console.error("Failed to capture screenshot:", error);
      } finally {
        setIsSharing(false);
      }
    }
  }; */

  const { created_at, amount, type } = transaction;

  const isTransfer =
    transaction?.deposit === null || transaction?.external_transfer;
  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  const handleDownload = async () => {
    console.log(receiptImage);

    const a = document.createElement("a");
    a.href = await toDataURL(receiptImage);
    a.download = "إشعار التحويل";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleShare = async (imageUrl) => {
    console.log(receiptImage);
    try {
      const response = await fetch(receiptImage);
      const blob = await response.blob();

      // Extracting file extension based on MIME type
      const fileExtension = blob.type.split("/")[1];
      const fileName = `إشعار التحويل.${fileExtension}`;

      const file = new File([blob], fileName, { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: "إشعار التحويل",
          text:
            transaction.external_transfer?.external_transfer_method
              ?.calc_method === "localSy"
              ? "يجب تجنب ذكر أي شيء يتعلق بتحويل خارجي، وإخبار الموظف أنها حوالة لثمن بضاعة أو ماشابه."
              : "",
        });
        console.log("Image file shared successfully");
      } else {
        console.log("File sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing the image file:", error);
    }
  };

  if (showReceipt) {
    return (
      <View style={styles.container}>
        <View style={{ backgroundColor: "#263e5b" }}>
          <Ptext
            style={{
              fontSize: 20,
              writingDirection: "rtl",
              margin: 20,
              marginRight: 60,
              color: "white",
            }}
          >
            إشعار التحويل:
          </Ptext>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ backgroundColor: Global.backgroundColor }}
        >
          <View
            style={{
              alignSelf: "center",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              display:
                transaction.external_transfer?.external_transfer_method
                  ?.calc_method === "localSy"
                  ? "flex"
                  : "none",
            }}
          >
            <IconButton icon="information-outline" size={25} />
            <Text
              style={{
                color: Global.textcolor,
                fontWeight: Global.fontWeight,
                lineHeight: 25,

                fontFamily: "Tajawal",
              }}
            >
              يجب تجنب ذكر أي شيء يتعلق بتحويل خارجي، وإخبار الموظف أنها حوالة
              لمساعدة عائلية أو ثمن بضاعة.
            </Text>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderRadius: 10,
              marginHorizontal: 10,
              marginTop: 10,
              borderColor: Global.graycolor,
              paddingVertical: 10,
              backgroundColor: Global.cardcolor,
              height: "95%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={{
                uri: receiptImage,
              }}
              resizeMode="contain"
              style={{
                alignSelf: "center",
                height: "100%",
                width: "100%",
                maxWidth: "95%",
                aspectRatio: 1,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              marginVertical: 5,
            }}
          >
            {!Global.isWebIos && (
              <View
                style={{
                  alignItems: "center",
                  marginHorizontal: 30,
                }}
              >
                <IconButton
                  style={{
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: Global.placeholder,
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                  iconColor={Global.primary}
                  icon="download"
                  size={50}
                  onPress={handleDownload}
                />
                <Ptext>تحميل</Ptext>
              </View>
            )}
            <View style={{ alignItems: "center", marginHorizontal: 30 }}>
              <IconButton
                style={{
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: Global.placeholder,
                  justifyContent: "center",
                  alignSelf: "center",
                }}
                iconColor={Global.primary}
                icon="share"
                size={50}
                onPress={handleShare}
              />
              <Ptext>مشاركة</Ptext>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
  if (mainStore.loading) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      scrollEnabled={Global.isios ? false : true}
      style={{ backgroundColor: Global.backgroundColor }}
    >
      <View style={styles.container}>
        <View style={styles.amountview}>
          {/*  <Text style={[styles.amount, {display: !External ? 'flex' : 'none'}]}>
          {' '}
          {type === 'deposit' ? '+' : '-'}{' '}
          {(amount / 100).toFixed(2).toString().split('-')} $
        </Text>
        <Text
          style={[
            styles.amount,
            {display: External ? 'flex' : 'none', marginBottom: 5},
          ]}>
          {Number(
            transaction.external_transfer?.receiving_amount,
          ).toLocaleString()}
          {transaction.external_transfer?.country === 'syria'
            ? ' ل.س'
            : ' ج.م'}
        </Text> */}

          <Image
            source={require("../assets/logo.webp")}
            style={{ width: 100, height: 100, alignSelf: "center" }}
          />
          <Text
            style={[styles.logoname, { display: External ? "flex" : "flex" }]}
          >
            يامرسال
          </Text>
        </View>
        <Animated.View style={[animatedStyles]}>
          <View style={{ padding: 16 }}>
            <View
              style={{
                //     paddingHorizontal: 20,
                display:
                  transaction.external_transfer?.external_transfer_method
                    ?.slug === "sawa" ||
                  transaction?.external_transfer?.is_picked_up
                    ? "flex"
                    : "none",
                alignSelf: "center",
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 20,
                borderWidth: 1,
                borderStyle: "dashed",
                // marginHorizontal: 27,
                padding: 10,
                borderColor: Global.textcolor,
                backgroundColor: Global.isDark
                  ? "rgba(100, 100,100, 0.3)"
                  : "rgba(100, 100, 100, 0.1)",
              }}
            >
              <IconButton iconColor="green" icon="check-decagram" size={30} />
              <Text
                style={{
                  color: Global.primary,
                  fontWeight: Global.fontWeight,
                  lineHeight: 25,
                  maxWidth: "95%",
                  fontFamily: "Tajawal",
                }}
              >
                {transaction?.external_transfer?.is_picked_up
                  ? "تم استلام هذه الحوالة من قبل المستفيد بنجاح"
                  : "الحوالة جاهزة للاستلام بناءً على هذا الإشعار من أي مكتب من مكاتب الشركات التالية:\nالفاضل - المتحدة - النضال - الأدهم - شخاشيرو"}
              </Text>
            </View>
            <Text style={styles.header}>
              {transaction?.external_transfer?.country === "syria"
                ? "معلومات التسليم"
                : "تفاصيل المعاملة"}
            </Text>

            <View style={styles.rows}>
              <Text style={styles.graytxt}>التاريخ والوقت:</Text>
              <Text style={styles.txt}>
                {moment(created_at)
                  .format("DD/MM/YYYY, h:mm a")
                  .toLocaleUpperCase()}
              </Text>
            </View>
            <View style={styles.rows}>
              <Text style={styles.graytxt}>حالة المعاملة:</Text>
              <Text style={styles.txt}>
                {isSuccess && transaction.confirmed
                  ? "تمت بنجاح"
                  : userStatus === "canceled" || status === "cancelled"
                  ? "ملغاة"
                  : status === "processing"
                  ? "تتم المعالجة"
                  : transaction?.external_transfer?.is_picked_up
                  ? "مستلمة"
                  : "قيد الانتظار"}
              </Text>
            </View>

            <View style={styles.rows}>
              <Text style={styles.graytxt}>
                {isTransfer && type === "deposit"
                  ? "المرسل: "
                  : isTransfer &&
                    transaction?.withdrawal === null &&
                    transaction?.top_up === null
                  ? "المستلم:"
                  : "نوع المعاملة:"}
              </Text>
              <Text style={styles.txt}>
                {" "}
                {transaction.meta?.description
                  ? transaction.meta.description
                  : transaction.deposit?.deposit_method != null
                  ? "إيداع عبر " + transaction.deposit.deposit_method.name
                  : transaction?.external_transfer != null
                  ? `${transaction.external_transfer.receiver_name}`
                  : transaction.top_up !== null
                  ? "شحن رصيد/ وحدات"
                  : transaction.related_to === null
                  ? "سحب عبر USDT"
                  : transaction.related_to.Name}
              </Text>
            </View>
            {transaction?.external_transfer && External && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>
                  {transaction?.external_transfer?.country === "الإمارات" ||
                  transaction?.external_transfer?.country === "روسيا" ||
                  transaction?.external_transfer?.country === "أوروبا" ||
                  transaction?.external_transfer?.country === "العراق"
                    ? "معلومات الحساب:"
                    : "رقم هاتف المستلم:"}
                </Text>
                <Text style={[styles.txt, { marginBottom: 20 }]}>
                  {transaction.external_transfer.receiver_phone}
                </Text>
              </View>
            )}
            {transaction.top_up !== null && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>رقم هاتف المستفيد:</Text>
                <Text style={styles.txt}>
                  {transaction.top_up.mobile_number}
                </Text>
              </View>
            )}

            <View style={styles.rows}>
              <Text style={styles.graytxt}>
                {External &&
                transaction?.external_transfer.country === "سوريا" &&
                transaction?.external_transfer?.external_transfer_method
                  ?.slug === "sawa"
                  ? "رقم الإشعار:"
                  : "رقم المعاملة:"}
              </Text>
              <View style={{ flexDirection: "row" }}>
                <IconButton
                  icon="content-copy"
                  onPress={copy}
                  size={20}
                  style={{
                    marginTop: -12,
                    alignSelf: "flex-start",
                  }}
                />
                <Text style={styles.txt}>
                  {External &&
                  transaction?.external_transfer.country === "سوريا" &&
                  transaction?.external_transfer?.external_transfer_method
                    ?.slug === "sawa"
                    ? transaction.external_transfer.receipt_number
                    : transaction.id}
                </Text>
              </View>
            </View>
            {transaction?.external_transfer?.external_transfer_method?.slug ===
              "agent" && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>المدينة:</Text>
                <Text style={styles.txt}>
                  {transaction.external_transfer.country}
                </Text>
              </View>
            )}
            {transaction?.external_transfer?.external_transfer_method?.slug && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>طريقة التحويل:</Text>
                <Text style={styles.txt}>
                  {
                    transaction.external_transfer?.external_transfer_method
                      ?.name
                  }
                </Text>
              </View>
            )}
            {transaction?.external_transfer?.choice && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>تفضيلات الاستلام:</Text>
                <Text style={styles.txt}>
                  {transaction.external_transfer?.choice}
                </Text>
              </View>
            )}
            {transaction?.external_transfer && External && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>قيمة الحوالة:</Text>
                <Text style={styles.txt}>
                  {Global.arabicToNumeric(
                    transaction.external_transfer?.receiving_amount
                  )}{" "}
                  {transaction.external_transfer?.external_transfer_method
                    ?.exchangeRate?.code_ar === "ج. م"
                    ? "جنيه"
                    : transaction.external_transfer?.receiving_currency ===
                      "usd"
                    ? "$"
                    : transaction.external_transfer?.receiving_currency ===
                      "eur"
                    ? "€"
                    : transaction.external_transfer?.external_transfer_method
                        ?.exchangeRate?.code_ar}
                </Text>
              </View>
            )}
            {transaction.deposit?.deposit_method.name === "Bank Transfer" && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>شرح الحوالة:</Text>
                <Text style={styles.txt}>{transaction.deposit?.reference}</Text>
              </View>
            )}
            <Snackbar
              visible={snackbarVisible}
              onDismiss={onDismissSnackBar}
              duration={2000}
              style={{
                backgroundColor: "lightgray",
                left: 20,
                bottom: -45,
              }}
            >
              <Text style={{ color: "black" }}>
                تم نسخ الرقم إلى الحافظة بنجاح!
              </Text>
            </Snackbar>
            <View
              style={[
                styles.rows,
                {
                  display:
                    transaction.external_transfer === null &&
                    transaction.withdrawal === null
                      ? "flex"
                      : "none",
                },
              ]}
            >
              <Text style={styles.graytxt}>
                {transaction.type === "deposit"
                  ? "الوارد إلى الحساب:"
                  : transaction.top_up !== null
                  ? "المبلغ المدفوع:"
                  : "المبلغ المرسل:"}
              </Text>
              <Text style={styles.txt}>
                {Global.arabicToNumeric(
                  (Math.abs(transaction.amount) / 100).toString()
                ) + " دولار"}
              </Text>
            </View>
            {transaction.top_up !== null && (
              <View style={styles.rows}>
                <Text style={styles.graytxt}>الرصيد المشحون:</Text>
                <Text style={styles.txt}>
                  {Global.arabicToNumeric(
                    transaction.top_up.top_up_package.mobile_balance
                  ) + " "}
                  وحدة
                </Text>
              </View>
            )}
            {transaction.withdrawal !== null ||
            transaction.deposit !== null ||
            transaction.external_transfer !== null ? (
              <View>
                {/* <View
                    style={[styles.rows, {display: External ? 'none' : 'flex'}]}>
                    <Text style={styles.graytxt}>أجور المعاملة:</Text>
                    <Text style={styles.txt}>
                      {transaction.deposit?.fee}
                      {transaction.external_transfer?.fee}
                      {transaction.withdrawal?.fee}
                      {transaction?.deposit &&
                        ' ' +
                          transaction.deposit.paying_currency.toLocaleUpperCase()}
                      {transaction?.external_transfer && ' دولار'}
                      {transaction?.withdrawal && ' دولار'}
                    </Text>
                  </View> */}

                <View
                  style={[styles.rows, { display: External ? "none" : "flex" }]}
                >
                  <Text style={styles.graytxt}>المبلغ المدفوع:</Text>
                  <Text style={styles.txt}>
                    {Global.arabicToNumeric(transaction.deposit?.paying_amount)}
                    {Global.arabicToNumeric(
                      transaction.external_transfer?.paying_amount
                    )}
                    {Global.arabicToNumeric(
                      transaction.withdrawal?.paying_amount
                    )}
                    {transaction?.deposit &&
                      " " +
                        transaction.deposit.paying_currency.toLocaleUpperCase()}
                    {transaction?.external_transfer && " دولار"}
                    {transaction?.withdrawal && " دولار"}
                  </Text>
                </View>
                {transaction.withdrawal?.method === "USDT" && (
                  <View style={[styles.rows, {}]}>
                    <Text style={styles.graytxt}>المبلغ المسحوب:</Text>
                    <Text style={styles.txt}>
                      {" "}
                      {Global.arabicToNumeric(
                        transaction.withdrawal?.receiving_amount
                      )}
                      {transaction?.withdrawal && " دولار"}
                    </Text>
                  </View>
                )}

                {transaction.withdrawal?.method === "USDT" && (
                  <View style={[styles.rows, {}]}>
                    <Text style={styles.graytxt}>الشبكة:</Text>
                    <Text style={styles.txt}>
                      {transaction?.withdrawal?.address
                        ?.toLowerCase()
                        .startsWith("t")
                        ? "Tron (TRC20)"
                        : transaction?.withdrawal?.address
                            .toLowerCase()
                            .startsWith("0x")
                        ? "BSC (BEP20)"
                        : transaction?.withdrawal?.address
                            .toLowerCase()
                            .startsWith("e") ||
                          transaction?.withdrawal?.address
                            .toLowerCase()
                            .startsWith("u")
                        ? "Ton (TON)"
                        : "غير معروف"}
                    </Text>
                  </View>
                )}
                {transaction.withdrawal?.method === "USDT" && (
                  <View style={[styles.rows, {}]}>
                    <Text style={styles.graytxt}>عنوان المحفظة:</Text>
                    <Text style={[styles.txt, { marginBottom: 20 }]}>
                      {transaction?.withdrawal?.address}
                    </Text>
                  </View>
                )}
                <View
                  style={{
                    alignSelf: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    display:
                      transaction.external_transfer?.status === "processing"
                        ? "flex"
                        : "none",
                  }}
                >
                  <IconButton icon="information-outline" size={25} />
                  <Text
                    style={{
                      color: Global.textcolor,
                      fontWeight: Global.fontWeight,
                      lineHeight: 25,

                      fontFamily: "Tajawal",
                    }}
                  >
                    جاري تجهيز إشعار التحويل، يمكنكم تحميله مباشرةً من هنا فور
                    أن يتم إصداره!
                  </Text>
                </View>
                {transaction?.external_transfer && (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      display: isSharing ? "none" : "flex",
                      // marginBottom: 20,
                      marginTop: -10,
                    }}
                  >
                    <View
                      style={{
                        display:
                          ["local", "whish", "omt", "walletJOD"].some((slug) =>
                            transaction?.external_transfer?.external_transfer_method?.slug.includes(
                              slug
                            )
                          ) &&
                          transaction?.external_transfer?.receipt_image &&
                          transaction.external_transfer?.status === "success"
                            ? "flex"
                            : "none",
                      }}
                    >
                      <IconButton
                        onPress={setShowReceipt}
                        icon="receipt"
                        size={55}
                        iconColor={Global.primary}
                        style={{
                          borderWidth: 1,
                          borderRadius: 10,
                          borderColor: Global.placeholder,
                          justifyContent: "center",
                          alignSelf: "center",
                          marginBottom: 10,
                          marginTop: 20,
                        }}
                      />
                      <Ptext alignSelf={"center"}>إشعار التحويل</Ptext>
                    </View>
                    <View style={{ display: "none" }}>
                      <IconButton
                        onPress={() => {
                          rotation.value = withTiming(
                            rotation.value === 0 ? 360 : 0
                          );

                          setExternal(!External);
                        }}
                        icon="compare-horizontal"
                        size={55}
                        iconColor={Global.primary}
                        style={{
                          borderWidth: 1,
                          borderRadius: 10,
                          borderColor: Global.placeholder,
                          justifyContent: "center",
                          alignSelf: "center",
                          marginBottom: 10,
                          marginTop: 20,
                        }}
                      />
                      <Text alignSelf={"center"}>
                        {External ? "تفاصيل المعاملة" : "معلومات التسليم"}
                      </Text>
                    </View>
                    {/* View>
                        <IconButton
                          onPress={shareScreenshot}
                          icon="share"
                          size={55}
                          iconColor={Global.iconcolor}
                          style={{
                            borderWidth: 1,
                            borderRadius: 10,
                            borderColor: Global.placeholder,
                            justifyContent: "center",
                            alignSelf: "center",
                            marginBottom: 10,
                            marginTop: 20,
                          }}
                        />
                        <Text alignSelf={"center"}>مشاركة</Text>
                      </View> */}
                  </View>
                )}
              </View>
            ) : null}
          </View>
        </Animated.View>
        <View
          style={{
            marginBottom: 20,
            marginTop: -20,
            display:
              transaction?.deposit &&
              transaction.deposit?.status !== "success" &&
              transaction.deposit?.status !== "cancelled" &&
              userStatus != "canceled"
                ? "flex"
                : "none",
          }}
        >
          <View
            style={{
              alignSelf: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton icon="information-outline" size={25} />
            <Text
              style={{
                color: Global.textcolor,
                fontWeight: Global.fontWeight,
                lineHeight: 25,

                fontFamily: "Tajawal",
              }}
            >
              العملية قيد الانتظار، سيتم إيداع المبلغ في محفظتكم فور تأكيد وصوله
              لحسابنا بنجاح.
            </Text>
          </View>
          {/* أخفيت زر معلومات التحويل وبدلته بزر تواصل عبر الواتس  */}

          <View
            style={{
              display: transaction?.deposit?.shouldSeeBankDetails
                ? "none"
                : "none",
              display:
                transaction?.deposit?.deposit_method?.slug === "vodafonecash"
                  ? "flex"
                  : "none",
            }}
          >
            <IconButton
              onPress={async () => {
                await mainStore.getBankDetails(
                  /\d/.test(transaction?.deposit?.reference)
                    ? transaction?.deposit?.paying_currency
                    : transaction?.deposit?.reference
                );
                transaction?.deposit.paying_currency === "egp"
                  ? navigation.navigate("VodafoneAddressScreen", transaction)
                  : navigation.navigate("BankDetailsScreen", [
                      transaction.deposit?.reference,
                      transaction,
                      ,
                    ]);
              }}
              icon="bank"
              size={35}
              iconColor={Global.primary}
              style={{
                borderWidth: 1,
                borderRadius: 10,
                borderColor: Global.placeholder,
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 10,
              }}
            />
            <Text
              style={{
                fontWeight: Global.fontWeight,
                fontSize: 12,
                marginBottom: 20,
                fontFamily: "Tajawal",
                color: Global.textcolor,
                alignSelf: "center",
              }}
            >
              معلومات التحويل
            </Text>
          </View>
          <View
            style={{
              marginTop: 5,
              display:
                transaction?.deposit?.deposit_method?.slug === "vodafonecash"
                  ? "none"
                  : "flex",
            }}
          >
            <IconButton
              onPress={async () => {
                const s = transaction?.deposit?.deposit_method?.name;
                const slug =
                  s === "Wise & Revolut" ? transaction?.deposit?.reference : s;

                let message = `مرحبًا، أنا ${
                  mainStore.userName
                }\nلقد طلبت إيداع ${
                  Global.arabicToNumeric(
                    Math.abs(transaction.deposit.paying_amount).toString()
                  ) +
                  " " +
                  transaction?.deposit?.paying_currency.toLocaleUpperCase()
                } عبر ${slug}\nرقم المعاملة: ${transaction.id}.`;

                // Encode the message to make it URL-safe
                let encodedMessage = encodeURIComponent(message);

                // Specify the phone number (without +, spaces, or dashes)
                let phoneNumber = mainStore.contact.whatsapp;

                // Construct the WhatsApp URL with the phone number
                let whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

                // Open WhatsApp with the encoded message
                const canOpen = await Linking.canOpenURL(whatsappUrl);
                if (mainStore.contact.whatsapp) {
                  Linking.openURL(whatsappUrl);
                } else {
                  const telegramBotUsername = mainStore.contact.telegram;
                  const telegramUrl = `https://t.me/${telegramBotUsername}?start=${encodedMessage}`;

                  const canOpenTelegram = await Linking.canOpenURL(telegramUrl);
                  if (canOpenTelegram) {
                    Linking.openURL(telegramUrl);
                  } else {
                    console.log("Cannot open WhatsApp or Telegram");
                  }
                }
              }}
              icon="phone"
              size={35}
              iconColor={Global.primary}
              style={{
                borderWidth: 1,
                borderRadius: 10,
                borderColor: Global.placeholder,
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 10,
              }}
            />
            <Text
              style={{
                fontWeight: Global.fontWeight,
                fontSize: 12,
                marginBottom: 20,
                fontFamily: "Tajawal",
                color: Global.textcolor,
                alignSelf: "center",
              }}
            >
              تواصل معنا
            </Text>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Global.backgroundColor,
  },
  amount: {
    fontSize: 40,
    alignSelf: "center",
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    color: "white",
    fontFamily: "Tajawal",
  },
  logoname: {
    fontSize: 40,
    alignSelf: "center",
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    color: "white",
    marginTop: 20,
    marginBottom: -20,
    fontFamily: "Tajawal",
  },
  amountview: {
    backgroundColor: "#263e5b",
    padding: 50,
  },
  header: {
    fontSize: 25,
    marginBottom: 20,
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    color: Global.textcolor,
    fontFamily: "Tajawal",
  },
  rows: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  graytxt: {
    color: Global.graycolor,
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    marginBottom: 25,
    fontSize: 16,
    fontFamily: "Tajawal",
  },
  txt: {
    fontSize: 16,
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    color: Global.textcolor,
    fontFamily: "Tajawal",
  },
  ios: {
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
  },
  infoBox: {
    backgroundColor: Global.cardcolor,
    padding: 10,

    borderRadius: 5,
    borderWidth: 1,
    margin: 20,
  },
});

export default observer(TransactionDetailsScreen);
