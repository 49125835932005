import { AppRegistry } from "react-native";
import App from "./App";
import Global from "./Global";
import OneSignal, { useOneSignalSetup } from "react-onesignal";
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.log = function () {};
}
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
async function initializeOneSignal() {
  try {
    await OneSignal.init({
      appId: "0fdb35e6-d130-4a6c-9afa-4669d0c1962c",
      allowLocalhostAsSecureOrigin: true,
    });
    OneSignal.Slidedown.promptPush();
  } catch (error) {
    console.error("OneSignal initialization failed:", error);
  }
}

// Call the function
initializeOneSignal();
document
  .querySelector('meta[name="theme-color"]')
  .setAttribute("content", Global.backgroundColor);
document.body.style.overflow = Global.isWebIos ? "hidden" : "hidden";

AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("root"),
});
