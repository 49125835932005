import React, { useEffect, useState } from "react";
import {
  LayoutAnimation,
  Platform,
  ScrollView,
  UIManager,
  View,
  Image,
} from "react-native";
import { Divider, List, Text } from "react-native-paper";
import Global from "../Global";
import { useIsFocused } from "@react-navigation/core";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const FaqScreen = ({ navigation }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const FAQs = mainStore.FAQs;
  //const [FAQs, setFAQs] = useState(mainStore.FAQs);
  const isFocused = useIsFocused();
  useEffect(() => {
    mainStore.getFaq();
  }, []);
  useEffect(() => {
    isFocused ? mainStore.stackState("opened") : mainStore.stackState("closed");
  }, [isFocused]);
  const [expanded, setExpanded] = useState({});

  const handlePress = (index) => {
    LayoutAnimation.easeInEaseOut();
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  if (mainStore.FAQs.length === 0) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
      style={{ backgroundColor: Global.backgroundColor }}
    >
      <View
        style={{
          padding: 10,
          paddingBottom: 100,
          backgroundColor: Global.backgroundColor,
          flex: 1,
        }}
      >
        {FAQs.map((faq, index) => (
          <List.Section key={index}>
            <List.Accordion
              titleNumberOfLines={faq.question.length > 30 ? 4 : 1}
              title={faq.question}
              expanded={expanded[index]}
              titleStyle={{ lineHeight: 22, direction: "rtl" }}
              onPress={() => handlePress(index)}
            >
              <List.Item
                title={faq.answer}
                titleNumberOfLines={faq.answer.length > 37 ? 15 : 1}
                titleStyle={{ lineHeight: 22, direction: "rtl" }}
              />
            </List.Accordion>
          </List.Section>
        ))}
        <Divider marginTop={20} />
      </View>
    </ScrollView>
  );
};
export default observer(FaqScreen);
