import { View, StyleSheet, ScrollView, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Global from "../Global";
import { useNavigationState } from "@react-navigation/native";
import {
  Button,
  Dialog,
  IconButton,
  Portal,
  Snackbar,
  Text,
  TextInput,
} from "react-native-paper";
import * as moment from "moment";
import Clipboard from "@react-native-clipboard/clipboard";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import mainStore from "../stores/MainStore";

const TransactionDetails2 = ({ route, navigation }) => {
  const previousRouteName = useNavigationState(
    (state) => state.routes[state.index - 1]?.name
  );
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [newName, setNewName] = useState("");
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [endDialogVisible, setEndlDialogVisible] = useState(false);
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  useEffect(() => {
    const preventback = navigation.addListener("beforeRemove", (e) => {
      // Prevent the back action

      if (!previousRouteName.includes("MyTabs")) {
        e.preventDefault();
        navigation.navigate("HomeScreen");
      }
    });
    // Clean up the listener on unmount
    return preventback;
  }, [navigation]);

  const copy = () => {
    Clipboard.setString(
      transaction?.external_transfer?.external_transfer_method?.slug === "sawa"
        ? transaction.external_transfer.receipt_number
        : transaction.id
    );
    setSnackbarVisible(true);
  };

  const transaction = route.params;
  const externalInfo = transaction?.external_transfer;
  const calcMethod =
    transaction.external_transfer?.external_transfer_method?.calc_method;

  let isSuccess = true;
  let status = null;
  for (const key in transaction) {
    if (transaction[key]?.status) {
      status = transaction[key].status;
      if (status !== "success") {
        isSuccess = false;
        break;
      }
    }
  }

  if (mainStore.loading) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <KeyboardAwareScrollView
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      style={styles.container}
    >
      <Portal>
        <Dialog
          style={{ maxHeight: Global.isios ? "100%" : "90%" }}
          visible={editDialogVisible}
          onDismiss={() => setEditDialogVisible(false)}
        >
          <Dialog.Title style={{ writingDirection: "rtl" }}>
            طلب تعديل الاسم
          </Dialog.Title>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Dialog.Content>
              <View>
                <Text
                  style={{
                    writingDirection: "rtl",
                    fontSize: 16,
                    color: Global.graycolor,
                  }}
                >
                  الاسم الحالي:
                  <Text style={{ color: Global.primary }}>
                    {" "}
                    {externalInfo.receiver_name}
                  </Text>
                </Text>
                <Text
                  style={{
                    writingDirection: "rtl",
                    fontSize: 16,
                    marginVertical: 10,
                  }}
                >
                  الاسم الجديد:
                </Text>
                <TextInput
                  mode="outlined"
                  value={newName}
                  onChangeText={setNewName}
                  placeholder="اسم المستلم الجديد"
                  placeholderTextColor={Global.placeholder}
                  style={{ textAlign: "auto" }}
                />
              </View>
            </Dialog.Content>
          </ScrollView>

          <Dialog.Actions style={{ justifyContent: "center" }}>
            <Button
              mode="contained"
              onPress={async () => {
                setEditDialogVisible(false);
                await mainStore.modify(
                  transaction?.meta?.external_transfer_id.toString(),
                  "modify",
                  newName
                );
                setEndlDialogVisible(true);
              }}
              style={{ flex: 1, borderRadius: 5 }}
              labelStyle={{ fontSize: 16 }}
              disabled={!newName}
            >
              موافق
            </Button>
            <Button
              mode="outlined"
              onPress={() => setEditDialogVisible(false)}
              style={{ flex: 1, borderRadius: 5, marginRight: 20 }}
              labelStyle={{ color: "red", fontSize: 16 }}
            >
              إلغاء
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <Portal>
        <Dialog
          style={{ maxHeight: Global.isios ? "100%" : "90%" }}
          visible={cancelDialogVisible}
          onDismiss={() => setCancelDialogVisible(false)}
        >
          <Dialog.Title style={{ writingDirection: "rtl" }}>
            طلب إلغاء
          </Dialog.Title>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Dialog.Content>
              <View>
                <Text
                  style={{
                    writingDirection: "rtl",
                    fontSize: 16,
                    textAlign: "center",
                    lineHeight: 21,
                  }}
                >
                  أنت على وشك إلغاء هذه الحوالة هل تؤكد ذلك؟
                </Text>
              </View>
            </Dialog.Content>
          </ScrollView>

          <Dialog.Actions style={{ justifyContent: "center" }}>
            <Button
              mode="contained"
              onPress={async () => {
                setCancelDialogVisible(false);
                await mainStore.modify(
                  transaction?.meta?.external_transfer_id.toString(),
                  "cancel",
                  undefined
                );
                setEndlDialogVisible(true);
              }}
              style={{ flex: 1, borderRadius: 5 }}
              labelStyle={{ fontSize: 16 }}
            >
              نعم
            </Button>
            <Button
              mode="outlined"
              onPress={() => setCancelDialogVisible(false)}
              style={{ flex: 1, borderRadius: 5, marginRight: 20 }}
              labelStyle={{ color: "red", fontSize: 16 }}
            >
              لا
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <Portal>
        <Dialog
          style={{ maxHeight: Global.isios ? "100%" : "90%" }}
          visible={endDialogVisible}
          onDismiss={() => {
            setCancelDialogVisible(false);
            navigation.navigate("HomeScreen");
          }}
        >
          <Dialog.Title style={{ writingDirection: "rtl" }}>
            نتيجة العملية
          </Dialog.Title>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Dialog.Content>
              <View>
                <IconButton
                  iconColor={mainStore.errorMessage ? "red" : "green"}
                  icon={mainStore.errorMessage ? "close" : "check-decagram"}
                  size={75}
                  style={{ alignSelf: "center" }}
                />
                <Text
                  style={{
                    writingDirection: "rtl",
                    fontSize: 16,
                    textAlign: "center",
                    lineHeight: 21,
                  }}
                >
                  {mainStore.errorMessage ||
                    "تم استلام طلبكم بنجاح، ستتم مراجعة طلبكم خلال فترة قصيرة."}
                </Text>
              </View>
            </Dialog.Content>
          </ScrollView>

          <Dialog.Actions style={{ justifyContent: "center" }}>
            <Button
              mode="contained"
              onPress={async () => {
                setCancelDialogVisible(false);
                navigation.navigate("HomeScreen");
              }}
              style={{ flex: 1, borderRadius: 5 }}
              labelStyle={{ fontSize: 16 }}
            >
              حسنًا
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <Text
        style={{
          writingDirection: "rtl",
          marginTop: 20,
          marginBottom: 10,
          fontSize: 23,
        }}
      >
        {Global.arabicToNumeric(externalInfo.receiving_amount) +
          " " +
          externalInfo.external_transfer_method.exchangeRate.code_ar}
      </Text>
      <Text style={{ writingDirection: "rtl", marginBottom: 10, fontSize: 18 }}>
        {"إلى" + " "}
        <Text style={{ color: Global.primary }}>
          {externalInfo.receiver_name}
        </Text>
      </Text>
      <Text style={{ color: Global.graycolor, marginBottom: 10 }}>
        {moment(transaction.created_at)
          .format("DD/MM/YYYY, h:mm a")
          .toLocaleUpperCase()}
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: Global.isDark
            ? Global.cardcolor
            : Global.backgroundColor,
          padding: 10,
          borderRadius: 10,
        }}
      >
        <Text style={{ fontSize: 16, color: Global.graycolor }}>
          {calcMethod === "sawa" ? "رقم الإشعار" : "رقم المعاملة"}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            icon="content-copy"
            onPress={copy}
            size={17}
            style={{
              // marginTop: -12,
              alignSelf: "flex-start",
            }}
          />
          <Text style={{ fontSize: 18 }}>
            {calcMethod === "sawa"
              ? externalInfo.receipt_number
              : transaction.id}
          </Text>
        </View>
      </View>
      <View
        style={{
          backgroundColor: Global.isDark
            ? Global.cardcolor
            : Global.backgroundColor,
          padding: 10,
          borderRadius: 10,
          marginVertical: 10,
        }}
      >
        <Text
          style={{
            fontSize: 16,
            color: Global.graycolor,
            writingDirection: "rtl",
            marginVertical: 10,
          }}
        >
          حالة الحوالة:
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginVertical: 20,
            display: status === "cancelled" ? "none" : "flex",
          }}
        >
          <View
            style={{
              borderWidth: 4,
              borderRightWidth: 0,
              borderColor: "#66CC66",
              height: "140%",
              borderRadius: 20,
              marginStart: 20,
            }}
          />
          <View style={{ maxWidth: "90%" }}>
            <Text style={{ fontSize: 16, marginBottom: 5 }}>
              {calcMethod === "sawa" ? "تمت معالجة الحوالة" : "تم استلام طلبكم"}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginVertical: 20,
          }}
        >
          <View
            style={{
              borderWidth: 4,
              borderRightWidth: 0,
              borderColor: isSuccess
                ? "#66CC66"
                : status === "cancelled"
                ? "red"
                : Global.graycolor,
              borderStyle:
                isSuccess || status === "cancelled" ? "solid" : "dotted",
              height: "140%",
              borderRadius: 20,
              marginStart: 20,
            }}
          />
          <View style={{ maxWidth: "90%" }}>
            <Text style={{ fontSize: 16, marginBottom: 5 }}>
              {isSuccess
                ? calcMethod === "sawa"
                  ? "جاهزة للاستلام"
                  : "تمت المعالجة"
                : status === "cancelled"
                ? "ملغاة"
                : status === "processing"
                ? "تتم المعالجة"
                : transaction?.external_transfer?.is_picked_up
                ? "مستلمة"
                : "قيد الانتظار"}
            </Text>
            <Text
              style={{
                color: Global.graycolor,
                //    maxWidth: "60%",
                writingDirection: "rtl",
                lineHeight: 23,
                display: isSuccess ? "flex" : "none",
              }}
            >
              {calcMethod === "agent"
                ? "تمت معالجة الحوالة وسيتواصل المندوب مع المستفيد عبر WhatsApp خلال يوم عمل."
                : "الحوالة جاهزة للاستلام بناءً على هذا الإشعار من أي مكتب من مكاتب الشركات التالية:\nالفاضل - المتحدة - النضال - الأدهم - شخاشيرو"}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginVertical: 20,
            display:
              calcMethod === "sawa" && status !== "cancelled" ? "flex" : "none",
          }}
        >
          <View
            style={{
              borderWidth: 4,
              borderRightWidth: 0,
              borderColor: externalInfo.is_picked_up
                ? "#66CC66"
                : Global.graycolor,
              height: "140%",
              borderRadius: 20,
              marginStart: 20,
              borderStyle: externalInfo.is_picked_up ? "solid" : "dotted",
            }}
          />
          <View style={{ maxWidth: "90%" }}>
            <Text style={{ fontSize: 16, marginBottom: 5 }}>الاستلام</Text>
            <Text
              style={{
                color: Global.graycolor,
                //    maxWidth: "60%",
                writingDirection: "rtl",
                lineHeight: 20,
              }}
            >
              {externalInfo.is_picked_up
                ? "لقد تم استلام هذه الحوالة من قبل المستفيد بنجاح"
                : "لم يتم استلام الحوالة بعد."}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: Global.isDark
            ? Global.cardcolor
            : Global.backgroundColor,
          padding: 15,
          borderRadius: 10,
          marginBottom: calcMethod === "sawa" ? 10 : 0,
        }}
      >
        <Text style={{ fontSize: 16, color: Global.graycolor }}>
          رقم هاتف المستلم
        </Text>
        <Text style={{ fontSize: 16 }}>{externalInfo.receiver_phone}</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: Global.isDark
            ? Global.cardcolor
            : Global.backgroundColor,
          padding: 15,
          borderRadius: 10,
          marginTop: 10,
          display: calcMethod === "agent" ? "flex" : "none",
        }}
      >
        <Text style={{ fontSize: 16, color: Global.graycolor }}>المدينة</Text>
        <Text style={{ fontSize: 16 }}>
          {transaction.external_transfer.country}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: Global.isDark
            ? Global.cardcolor
            : Global.backgroundColor,
          padding: 15,
          borderRadius: 10,
          marginVertical: 10,
          display: calcMethod !== "sawa" ? "flex" : "none",
        }}
      >
        <Text style={{ fontSize: 16, color: Global.graycolor }}>
          المبلغ المخصوم
        </Text>
        <Text style={{ fontSize: 16 }}>
          {Global.arabicToNumeric(transaction.amount / 100)} $
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: Global.isDark
            ? Global.cardcolor
            : Global.backgroundColor,
          padding: 15,
          borderRadius: 10,
          marginBottom: 10,
        }}
      >
        <Text style={{ fontSize: 16, color: Global.graycolor }}>
          طريقة التحويل
        </Text>
        <Text style={{ fontSize: 16 }}>
          {transaction.external_transfer?.external_transfer_method?.name}
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: 10,
          display:
            externalInfo.is_picked_up ||
            calcMethod !== "sawa" ||
            status === "cancelled"
              ? "none"
              : "flex",
        }}
      >
        <Button
          labelStyle={{ fontSize: 16 }}
          style={{ borderRadius: 5, flex: 1 }}
          mode="outlined"
          onPress={setEditDialogVisible}
        >
          تعديل الاسم
        </Button>
        <Button
          style={{ borderRadius: 5, flex: 1, marginEnd: 10 }}
          mode="outlined"
          labelStyle={{ color: "red", fontSize: 16 }}
          onPress={setCancelDialogVisible}
        >
          طلب إلغاء
        </Button>
      </View>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={2000}
        style={{
          backgroundColor: "lightgray",
          bottom: -45,
        }}
      >
        <Text style={{ color: "black", writingDirection: "rtl" }}>
          تم نسخ الرقم إلى الحافظة بنجاح!
        </Text>
      </Snackbar>
    </KeyboardAwareScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: !Global.isDark
      ? "rgba(246, 246, 246, 1)"
      : Global.backgroundColor,
    paddingHorizontal: 15,
    paddingBottom: 0,
    marginBottom: 0,
  },
});
export default observer(TransactionDetails2);
