import React, { useContext, useRef, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Share as Sharee,
  ScrollView,
  Image,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import {
  Title,
  Text,
  IconButton,
  Button,
  Snackbar,
  ActivityIndicator,
  MD2Colors,
  Menu,
  Portal,
  Dialog,
} from "react-native-paper";
import QRCode from "react-native-qrcode-svg";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import { useIsFocused } from "@react-navigation/native";
import Global from "../../Global";

const DepositScreen = () => {
  const isFocused = useIsFocused();
  useEffect(() => {
    isFocused ? mainStore.stackState("opened") : mainStore.stackState("closed");
  }, [isFocused]);
  useEffect(() => {
    mainStore.getAddress("trc20");
  }, []);

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [networkName, setNetworkName] = useState("Tron (TRC20)");
  const [dialogVisible, setDialogVisible] = useState(false);
  const memo = (mainStore.userId + 103999) % 1000000000;
  const onDismissSnackBar = () => setSnackbarVisible(false);
  const walletAddress = mainStore.address;
  const minDepositAmount = "0.1 USDT";
  const depositTime = "10-30 دقيقة";
  //const viewShotRef = useRef();
  const copyWalletAddress = () => {
    Clipboard.setString(walletAddress);
    setSnackbarVisible(true);
  };

  const shareInformation = () => {
    const message =
      `Network: ${networkName}\nWallet Address: ${walletAddress}\n` +
      (networkName === "TON" ? `Memo: ${memo}` : "");
    Sharee.share({ message });
  };

  if (mainStore.address == "") {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Portal>
          <Dialog
            style={{ width: "95%", alignSelf: "center" }}
            visible={dialogVisible}
            onDismiss={() => setDialogVisible(false)}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر الشبكة:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              <Dialog.Content>
                <TouchableOpacity
                  style={{ marginTop: 5 }}
                  onPress={async () => {
                    await mainStore.getAddress("trc20");
                    setNetworkName("Tron (TRC20)");
                    setDialogVisible(false);
                  }}
                >
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderColor: Global.graycolor,
                      padding: 10,
                    }}
                  >
                    <Text style={{ fontSize: 16 }}>Tron (TRC20)</Text>
                    <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                      وقت المعالجة المتوقع: 5 دقائق
                    </Text>
                    <Text
                      style={{
                        color: Global.graycolor,
                        fontSize: 12,
                        writingDirection: "rtl",
                      }}
                    >
                      الحد الأدنى: 0.1 USDT
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginTop: 20 }}
                  onPress={async () => {
                    await mainStore.getAddress("bep20");
                    setNetworkName("BSC (BEP20)");
                    setDialogVisible(false);
                  }}
                >
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderColor: Global.graycolor,
                      padding: 10,
                    }}
                  >
                    <Text style={{ fontSize: 16 }}>BSC (BEP20)</Text>
                    <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                      وقت المعالجة المتوقع: 5 دقائق
                    </Text>
                    <Text
                      style={{
                        color: Global.graycolor,
                        fontSize: 12,
                        writingDirection: "rtl",
                      }}
                    >
                      الحد الأدنى: 0.1 USDT
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginTop: 20 }}
                  onPress={async () => {
                    await mainStore.getAddress("ton");
                    setNetworkName("TON");
                    setDialogVisible(false);
                  }}
                >
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderColor: Global.graycolor,
                      padding: 10,
                    }}
                  >
                    <Text style={{ fontSize: 16 }}>TON</Text>
                    <Text style={{ color: Global.graycolor, fontSize: 12 }}>
                      وقت المعالجة المتوقع: 5 دقائق
                    </Text>
                    <Text
                      style={{
                        color: Global.graycolor,
                        fontSize: 12,
                        writingDirection: "rtl",
                      }}
                    >
                      الحد الأدنى: 0.1 USDT
                    </Text>
                  </View>
                </TouchableOpacity>
              </Dialog.Content>
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={() => setDialogVisible(false)}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Title style={styles.title} />
        <View style={styles.viewShotWrapper}>
          <View style={styles.viewShotContainer}>
            <QRCode value={walletAddress} size={200} />
          </View>
          <View style={{ marginVertical: 15 }}>
            <View
              style={{
                justifyContent: "space-between",
                margin: 15,
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{ color: Global.graycolor, writingDirection: "rtl" }}
              >
                اسم الشبكة:
              </Text>
              <View
                style={{
                  borderRadius: 10,
                  //borderWidth: 0.5,
                  borderColor: Global.graycolor,
                  alignSelf: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <TouchableOpacity
                  onPress={setDialogVisible}
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      color: Global.primary,
                      alignSelf: "center",
                    }}
                  >
                    {networkName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 16,
                      marginRight: 7,
                      marginTop: Global.isios ? -7.5 : -5,
                      transform: [{ scaleX: 1.5 }],
                      color: Global.primary,
                    }}
                  >
                    ⌄
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                justifyContent: "space-between",
                margin: 15,
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{ color: Global.graycolor, writingDirection: "rtl" }}
              >
                الحد الأدنى للإيداع:
              </Text>
              <Text>{minDepositAmount}</Text>
            </View>
            <View
              style={{
                justifyContent: "space-between",
                margin: 15,
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{ color: Global.graycolor, writingDirection: "rtl" }}
              >
                وقت وصول الإيداع المتوقع:
              </Text>
              <Text style={{ writingDirection: "rtl" }}>5 دقائق كحد أقصى</Text>
            </View>
          </View>
        </View>
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ marginBottom: 15, writingDirection: "rtl" }}>
            عنوان المحفظة:
          </Text>
          <Button
            contentStyle={{ paddingHorizontal: 50 }}
            icon={"content-copy"}
            style={{ maxWidth: "90%", alignSelf: "center", marginBottom: 10 }}
            mode="contained"
            onPress={copyWalletAddress}
          >
            {walletAddress.slice(0, 6) +
              "..." +
              walletAddress.slice(walletAddress.length - 6)}
          </Button>
          {networkName === "TON" && (
            <>
              <Text style={{ marginVertical: 15, writingDirection: "rtl" }}>
                رقم الـ Memo/Tag:
              </Text>
              <Button
                contentStyle={{ paddingHorizontal: 50 }}
                icon={"content-copy"}
                style={{ maxWidth: "90%", alignSelf: "center" }}
                mode="contained"
                onPress={() => {
                  Clipboard.setString(memo.toString());
                  setSnackbarVisible(true);
                }}
              >
                {memo}
              </Button>
            </>
          )}
        </View>
        {networkName === "TON" && (
          <>
            <View
              style={{
                alignSelf: "center",
                borderRadius: 5,
                marginVertical: 20,
                // direction: "rtl",
                maxWidth: "90%",
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <IconButton icon="information-outline" size={20} />
                <Text
                  style={{
                    fontWeight: Global.fontWeight,
                    lineHeight: 25,
                    color: "red",
                    direction: "rtl",
                  }}
                >
                  تأكد أنك تكتب رقم ال Memo/Tag بشكل دقيق، لتأكيد إيداعك بشكل
                  ناجح.
                </Text>
              </View>
            </View>
          </>
        )}

        <Snackbar
          visible={snackbarVisible}
          onDismiss={onDismissSnackBar}
          duration={2000}
          style={{
            backgroundColor: "lightgray",
            left: 10,
            bottom: 20,
          }}
        >
          <Text style={{ color: "black", writingDirection: "rtl" }}>
            تم النسخ بنجاح!{" "}
          </Text>
        </Snackbar>
        <View style={{ height: 20 }}></View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingHorizontal: Dimensions.get('screen').width * 0.03,
    backgroundColor: Global.backgroundColor,
    // paddingHorizontal: 15,
  },
  title: {
    fontSize: 27,
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 35,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  viewShotContainer: {
    alignItems: "center",
  },

  label: {
    fontWeight: "bold",
    marginTop: 20,
    color: Global.graycolor,
    //fontSize: 16,
  },
  addressRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: -10,
  },
  walletAddress: {},
  infoBox: {
    backgroundColor: Global.cardcolor,
    padding: 10,

    borderRadius: 5,
    borderWidth: 1,
    margin: 20,
  },
  shareButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 20,
    // width: '100%',
    //alignSelf: 'center',
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
    borderRadius: 5,
  },
});

export default observer(DepositScreen);
