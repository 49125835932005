// SendTransferScreen.js
import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Platform,
  Text as Txt,
  TouchableOpacity,
  PermissionsAndroid,
  KeyboardAvoidingView,
  Image,
  Modal,
} from "react-native";
import {
  TextInput,
  Button,
  useTheme,
  Text,
  Snackbar,
  ActivityIndicator,
  MD2Colors,
  IconButton,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import mainStore from "../../stores/MainStore";
import { observer } from "mobx-react-lite";
import Global from "../../Global";
import CountryFlag from "react-native-country-flag";
import ReviewScreen from "./ReviewScreen";
import ResultScreen from "../ResultScreen";

const SendToWalletScreen = ({ navigation, route }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [modal2Visible, setModal2Visible] = useState(false);
  const [amount, setAmount] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [country, setCountry] = useState();
  const [succsess, setSuccsess] = useState(0);
  const [step, setStep] = useState(1);

  const onDismissSnackBar = () => setSnackbarVisible(false);
  useEffect(() => {
    if (route.params !== undefined) {
      console.log(route.params);
      setCountryCode(route.params.countryCode);
      setPhoneNumber(route.params.number);
    }
  }, [route.params]);
  const theme = useTheme();

  const handleSend = async () => {
    await mainStore.checkMobile("+" + countryCode + phoneNumber);

    if (mainStore.receiver !== "") {
      setSuccsess(1);
    } else {
      setModal2Visible(true);
    }
  };
  const formatPhoneNumber = (phone) => {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Split the digits into groups of 3, separated by spaces
    //const formattedNumber = digitsOnly.replace(/(\d{3})(?=\d)/g, '$1 ');
    const formattedNumber =
      digitsOnly.length === 10
        ? digitsOnly.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4")
        : digitsOnly.length === 11
        ? digitsOnly.substring(0, 4) + " " + digitsOnly.substring(4)
        : digitsOnly.replace(/(\d{3})(?=\d)/g, "$1 ");

    return formattedNumber;
  };
  const callingCodeToIso = require("../../callingcode.json");

  const getIsoCode = (callingCode) => {
    return callingCodeToIso[callingCode];
  };
  const handleTextChange = (text) => {
    // Remove any spaces from the text before updating the state
    const normalizedText = text.replace(/\s/g, "");
    setPhoneNumber(normalizedText);
  };
  if (mainStore.loading) {
    return (
      <Image
        source={require("../../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  if (succsess === 1) {
    return (
      <ReviewScreen
        props={{
          previousRouteName: "SendToWalletScreen",
          amount: amount,
          recipientName: mainStore.receiver,
          transferFees: 0,
          phone: "+" + countryCode + phoneNumber,
        }}
        setSuccsess={setSuccsess}
        setStep={setStep}
      />
    );
  }
  if (succsess === 2) {
    return (
      <ResultScreen
        props={{
          previousRouteName: "SendToWalletScreen",
        }}
        navigation={navigation}
      />
    );
  }
  return (
    <View style={styles.container}>
      <Text style={styles.label}>:رقم هاتف المستلم</Text>
      <View style={styles.phoneInputContainer}>
        <TextInput
          value={formatPhoneNumber(phoneNumber)}
          onChangeText={(text) => handleTextChange(text)}
          style={styles.phoneNumberInput}
          keyboardType="phone-pad"
          mode="outlined"
          right={
            <TextInput.Icon
              onPress={() => navigation.navigate("ContactsScreen")}
              icon="contacts"
              iconColor={Global.primary}
              style={{
                borderWidth: 1,
                borderRadius: 10,
                borderColor: Global.placeholder,
                justifyContent: "center",
                alignSelf: "center",
              }}
            />
          }
        />
        <TextInput
          placeholder="00"
          placeholderTextColor={"gray"}
          value={countryCode}
          onChangeText={setCountryCode}
          style={styles.countryCodeInput}
          keyboardType="phone-pad"
          mode="outlined"
          contentStyle={{ marginLeft: 60, marginRight: -10 }}
        />
        <Text
          style={[
            {
              alignSelf: "center",
              fontSize: 30,
              position: "absolute",
              left: 55,
              fontWeight: Global.fontWeight,
              color: Global.textcolor,
              bottom: 10,
            },
            Platform.OS === "ios" && {},
          ]}
        >
          +{" "}
        </Text>
        {getIsoCode(countryCode) !== undefined && (
          <CountryFlag
            style={{
              alignSelf: "center",
              position: "absolute",
              left: 10,
              bottom: 15,
            }}
            isoCode={
              getIsoCode(countryCode) === undefined
                ? ""
                : getIsoCode(countryCode)
            }
            size={25}
          />
        )}

        {/*  <Text
          style={{
            alignSelf: 'center',
            fontSize: 30,
            position: 'absolute',
            right: 15,
            fontWeight: Global.fontWeight,
            bottom: Platform.OS === 'ios' ? 10 : 5,
            color: Global.textcolor,
          }}>
          +{' '}
        </Text> */}
      </View>
      <Text style={styles.label}>المبلغ المراد إرساله: (بالدولار)</Text>
      <TextInput
        value={amount}
        onChangeText={setAmount}
        style={styles.amountInput}
        keyboardType="decimal-pad"
        mode="outlined"
        right={<TextInput.Icon icon="currency-usd" />}
      />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          marginTop: 20,
        }}
      >
        <Text style={[styles.alert]}> :رصيدك الحالي</Text>
        <TouchableOpacity
          style={{
            borderBottomWidth: 1,
            borderColor: Global.primary,
          }}
          onPress={() => setAmount(mainStore.balance)}
        >
          <Txt
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: Global.primary,
              //  marginBottom: Platform.OS === 'ios' ? 3 : -3,
            }}
          >
            ${Global.arabicToNumeric(mainStore.balance)}
          </Txt>
        </TouchableOpacity>
      </View>
      <Button
        mode="contained"
        onPress={handleSend}
        disabled={!(Number(mainStore.balance) >= amount) || amount <= 0}
        style={styles.sendButton}
        contentStyle={styles.sendButtonContent}
        labelStyle={styles.sendButtonLabel}
      >
        التالي
      </Button>
      <Modal
        visible={modal2Visible}
        animationInTiming={350}
        animationOutTiming={800}
        backdropTransitionInTiming={350}
        backdropTransitionOutTiming={0}
      >
        <TouchableOpacity
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          activeOpacity={1}
          onPress={() => setModal2Visible(false)}
          onRequestClose={() => setModal2Visible(false)}
        >
          <View
            padding={8}
            style={{
              width: "100%",
              backgroundColor: Global.backgroundColor,
              borderRadius: 10,
            }}
            alignItems={"center"}
            alignContent={"center"}
          >
            <IconButton icon="information-outline" color={"red"} size={50} />
            <Text
              paddingBottom={10}
              style={{
                textAlign: "center",
                fontWeight: Global.fontWeight,
                lineHeight: 23,
              }}
            >
              {mainStore.errorMessage}
            </Text>
          </View>

          <Button
            mode="contained"
            style={{
              alignSelf: "center",
              width: "100%",
              height: 50,
              justifyContent: "center",
              margin: 10,
              fontFamily: "Tajawal-Bold",
            }}
            onPress={() =>
              mainStore.errorMessage !== ""
                ? setModal2Visible(false)
                : (setModal2Visible(false), navigation.navigate("HomeScreen"))
            }
          >
            موافق
          </Button>
        </TouchableOpacity>
      </Modal>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={onDismissSnackBar}
        duration={5000}
        style={{
          backgroundColor: "lightgray",
          bottom: 150,
          right: -18,
          position: "relative",
        }}
      >
        <Text
          style={{
            color: mainStore.transferStatus === 200 ? "green" : "red",
          }}
        >
          {mainStore.transferStatus === 200
            ? "تم إرسال الحوالة بنجاح!"
            : "حدثت مشكلة الرجاء المحاولة لاحقًا"}
        </Text>
      </Snackbar>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: Global.backgroundColor,
    paddingVertical: 10,
  },
  label: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 5,
    fontWeight: Global.fontWeight,
    color: Global.textcolor,
  },
  alert: {
    fontSize: 16,
    marginEnd: 5,
    fontWeight: Global.fontWeight,
  },
  phoneInputContainer: {
    flexDirection: "row",
  },
  countryCodeInput: {
    flex: 1.7,
    marginTop: 10,
  },
  phoneNumberInput: {
    flex: 3,
    marginLeft: 10,
    textAlign: "left",
    marginTop: 10,
  },
  amountInput: {
    marginTop: 10,
    textAlign: "auto",
  },
  sendButton: {
    marginTop: 30,
    borderRadius: 5,
  },
  sendButtonContent: {
    height: 50,
  },
  sendButtonLabel: {
    fontSize: 18,
  },
});

export default observer(SendToWalletScreen);
