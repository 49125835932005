/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Image,
  Linking,
  Text as Txt,
  Dimensions,
  useWindowDimensions,
  TouchableWithoutFeedback,
  AppState,
  Modal,
  FlatList,
  ScrollView,
} from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";

import {
  Title,
  Button,
  List,
  Chip,
  Text,
  Avatar,
  Menu,
  MD3Colors,
  IconButton,
  Card,
  Paragraph,
} from "react-native-paper";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { useTheme } from "react-native-paper";
import { Platform } from "react-native";
import MaterialIcon from "react-native-vector-icons/MaterialIcons";
import Foundation from "react-native-vector-icons/Foundation";
import { useNavigation } from "@react-navigation/native";
import mainStore from "../stores/MainStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ActivityIndicator, MD2Colors } from "react-native-paper";
import Global from "../Global";
import Carousel from "react-native-reanimated-carousel";

import AsyncStorage from "@react-native-async-storage/async-storage";

//import {Tooltip} from '@rneui/themed';

//import Tooltip from "react-native-walkthrough-tooltip";

const Wait = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};
const calculateTotal = (index) => {
  const length = mainStore.transactions.length;
  let total = 0;
  mainStore.transactions.slice(index, length + 1).forEach((transaction) => {
    total += +transaction.amount;
  });
  return total / 100; // If the amounts are in cents
};

const Transaction = ({ transaction, transactions, index }) => {
  const navigation = useNavigation();
  const userStatus = transaction.deposit?.by_user_status;
  const { created_at, amount, type } = transaction;
  const handleItemPress = () => {
    // Navigate to the TransactionDetails screen with the selected transaction
    const calcMethod =
      transaction.external_transfer?.external_transfer_method?.calc_method;

    calcMethod === "sawa" || calcMethod === "agent"
      ? navigation.navigate("TransactionDetails2", transaction)
      : navigation.navigate("TransactionDetailsScreen", transaction);
  };
  const Total = calculateTotal(index);
  let isSuccess = true;
  let status = null;
  for (const key in transaction) {
    if (transaction[key]?.status) {
      status = transaction[key].status;
      if (status !== "success") {
        isSuccess = false;
        break;
      }
    }
  }
  return (
    <List.Item
      title={() => (
        <Text
          style={[
            {
              fontSize: 16,
              color: Global.textcolor,
              direction: "rtl",
              alignSelf: "flex-start",
            },
            styles.ios,
          ]}
        >
          {transaction.meta?.description
            ? transaction.meta.description
            : transaction?.external_transfer
            ? transaction.external_transfer.receiver_name
            : transaction.deposit?.deposit_method != null
            ? "إيداع عبر " + transaction.deposit.deposit_method.name
            : type === "deposit"
            ? transaction.related_to.Name
            : transaction.top_up !== null
            ? "شحن رصيد/ وحدات"
            : transaction.related_to === null && type === "withdraw"
            ? "سحب عبر USDT"
            : transaction.related_to.Name}
        </Text>
      )}
      descriptionStyle={{ alignSelf: Global.isios ? "flex-start" : "auto" }}
      description={
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignContent: "flex-start",
            alignSelf: "flex-start",
            flexWrap: "wrap-reverse",
            maxWidth: 200,
            direction: "rtl",
          }}
        >
          <Text
            style={[
              {
                fontSize: 13,
                color: Global.graycolor,
                fontWeight: Global.fontWeight,
                marginTop: 5,
                marginHorizontal: 5,
                textAlign: "right",
              },
            ]}
          >
            {moment(created_at)
              .format("DD/MM/YYYY, h:mm a")
              .toLocaleUpperCase()}
          </Text>

          {(!isSuccess ||
            transaction?.external_transfer?.is_picked_up ||
            !transaction.confirmed) && (
            <View
              style={{
                marginTop: 5,
                borderWidth: 0.5,
                borderColor: "#b38c04",
                alignSelf: "flex-start",
                borderRadius: 3,
                padding: 3,
              }}
            >
              <Text
                style={{
                  fontWeight: Global.fontWeight,
                  fontSize: 11,
                  color:
                    userStatus === "canceled" || status === "cancelled"
                      ? "red"
                      : status === "processing" ||
                        transaction?.external_transfer?.is_picked_up
                      ? "green"
                      : "#b38c04",

                  // alignSelf: 'center',
                  // justifyContent: 'center',
                }}
              >
                {userStatus === "canceled" || status === "cancelled"
                  ? "ملغاة"
                  : status === "processing"
                  ? "تتم المعالجة"
                  : transaction?.external_transfer?.is_picked_up
                  ? "مستلمة"
                  : "قيد الانتظار"}
              </Text>
            </View>
          )}
        </View>
      }
      left={() => (
        <Avatar.Icon
          icon={type === "withdraw" ? "arrow-up" : "arrow-down"}
          color={type === "withdraw" ? "red" : "green"}
          style={{ backgroundColor: "transparent", marginRight: -20 }}
          size={30}
        />
      )}
      style={{
        backgroundColor: Global.backgroundColor,
      }}
      onPress={() => handleItemPress()}
      right={() => (
        <View
          style={{
            flexDirection: "column",
            // alignContent: 'center',
            justifyContent: "center",
            marginRight: -20,
          }}
        >
          {transaction.external_transfer === null &&
          transaction.top_up === null ? (
            <Text
              style={{
                fontWeight: Global.fontWeight,
                fontSize: 18,
                color: type === "withdraw" ? "red" : "green",
                marginLeft: 10,
                alignSelf: "flex-end",
              }}
            >
              {type === "deposit" ? "+" : "-"}{" "}
              {Global.arabicToNumeric(
                (amount / 100).toFixed(2).toString()
              ).split("-")}
              {" $"}
            </Text>
          ) : transaction.top_up !== null ? (
            <Txt
              style={{
                alignSelf: "flex-end",
                fontSize: 18,
                marginLeft: 10,
                fontFamily: "Tajawal",
                color: "red",
                fontWeight: Global.fontWeight,
              }}
            >
              {Global.arabicToNumeric(
                transaction.top_up.top_up_package.mobile_balance
              )}
              {" وحدة"}
            </Txt>
          ) : (
            <Txt
              style={{
                fontWeight: Global.fontWeight,
                fontSize: 18,
                color: type === "withdraw" ? "red" : "green",
                marginLeft: 10,
                alignSelf: "flex-end",
                fontFamily: "Tajawal",
              }}
            >
              {Global.arabicToNumeric(
                transaction.external_transfer?.receiving_amount
              )}{" "}
              {transaction.external_transfer?.external_transfer_method
                ?.exchangeRate?.code_ar === "ج. م"
                ? "جنيه"
                : transaction.external_transfer?.receiving_currency === "usd"
                ? "$"
                : transaction.external_transfer?.receiving_currency === "eur"
                ? "€"
                : transaction.external_transfer?.external_transfer_method
                    ?.exchangeRate?.code_ar}
            </Txt>
          )}

          {transaction.deposit?.deposit_method != null && (
            <Text
              style={{
                alignSelf: "flex-end",
                marginTop: Global.isios ? 5 : 0,
                marginLeft: 10,
              }}
            >
              {Global.arabicToNumeric(transaction.deposit.paying_amount) +
                " " +
                transaction.deposit.paying_currency.toLocaleUpperCase()}
            </Text>
          )}
          {transaction.top_up !== null && (
            <Text
              style={{
                alignSelf: "flex-end",
                marginTop: Global.isios ? 5 : 0,
                marginLeft: 10,
              }}
            >
              {"- " +
                Global.arabicToNumeric(
                  transaction.top_up.top_up_package.amount
                ) +
                " $"}
            </Text>
          )}
          {transaction.external_transfer !== null && (
            <Text
              style={{
                alignSelf: Global.isios ? "flex-end" : "flex-end",
                marginTop: Global.isios ? 5 : 0,
                marginLeft: 10,
              }}
            >
              {type === "deposit" ? "+" : "-"}{" "}
              {Global.arabicToNumeric((amount / 100).toFixed(2)).split("-")}
              {" $"}
            </Text>
          )}
          {/*
          <Txt style={{color: Global.graycolor, fontSize: 12, fontWeight: 800}}>
            المتبقي:
            {' ' + Total}
          </Txt> */}
        </View>
      )}
    />
  );
};
const HomeScreen = observer(({ navigation }) => {
  const exhangeRates = ({ item }) => (
    <View
      style={{
        flexDirection: "row",
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderColor: Global.placeholder,
      }}
    >
      <Text
        style={{
          flex: 1,
          fontSize: 16,
          textAlign: "center",
          color: Global.primary,
        }}
      >
        {item.title}
      </Text>
      {selectedCurrency === "egp" && (
        <Text style={{ flex: 1, fontSize: 16, textAlign: "center" }}>
          {Global.arabicToNumeric(item.ask)}
        </Text>
      )}
      <Text style={{ flex: 1, fontSize: 16, textAlign: "center" }}>
        {Global.arabicToNumeric(item.bid)}
      </Text>
    </View>
  );
  const currencies = useMemo(
    () => [
      {
        id: 0,
        cur: "syp",
        curAR: "ليرة سورية",
        balance: Global.arabicToNumeric(
          mainStore?.balanceConverted?.balanceSYP
        ),
      },
      {
        id: 1,
        cur: "egp",
        curAR: "جنيه مصري",
        balance: Global.arabicToNumeric(
          mainStore?.balanceConverted?.balanceEGP
        ),
      },
      {
        id: 2,
        cur: "mad",
        curAR: "درهم مغربي",
        balance: Global.arabicToNumeric(
          mainStore?.balanceConverted?.balanceMAD
        ),
      },
    ],
    [mainStore.balanceConverted]
  );
  const translateY = useRef(new Animated.Value(300)).current;
  const { userName, balance } = mainStore;
  const [currency, setCurrency] = useState("دولار | USD");
  const theme = useTheme();
  const [refreshing, setRefreshing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dataHeight] = useState(new Animated.Value(200)); // Initial height for 4 items, adjust accordingly
  const imageSize = useRef(new Animated.Value(85)).current; // Using useRef to persist the value across re-renders.
  const [appState, setAppState] = useState(AppState.currentState);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const scrollViewRef = useRef(null);

  // responses from api>>
  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (appState.match(/inactive|background/) && nextAppState === "active") {
        console.error("App has come to the foreground!");
        mainStore.getBalance();

        mainStore.getLatestTransactions();
      }
      setAppState(nextAppState);
    });

    return () => {
      subscription.remove();
    };
  }, [appState]);
  useEffect(() => {
    // mainStore.getArticles();
    mainStore.getExternalCountries();
    mainStore.getIntroModals();
    mainStore.getExchangeSlider();
    //mainStore.getExchange("syp");
    //mainStore.getExchange("egp");
    mainStore.getDepositMethods();
    mainStore.getSendMethods();
    mainStore.onesignalRegister();
    mainStore.getUserdata();
    mainStore.getContact();
    mainStore.getHelpLinks();
    mainStore.getBalance();
    mainStore.getLatestTransactions();
  }, []);

  useEffect(() => {
    const fetchBalanceIndex = async () => {
      try {
        // Attempt to get the balanceIndex from AsyncStorage
        const storedIndex = await AsyncStorage.getItem("balanceIndex");
        let index = 0;

        if (storedIndex !== null) {
          // If a value is stored, parse it to an integer
          index = parseInt(storedIndex, 10);
        }

        // Use the index to set the selected balance
        setSelectedBalance(currencies[index]);
      } catch (error) {
        // Handle errors, for instance, log them
        console.error(
          "Failed to fetch balance index from AsyncStorage:",
          error
        );
      }
    };

    fetchBalanceIndex();
  }, [currencies]);
  useEffect(() => {
    const getSelectedCurrency = async () => {
      try {
        const storedCurrency = await AsyncStorage.getItem("selectedCurrency");
        if (storedCurrency !== null) {
          setSelectedCurrency(storedCurrency);
        }
      } catch (e) {
        console.error("Failed to fetch currency from AsyncStorage");
      }
    };

    getSelectedCurrency();
  }, []);

  const onRefresh = useCallback(() => {
    //mainStore.getExchange("syp");
    mainStore.getExchangeSlider();
    //  mainStore.getExchange("egp");
    mainStore.getDepositMethods();
    mainStore.getSendMethods();
    mainStore.getBalance();

    mainStore.getLatestTransactions();
    mainStore.getExternalCountries();

    setRefreshing(false);
  }, []);

  // account confirmation>>
  useEffect(() => {
    setTimeout(() => {
      mainStore.isVerified === 0 && navigation.navigate("ConfirmationScreen");
    }, 1000);
    //console.error(mainStore.isVerified);
  });

  //animations>>
  //...list animation>>
  /*   const filteredData = mainStore.slider.filter(
    (item) =>
      item.pair.endsWith(selectedCurrency) && item.pair !== "usd_local/syp"
  ); */
  const filteredData = mainStore.slider.filter((item) =>
    item.pair.endsWith(selectedCurrency)
  );

  const visibleData = isExpanded ? filteredData : filteredData.slice(0, 4);

  const toggleList = (isMenu) => {
    if (isMenu) {
      Animated.timing(dataHeight, {
        toValue: 200,
        duration: 500,
        useNativeDriver: false,
      }).start();

      setIsExpanded(false);
    } else {
      Animated.timing(dataHeight, {
        toValue: isExpanded
          ? 200
          : Global.isios
          ? filteredData.length * 37.5
          : filteredData.length * 45.83,
        duration: isExpanded ? 500 : Global.isios ? 900 : 1000,
        useNativeDriver: false,
      }).start();

      setIsExpanded(!isExpanded);
    }
  };
  //...image animation>>
  useEffect(() => {
    imageSize.stopAnimation();

    Animated.timing(imageSize, {
      toValue: isExpanded ? 125 : 85,
      duration: isExpanded ? 1000 : 300,
      useNativeDriver: false,
    }).start();
  }, [isExpanded]);

  // tooltip>>
  const handleTooltipClose = () => {
    setShowTooltip(false);
    AsyncStorage.setItem("hasShownTooltip2", true);
  };
  /*   useEffect(() => {
    const checkTooltipShown = async () => {
      const hasShownTooltip = await AsyncStorage.getItem('hasShownTooltip2');

      if (!hasShownTooltip) {
        scrollViewRef.current.scrollToEnd({animated: true});
        setShowTooltip(true);
      }
    };

    checkTooltipShown();
  }, []); */

  //functions and more>>
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const openMenu2 = () => setVisible2(true);
  const closeMenu2 = () => setVisible2(false);
  const extractCurrencies = (data) => {
    const currencySet = new Set();
    data.forEach((item) => {
      const [, currency] = item.pair.split("/");
      currencySet.add(currency);
    });
    return Array.from(currencySet);
  };

  const currencyOptions = extractCurrencies(mainStore.slider);
  const selectBalance = async (balance) => {
    setSelectedBalance(balance);
    closeMenu();
    await AsyncStorage.setItem("balanceIndex", balance.id.toString());
  };
  const selectCurrency = async (currency) => {
    setSelectedCurrency(currency);
    await AsyncStorage.setItem("selectedCurrency", currency);
    closeMenu2();
  };
  const [backgroundColor, setBackgroundColor] = useState(undefined);

  useEffect(() => {
    let timeout;

    if (modalVisible || modal2Visible || modal3Visible) {
      timeout = setTimeout(() => {
        setBackgroundColor(Global.modaloverlay);
      }, 300);
    } else {
      setBackgroundColor(undefined);
    }

    return () => clearTimeout(timeout);
  }, [modal2Visible, modal3Visible, modalVisible]);
  const handleMenuItemPress = (screen) => {
    setModalVisible(false);
    setModal3Visible(false);
    navigation.navigate(screen);
  };
  const ArticleCard = ({ title, featured_img, link, isFirst }) => (
    <Card
      onPress={() => {
        const URL = link;
        Linking.openURL(URL).catch((err) => {
          console.error("Failed to open url:", err);
        });
      }}
      style={{
        marginHorizontal: 10,
        borderColor: Global.placeholder,
        height: 160, // Adjust height to accommodate the image and title
        // backgroundColor: Global.slaydcolor,
      }}
    >
      <Image
        source={{ uri: featured_img }} // Replace with your actual image property
        style={{
          width: "100%",
          height: 160,
          resizeMode: "stretch",
          borderRadius: 10,
          //  borderBottomRightRadius: 0,
          // borderBottomLeftRadius: 0,
        }}
      />
      <Text
        style={{
          //  marginTop: 20,
          color: Global.primary,
          fontSize: 16,
          lineHeight: 25,
          fontWeight: Global.fontWeight,
          padding: 10, // Add some padding for the title
        }}
      >
        {title}
      </Text>
    </Card>
  );
  if (
    mainStore.loading ||
    mainStore.isVerified == "" ||
    mainStore.balance == "" ||
    mainStore.balanceConverted == "" ||
    mainStore.transactionsLoaded === false
  ) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <>
      <FlatList
        //scrollEnabled={false}
        style={{
          backgroundColor: Global.backgroundColor,
          flex: 1,
        }}
        ref={scrollViewRef}
        // onLayout={() => scrollViewRef.current.scrollToEnd({animated: true})}
        showsVerticalScrollIndicator={false}
        data={[{ id: "single-item" }]} // This is just to have a single item in FlatList
        renderItem={({ item }) => (
          <View
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                size={30}
                titleColor={"red"}
                tintColor={Global.primary}
                refreshing={refreshing}
                onRefresh={onRefresh}
              />
            }
            style={{
              backgroundColor: Global.backgroundColor,
            }}
          >
            <View
              style={{
                // backgroundColor: Global.backgroundColor,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 20,
                paddingTop: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => navigation.navigate("AccountScreen")}
                style={{ alignSelf: "center", fontFamily: "Tajawal-Bold" }}
              >
                <View style={styles.circle}>
                  <Text style={styles.initial}>
                    {userName ? userName.charAt(0).toUpperCase() : ""}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.navigate("InviteFriendScreen")}
              >
                <View
                  style={{
                    borderRadius: 15,
                    borderWidth: 1,
                    borderColor: Global.cardcolor,
                    backgroundColor: theme.colors.primary,
                    padding: 3,
                    paddingHorizontal: 5,
                    flexDirection: "row",
                  }}
                >
                  <Txt style={styles.earn}>اربح 5$</Txt>
                </View>
              </TouchableOpacity>
            </View>
            <View
              // backgroundColor={Global.amountbackground}
              borderRadius={15}
              marginHorizontal={10}
            >
              {/* <Button
              icon={({size, color}) => (
                <Icon name="chevron-down" size={25} color={color} />
              )}
              mode="outlined"
              labelStyle={{color: Global.textcolor}}
              style={styles.Cbutton}
              onPress={() => setModal2Visible(true)}>
              {currency}
            </Button> */}

              <Text style={styles.title}>:رصيدك الحالي</Text>
              <Text style={styles.balance}>
                ${Global.arabicToNumeric(balance)}
              </Text>
              <Menu
                visible={visible}
                onDismiss={closeMenu}
                style={{ marginStart: Dimensions.get("window").width * 0.25 }}
                contentStyle={{ alignSelf: "center", marginTop: 45 }}
                anchor={
                  <View
                    style={{
                      borderRadius: 10,
                      //borderWidth: 0.5,
                      borderColor: Global.graycolor,
                      width: "50%",
                      alignSelf: "center",
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={openMenu}
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{ color: Global.graycolor, alignSelf: "center" }}
                      >
                        ≈ {selectedBalance.balance}{" "}
                        {selectedBalance.cur?.toUpperCase()}
                      </Text>
                      <Text
                        style={{
                          fontSize: 16,
                          marginRight: 7,
                          marginTop: -7.5,
                          transform: [{ scaleX: 1.5 }],
                          color: Global.primary,
                        }}
                      >
                        ⌄
                      </Text>
                    </TouchableOpacity>
                  </View>
                }
              >
                {currencies.map((item, index) => (
                  <Menu.Item
                    key={index}
                    title={`${item.curAR} (${item.cur.toUpperCase()})`}
                    onPress={() => selectBalance(item)}
                  />
                ))}
              </Menu>
            </View>
            <View style={styles.buttonContainer}>
              <Button
                contentStyle={{
                  direction: "ltr",
                }}
                onPress={() => {
                  setModalVisible(true);
                }}
                mode="contained"
                icon={({ size, color }) => (
                  <Icon
                    // style={{ marginHorizontal: -30 }}
                    name="download-outline"
                    size={25}
                    color={color}
                  />
                )}
                // textColor={Global.buttonTextColor}
                style={[
                  styles.button,
                  { backgroundColor: theme.colors.primary },
                ]}
              >
                إيداع
              </Button>

              <Button
                contentStyle={{
                  direction: "ltr",
                }}
                icon={({ size, color }) => (
                  <Icon name="upload-outline" size={25} color={color} />
                )}
                mode="outlined"
                textColor={Global.textcolor}
                style={styles.button}
                onPress={() => navigation.navigate("UsdtWithdraw")}
              >
                سحب
              </Button>
            </View>

            <View style={{}}>
              <Modal
                transparent={true}
                animationType="slide"
                visible={modalVisible}
                onBackdropPress={() => setModalVisible(false)}
                onModalHide={() => setModalVisible(false)}
              >
                <TouchableOpacity
                  style={{
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    backgroundColor: backgroundColor,
                  }}
                  activeOpacity={1}
                  onPress={() => setModalVisible(false)}
                  onRequestClose={() => setModalVisible(false)}
                >
                  <View
                    style={{
                      width: "90%",
                      backgroundColor: Global.backgroundColor,
                      borderRadius: 10,
                    }}
                  >
                    {mainStore.depositMethods.map((method, index) => (
                      <TouchableOpacity
                        key={method.id} // Important to have a unique key for list items
                        style={[
                          index === mainStore.depositMethods.length - 1
                            ? styles.menuItem3
                            : styles.menuItem,
                          {
                            display: method.active === 1 ? "flex" : "none",
                          },
                        ]}
                        onPress={() => handleMenuItemPress(method.screen_name)}
                      >
                        {method.icon && method.icon.includes("png") ? (
                          <Image
                            source={{
                              uri: `https://api.yamersal.com/images/${method.icon}`,
                            }}
                            style={styles.icon}
                          />
                        ) : (
                          <Icon
                            name={method.icon}
                            marginRight={5}
                            size={30}
                            color={Global.textcolor}
                          />
                        )}
                        <Text
                          style={{
                            fontSize: 18,
                            color: Global.textcolor,
                            fontWeight:
                              Platform.OS === "ios" ? "900" : "normal",
                            marginEnd: 10,
                            direction: "rtl",
                          }}
                        >
                          {"إيداع عبر" + " " + method.name}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                  <Button
                    labelStyle={{ fontSize: 19 }}
                    mode="contained"
                    style={{
                      borderRadius: 10,
                      alignSelf: "center",
                      width: "90%",
                      height: 50,
                      justifyContent: "center",
                      margin: 10,
                      marginBottom: Global.isWebIos ? 50 : 20,
                    }}
                    onPress={() => setModalVisible(false)}
                  >
                    إلغاء
                  </Button>
                </TouchableOpacity>
              </Modal>

              <Modal
                visible={modal2Visible}
                animationInTiming={350}
                animationOutTiming={800}
                backdropTransitionInTiming={350}
                backdropTransitionOutTiming={0}
              >
                <TouchableOpacity
                  style={[styles.modalContainer2]}
                  activeOpacity={1}
                  onPress={() => setModal2Visible(false)}
                  onRequestClose={() => setModal2Visible(false)}
                >
                  <View
                    padding={8}
                    style={styles.menu}
                    alignItems={"center"}
                    alignContent={"center"}
                  >
                    <IconButton
                      icon="information-outline"
                      color={"red"}
                      size={50}
                    />
                    <Text
                      paddingBottom={10}
                      style={{
                        textAlign: "center",
                        fontWeight: Global.fontWeight,
                      }}
                    >
                      سعر الشراء: هو سعر التحويل من دولار إلى العملة المطلوبة
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: Global.fontWeight,
                      }}
                      paddingBottom={20}
                    >
                      سعر المبيع: هو سعر التحويل من العملة المطلوبة إلى دولار
                    </Text>
                  </View>

                  <Button
                    mode="contained"
                    style={styles.modalbutton}
                    onPress={() => setModal2Visible(false)}
                  >
                    موافق
                  </Button>
                </TouchableOpacity>
              </Modal>

              <Modal
                transparent={true}
                visible={modal3Visible}
                onBackdropPress={() => setModal3Visible(false)}
                onModalHide={() => setModal3Visible(false)}
                animationType="slide"
              >
                <TouchableOpacity
                  style={{
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    backgroundColor: backgroundColor,
                  }}
                  activeOpacity={1}
                  onPress={() => setModal3Visible(false)}
                  onRequestClose={() => setModal3Visible(false)}
                >
                  <View
                    style={{
                      width: "90%",
                      backgroundColor: Global.backgroundColor,
                      borderRadius: 10,
                    }}
                  >
                    <TouchableOpacity
                      style={[styles.menuItem]}
                      onPress={() => handleMenuItemPress("QrDepositScreen")}
                    >
                      <Icon
                        name={"transfer"}
                        marginRight={5}
                        size={30}
                        color={Global.textcolor}
                      />
                      <Text
                        style={{
                          fontSize: 18,
                          color: Global.textcolor,
                          fontWeight: Platform.OS === "ios" ? "900" : "normal",
                          marginEnd: 10,
                          direction: "rtl",
                        }}
                      >
                        {"طلب حوالة عبر  QR"}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.menuItem3]}
                      onPress={() => handleMenuItemPress("QrReader")}
                    >
                      <Icon
                        name={"send"}
                        marginRight={5}
                        size={25}
                        color={Global.textcolor}
                      />
                      <Text
                        style={{
                          fontSize: 18,
                          color: Global.textcolor,
                          fontWeight: Platform.OS === "ios" ? "900" : "normal",
                          marginEnd: 10,
                          direction: "rtl",
                        }}
                      >
                        {"إرسال حوالة عبر  QR"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Button
                    labelStyle={{ fontSize: 19 }}
                    mode="contained"
                    style={{
                      borderRadius: 10,
                      alignSelf: "center",
                      width: "90%",
                      height: 50,
                      justifyContent: "center",
                      margin: 10,
                      marginBottom: Global.isWebIos ? 50 : 20,
                    }}
                    onPress={() => setModal3Visible(false)}
                  >
                    إلغاء
                  </Button>
                </TouchableOpacity>
              </Modal>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginHorizontal: 10,
                marginTop: 20,
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: Global.graycolor,
                  marginRight: 5,
                  fontWeight: Global.fontWeight,
                }}
              >
                :آخر العمليات
              </Text>
              <Button
                style={{
                  display:
                    mainStore.latestTransactions.length === 0 ? "none" : "flex",
                  marginLeft: -10,
                }}
                onPress={() => navigation.navigate("TransactionScreen")}
                labelStyle={{ color: Global.secondary }}
                mode="text"
              >
                عرض الجميع
              </Button>
            </View>

            {/* <List.Accordion
            title="Today"
            left={props => <List.Icon {...props} icon="calendar-today" />}
             expanded={expanded}> */}
            {mainStore.latestTransactions.length === 0 && (
              <View>
                <Icon
                  name="bank-transfer"
                  size={125}
                  color={Global.graycolor}
                  style={{ alignSelf: "center" }}
                />
                <Text
                  style={{
                    alignSelf: "center",
                    color: Global.primary,
                    marginBottom: 10,
                  }}
                >
                  لم تقم بأي عملية أو تصلك أي حوالة حتى الآن!
                </Text>
              </View>
            )}
            <FlatList
              data={mainStore.latestTransactions}
              renderItem={({ item, index }) => (
                <Transaction
                  transaction={item}
                  index={index}
                  transactions={mainStore.latestTransactions}
                />
              )}
              keyExtractor={(item) => item.id.toString()}
              style={{ marginBottom: 15 }}
            />
            <Text
              style={{
                marginBottom: 10,
                fontSize: 16,
                marginRight: 15,
                color: Global.graycolor,
                fontWeight: Global.fontWeight,
                //display: mainStore.slider.length === 0 ? 'none' : 'flex',
              }}
            >
              :خدمات يامرسال
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginHorizontal: 15,
                marginBottom: 20,
                borderRadius: 15,
                paddingVertical: 15,
                paddingEnd: 10,
                borderColor: Global.placeholder,
                backgroundColor: Global.backgroundColor,
                borderWidth: 1,
              }}
            >
              <View
                style={{
                  display: mainStore.slider.length === 0 ? "none" : "flex",
                }}
              >
                <IconButton
                  onPress={() => navigation.navigate("MobileRechargeScreen")}
                  icon={() => (
                    <Image
                      source={{
                        uri: "https://api.yamersal.com/images/mtn.png",
                      }}
                      style={{
                        width: "85%",
                        height: "85%",
                        resizeMode: "contain",
                      }}
                    />
                  )}
                  size={55}
                  iconColor={Global.iconcolor}
                  style={{
                    borderColor: Global.placeholder,
                    justifyContent: "center",
                  }}
                />
                <Text style={{ marginTop: 10, alignSelf: "center" }}>
                  شحن رصيد
                </Text>
              </View>
              <View
                style={{
                  display: mainStore.slider.length === 0 ? "none" : "flex",
                }}
              >
                <IconButton
                  onPress={() => navigation.navigate("CalcScreen")}
                  icon={() => (
                    <Image
                      source={{
                        uri: "https://sp-today.com/news/wp-content/uploads/2024/08/IMG_4907.png",
                      }}
                      style={{
                        width: "85%",
                        height: "85%",
                        resizeMode: "contain",
                      }}
                    />
                  )}
                  size={55}
                  iconColor={Global.iconcolor}
                  style={{
                    borderColor: Global.placeholder,
                    justifyContent: "center",
                  }}
                />
                <Text style={{ alignSelf: "center" }}>حاسبة</Text>
                <Text style={{ alignSelf: "center" }}>الحوالات</Text>
              </View>
              <View
                style={{
                  display: mainStore.contact.whatsapp ? "flex" : "none",
                }}
              >
                <IconButton
                  onPress={() => {
                    const URL = `https://wa.me/${mainStore.contact.whatsapp}`;
                    Linking.openURL(URL).catch((err) => {
                      console.error("Failed to open WhatsApp:", err);
                    });
                  }}
                  icon={() => (
                    <Image
                      source={{
                        uri: "https://api.yamersal.com/images/whatsapp.png",
                      }}
                      style={{
                        width: "85%",
                        height: "85%",
                        resizeMode: "contain",
                      }}
                    />
                  )}
                  size={55}
                  iconColor={Global.iconcolor}
                  style={{
                    borderColor: Global.placeholder,
                    justifyContent: "center",
                  }}
                />
                <Text style={{ alignSelf: "center" }}>خدمة</Text>
                <Text style={{ alignSelf: "center" }}>العملاء</Text>
              </View>

              <View>
                <IconButton
                  onPress={() => {
                    const telegramURL = `https://t.me/${mainStore.contact.telegram}`;

                    Linking.openURL(telegramURL).catch((err) => {
                      console.error("Failed to open Telegram channel:", err);
                    });
                  }}
                  icon={() => (
                    <Image
                      source={{
                        uri: "https://api.yamersal.com/images/tg.png",
                      }}
                      style={{
                        width: "85%",
                        height: "85%",
                        resizeMode: "contain",
                      }}
                    />
                  )}
                  size={55}
                  iconColor={Global.iconcolor}
                  style={{
                    borderColor: Global.placeholder,
                    justifyContent: "center",
                  }}
                />
                <Text style={{ alignSelf: "center" }}>خدمة</Text>
                <Text style={{ alignSelf: "center" }}>العملاء</Text>
              </View>
              <View>
                <IconButton
                  onPress={() => navigation.navigate("FreelancersDeposit")}
                  icon={() => (
                    <Image
                      source={{
                        uri: "https://api.yamersal.com/images/khamsat.png",
                      }}
                      style={{
                        width: "85%",
                        height: "85%",
                        resizeMode: "contain",
                      }}
                    />
                  )}
                  size={55}
                  iconColor={Global.iconcolor}
                  style={{
                    borderColor: Global.placeholder,
                    justifyContent: "center",
                    // marginBottom: -7,
                  }}
                />
                <Text style={{ textAlign: "center" }}>سحب من</Text>
                <Text style={{ textAlign: "center" }}>منصة</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
                marginHorizontal: 15,
                display: mainStore.slider.length === 0 ? "none" : "flex",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: Global.graycolor,
                  fontWeight: Global.fontWeight,
                  alignSelf: "center",
                  writingDirection: "rtl",
                }}
              >
                سعر الصرف للحوالات:
              </Text>
              <Menu
                visible={visible2}
                onDismiss={closeMenu2}
                anchor={
                  <View
                    style={{
                      borderRadius: 10,
                      //borderWidth: 0.5,
                      borderColor: Global.graycolor,
                      alignSelf: "center",
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={openMenu2}
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          color: Global.primary,
                          alignSelf: "center",
                          fontSize: 16,
                        }}
                      >
                        {selectedCurrency?.toUpperCase()
                          ? selectedCurrency?.toUpperCase()
                          : "اختر عملة"}
                      </Text>
                      <Text
                        style={{
                          fontSize: 16,
                          marginRight: 7,
                          marginTop: -7.5,
                          transform: [{ scaleX: 1.5 }],
                          color: Global.primary,
                        }}
                      >
                        ⌄
                      </Text>
                    </TouchableOpacity>
                  </View>
                }
              >
                {currencyOptions.map((currency, index) => (
                  <Menu.Item
                    key={index}
                    onPress={() => {
                      toggleList(true);
                      selectCurrency(currency);
                    }}
                    title={currency?.toUpperCase()}
                  />
                ))}
              </Menu>
            </View>
            <View
              style={{
                padding: 10,
                display: mainStore.slider.length === 0 ? "none" : "flex",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#ccc",
                  paddingBottom: 10,
                }}
              >
                <Text
                  style={{
                    flex: 1,
                    fontSize: 16,
                    textAlign: "center",
                    color: Global.graycolor,
                  }}
                >
                  العملة
                </Text>
                {selectedCurrency === "egp" && (
                  <Text
                    style={{
                      flex: 1,
                      fontSize: 16,
                      textAlign: "center",
                      color: Global.graycolor,
                    }}
                  >
                    الشراء
                  </Text>
                )}
                <Text
                  style={{
                    flex: 1,
                    fontSize: 16,
                    textAlign: "center",
                    color: Global.graycolor,
                  }}
                >
                  {selectedCurrency === "egp" ? "المبيع" : "سعر الصرف"}
                </Text>
              </View>

              <Animated.View
                style={{
                  height: filteredData.length < 4 ? 75 : dataHeight,
                  overflow: "visible",
                  // justifyContent: 'center',
                  marginBottom: filteredData.length < 4 ? 20 : undefined,
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    alignSelf: "center",
                    opacity: 0.3,
                    bottom: isExpanded
                      ? "45%"
                      : filteredData.length < 4
                      ? "0%"
                      : Global.isWebIos
                      ? "35%"
                      : "35%",
                    // display: isExpanded ? 'flex' : 'none',
                  }}
                >
                  <Animated.Image
                    source={require("../assets/logo.webp")}
                    style={{
                      resizeMode: "cover",

                      width: imageSize,
                      height: imageSize,
                    }}
                  />

                  <Text style={{ alignSelf: "center" }}>yamersal.com</Text>
                </View>
                <FlatList
                  data={visibleData}
                  keyExtractor={(item) => item.pair}
                  renderItem={exhangeRates}
                />
              </Animated.View>
            </View>
            <IconButton
              size={35}
              iconColor={Global.primary}
              icon={isExpanded ? "arrow-up" : "arrow-down"}
              style={{
                display:
                  mainStore.slider.length === 0 || filteredData.length < 4
                    ? "none"
                    : "flex",

                alignSelf: "center",
                marginTop: isExpanded ? (Global.isWebIos ? -60 : -80) : -40,
              }}
              onPress={() => toggleList(false)}
            />
            {/*  <View
            style={{
              justifyContent: "flex-start",
              flexDirection: "column",
              paddingBottom: 50,
              display: mainStore.slider.length === 0 ? "none" : "flex",
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: Global.graycolor,
                marginRight: 15,
                fontWeight: Global.fontWeight,
                marginBottom: 10,
              }}
            >
              :أكاديمية يامرسال
            </Text>
            <View
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Carousel
                loop
                width={Dimensions.get("window").width / 1.5}
                height={Dimensions.get("window").height * 0.35}
                autoPlay={true}
                data={mainStore.articles}
                autoPlayInterval={500}
                scrollAnimationDuration={1000}
                renderItem={({ item, index }) => {
                  return <ArticleCard {...item} />;
                }}
              />
            </View>
          </View> */}

            {/*    <ActionButton
            buttonColor="rgba(231,76,60,1)"
            position="left"
            bgColor="transparent"
            useNativeFeedback={false}
            offsetX={15}>
            <ActionButton.Item
              buttonColor="transparent"
              hideShadow={true}
              hideLabelShadow={false}
              title="Telegram"
              onPress={() => {
                const telegramURL = `https://t.me/${mainStore.contact.telegram}`;

                Linking.openURL(telegramURL).catch(err => {
                  console.error('Failed to open Telegram channel:', err);
                });
              }}>
              <Image
                source={{
                  uri: 'https://seeklogo.com/images/T/telegram-logo-AD3D08A014-seeklogo.com.png',
                }}
                style={styles.icon2}
              />
            </ActionButton.Item>

            <ActionButton.Item
              buttonColor="transparent"
              hideShadow={true}
              hideLabelShadow={false}
              title="WhatsApp"
              onPress={() => {
                const URL = `https://wa.me/${mainStore.contact.whatsapp}`;
                Linking.openURL(URL).catch(err => {
                  console.error('Failed to open WhatsApp:', err);
                });
              }}>
              <Image
                source={{
                  uri: 'https://creazilla-store.fra1.digitaloceanspaces.com/icons/3254551/whatsapp-icon-md.png',
                }}
                style={styles.icon2}
              />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor="transparent"
              hideShadow={true}
              hideLabelShadow={false}
              title="الأسئلة الشائعة"
              onPress={() => {
                navigation.navigate('FaqScreen');
              }}>
              <Icon
                name="chat-question-outline"
                size={50}
                color={Global.iconcolor}
              />
            </ActionButton.Item>
          </ActionButton> */}
          </View>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            size={30}
            titleColor={"red"}
            tintColor={Global.primary}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      />
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Global.backgroundColor,
    flexDirection: "column",
  },
  title: {
    alignSelf: "center",
    fontSize: 14,
    color: Global.graycolor,
    marginBottom: 10,
    fontWeight: Global.fontWeight,
    //fontFamily: 'Tajawal-Bold',
  },
  balance: {
    alignSelf: "center",
    fontSize: 35,
    fontWeight: "bold",
    color: Global.textcolor,
    //marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: 20,
    marginBottom: 5,
  },
  button: {
    alignSelf: "center",
    width: "45%",
    height: 50,
    justifyContent: "center",
    marginHorizontal: 10,
    fontFamily: "Tajawal-Bold",
    borderRadius: 10,
  },
  Cbutton: {
    alignSelf: "center",
    width: "45%",
    height: 40,
    justifyContent: "center",
    margin: 10,
    fontFamily: "Tajawal-Bold",
  },
  modalbutton: {
    borderRadius: 10,
    alignSelf: "center",
    width: "80%",
    height: 50,
    justifyContent: "center",
    margin: 10,
    marginBottom: 20,
    fontFamily: "Tajawal-Bold",
  },
  button2: {
    width: "130%",
    height: 50,
    justifyContent: "center",
    margin: 10,

    fontFamily: "Tajawal-Bold",
  },
  ios: {
    fontWeight: Global.fontWeight,
    marginBottom: Platform.OS === "ios" ? 6 : 0,
  },
  avatar: {
    backgroundColor: "transparent",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
  },
  modalContainer2: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  menu: {
    width: "80%",
    backgroundColor: Global.backgroundColor,
    borderRadius: 10,
  },
  menuItem: {
    borderBottomWidth: 1,
    borderColor: Global.bordercolor,
    paddingHorizontal: 20,
    paddingVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  menu2Item: {
    borderBottomWidth: 1,
    borderColor: "#eee",
    paddingHorizontal: 20,
    paddingVertical: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuItem3: {
    flexDirection: "row",
    paddingHorizontal: 20,
    paddingVertical: 15,
    alignItems: "center",
  },
  menu2Item2: {
    flexDirection: "row",
    paddingHorizontal: 20,
    paddingVertical: 15,
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuText: {
    fontSize: 18,
    color: Global.textcolor,
    fontWeight: Platform.OS === "ios" ? "900" : "normal",
    marginStart: 10,
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: "contain",
    marginLeft: 8,
  },
  icon2: {
    width: 55,
    height: 55,
    resizeMode: "contain",
  },
  icon3: {
    width: "100%",
    height: "100%",
  },
  earn: {
    padding: 7,
    fontSize: 11,
    color: Global.buttonTextColor,
    fontWeight: Global.fontWeight,
    fontFamily: "Tajawal",
  },
  earn2: {
    fontSize: 13,
    color: "white",
    fontWeight: Global.fontWeight,
    fontFamily: "Tajawal",
    marginTop: 7,
    marginRight: 5,
    marginLeft: -2,
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 25,
    justifyContent: "center",
    backgroundColor: "gray",
  },
  initial: {
    fontSize: 18,
    color: "white",
    alignSelf: "center",
    fontWeight: Global.fontWeight,
    marginBottom: Platform.OS === "ios" ? -5 : 0,
  },
  actionButtonIcon: {
    fontSize: 20,
    height: 22,
    color: "white",
  },
});

export default HomeScreen;
