/* eslint-disable no-unused-expressions */
import {
  StyleSheet,
  View,
  Imag,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  ScrollView,
  Dimensions,
  Modal,
  Image,
  Linking,
  FlatList,
} from "react-native";
import React, { useEffect, useState } from "react";
import {
  Button,
  TextInput,
  Text,
  RadioButton,
  Avatar,
  Portal,
  Dialog,
  Divider,
  IconButton,
} from "react-native-paper";
import mainStore from "../../stores/MainStore";
import Global from "../../Global";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import axios from "axios";
import debounce from "lodash.debounce";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import WebView from "react-native-webview";
import AsyncStorage from "@react-native-async-storage/async-storage";

const CURRENCIES = [
  { label: "يورو", value: "eur", symbol: "EUR" },
  { label: "دولار أمريكي", value: "usd", symbol: "USD" },
  { label: "كرونة نرويجية", value: "nok", symbol: "NOK" },
  { label: "كرونة سويدية", value: "sek", symbol: "SEK" },
  { label: "فرنك سويسري", value: "chf", symbol: "CHF" },
  { label: "جنيه استرليني", value: "gbp", symbol: "GBP" },
  { label: "دولار كندي", value: "cad", symbol: "CAD" },
  { label: "ريال سعودي", value: "sar", symbol: "SAR" },
  { label: "درهم إماراتي", value: "aed", symbol: "AED" },
  { label: "رينغيت ماليزي", value: "myr", symbol: "MYR" },
];

export default function CardDepositScreen({ navigation }) {
  const [loading, setLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [amount, setAmount] = useState("200");
  const [callingCode, setCallingCode] = useState("");
  const [amountFoucs, setAmountFoucs] = useState(false);
  const [rAmountFoucs, setRAmountFoucs] = useState(false);
  const showDialog = () => {
    setDialogVisible(true);
    Keyboard.dismiss();
  };
  const hideDialog = () => setDialogVisible(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [justInfo, setJustInfo] = useState();
  const [result, setResult] = useState("");
  const [recipientAmount, setRecipientAmount] = useState(" ");
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [editing, setediting] = useState(false);
  const [editing2, setediting2] = useState(false);
  const [webView, showWebView] = useState(false);
  useEffect(() => {
    mainStore.getAddress("trc20");
    console.error(Global.currency);
    const defaultCurrency =
      CURRENCIES.find((c) => c.symbol === Global.currency) || CURRENCIES[1];
    setSelectedCurrency(defaultCurrency);
  }, []);
  const debouncedTextChange = debounce(async (amount) => {
    setediting(true);
    if (!editing2) {
      await mainStore.calcCard(amount, selectedCurrency?.symbol, true);
      mainStore.cardAmount.fiat_amount === "" || amount === ""
        ? setRecipientAmount("0")
        : setRecipientAmount(
            mainStore.cardAmount.crypto_amount.toFixed(2).toString()
          );
    }
    setediting(false);
  }, 850);
  const debouncedTextChange2 = debounce(async (amount) => {
    setediting2(true);

    if (!editing) {
      await mainStore.calcCard(amount, selectedCurrency?.symbol, false);
      mainStore.cardAmount.fiat_amount === "" || recipientAmount === ""
        ? setAmount("0")
        : setAmount(mainStore.cardAmount.fiat_amount.toFixed(2).toString());
    }
    setediting2(false);
  }, 850);
  useEffect(() => {
    return () => {
      debouncedTextChange.cancel();
    };
  }, []);
  useEffect(() => {
    debouncedTextChange(amount);
    return () => {
      debouncedTextChange2.cancel();
    };
  }, [selectedCurrency?.value]);
  const selectCurrency = async (currency) => {
    await mainStore.getBanks(currency.value);
    setSelectedCurrency(currency);
    hideDialog();
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const URL = `https://buy.neocrypto.net/?partner=onramper&cur_from=${
    selectedCurrency?.symbol
  }&cur_to=USDT_TRC20&amount=${amount}&partner_reference=01HW5AHC719A3RQXD47NYHGGQF&fix_cur_from=true&fix_cur_to=true&fix_amount=true&partner_url=https://buy.neocrypto.net/success-static?partner=wallet&lang=en&force_theme=${
    Global.isDark ? "dark" : "light"
  }&address=${mainStore.address}&fix_address=true`;

  const checkFirstTime = async () => {
    try {
      const value = await AsyncStorage.getItem("isFirstTimeee");
      if (value === null && mainStore.banks) {
        // Value not found, means it's the first time
        await AsyncStorage.setItem("isFirstTimeee", "true");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking first time operation:", error);
      return false; // Default to false in case of error
    }
  };
  const handleConfirm = async () => {
    const isFirstTime = await checkFirstTime(); // Await the result of checkFirstTime
    if (isFirstTime) {
      setModal2Visible(true);
    } else {
      setLoading(true);
      await delay(2000);
      setLoading(false);
      window.location.replace(URL);
    }
  };

  if (webView) {
    return (
      <View style={{ flex: 1 }}>
        <WebView
          source={{
            //   uri: `https://test.yamersal.com/api/paypal/handle-payment?amount=${route.params.amount}&currency=${route.params.currency}`,
            uri: `https://buy.neocrypto.net/?partner=onramper&cur_from=${
              selectedCurrency?.symbol
            }&cur_to=USDT_TRC20&amount=${amount}&partner_reference=01HW5AHC719A3RQXD47NYHGGQF&fix_cur_from=true&fix_cur_to=true&fix_amount=true&partner_url=https://buy.neocrypto.net/success-static?partner=wallet&lang=en&force_theme=${
              Global.isDark ? "dark" : "light"
            }&address=${mainStore.address}&fix_address=true`,
            headers: {
              //  Authorization: `Bearer ${mainStore.accessToken}`,
            },
          }}
          //  onNavigationStateChange={handleWebViewNavigationStateChange}
          style={styles.webview}
        />
      </View>
    );
  }
  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Global.backgroundColor,
        }}
      >
        <Image
          source={require("../../assets/Comp2.gif")}
          style={{
            width: 600,
            height: 600,
            flex: 1,
            backgroundColor: Global.backgroundColor,
            alignSelf: "center",
            justifyContent: "center",
            resizeMode: "contain",
          }}
        />
        <Text
          style={{
            position: "absolute",
            top: "60%",
            // paddingHorizontal: '25%',
            textAlign: "center",
            fontSize: 20,
            lineHeight: 25,
            zIndex: 999,
          }}
        >
          نقوم بتوجيهكم إلى منصة الدفع
        </Text>
      </View>
    );
  }
  return (
    <KeyboardAwareScrollView
      enableAutomaticScroll={true}
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      style={{
        flex: 1,
        backgroundColor: Global.backgroundColor,
        // paddingHorizontal: 16,
        //  backgroundColor: Global.backgroundColor,
      }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <KeyboardAvoidingView behavior="position" style={styles.container}>
        <Portal>
          <Dialog
            visible={dialogVisible}
            onDismiss={hideDialog}
            style={{ height: "90%" }}
          >
            <Dialog.Title>اختر العملة</Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {CURRENCIES.map((currency, index) => (
                <React.Fragment key={currency.symbol}>
                  <Dialog.Content>
                    <TouchableOpacity onPress={() => selectCurrency(currency)}>
                      <Text>
                        {currency.label} / {currency.symbol}
                      </Text>
                      {index < CURRENCIES.length - 1 && (
                        <Divider style={{ marginTop: 20 }} />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>
            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideDialog}>إلغاء</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Icon
          name="credit-card-fast-outline"
          size={150}
          color={Global.primary}
          alignSelf={"center"}
          style={{ alignSelf: "center" }}
        />
        <Text style={styles.title}>ادخل معلومات الدفع</Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <TextInput
            label="المبلغ"
            value={amountFoucs ? amount : Global.arabicToNumeric(amount)}
            onChangeText={(text) => {
              const normalizedText = text.replace(/,/g, ".");
              setAmount(normalizedText);
              debouncedTextChange(normalizedText);
            }}
            onFocus={() => {
              setAmountFoucs(true);
            }}
            onBlur={() => {
              setAmountFoucs(false);
            }}
            // contentStyle={styles.sendButtonContent}
            style={{
              flex: 1,
              alignSelf: "center",
              textAlign: "auto",
              fontSize: 20,
            }}
            // right={<TextInput.Icon icon="currency-usd" />}
            keyboardType="decimal-pad"
            mode="outlined"
          />

          <Button
            labelStyle={{ fontSize: 18, marginRight: 10 }}
            contentStyle={{ height: 50, direction: "ltr", marginLeft: -5 }}
            style={{
              borderRadius: 0,
              alignSelf: "center",
              width: 90,
              marginBottom: -5,
              marginRight: 10,
              height: 47,
            }}
            mode="contained"
            onPress={showDialog}
            icon={() => (
              <Icon
                name="chevron-down"
                size={30}
                color={Global.buttonTextColor}
                style={{ marginTop: -5, marginLeft: -10 }}
              />
            )}
          >
            {`${selectedCurrency?.symbol}`}
          </Button>
        </View>
        <Text style={{ marginTop: 20, fontSize: 17, writingDirection: "rtl" }}>
          ستحصل على:
        </Text>
        <TextInput
          value={
            rAmountFoucs
              ? recipientAmount
              : Global.arabicToNumeric(recipientAmount)
          }
          onChangeText={(text) => {
            const normalizedText = text.replace(/,/g, ".");
            setRecipientAmount(normalizedText);
            debouncedTextChange2(normalizedText);
          }}
          onFocus={() => {
            setRAmountFoucs(true);
          }}
          onBlur={() => {
            setRAmountFoucs(false);
          }}
          placeholderTextColor={Global.placeholder}
          style={{
            textAlign: "auto",
            fontSize: 20,
            marginTop: 10,
          }}
          left={<TextInput.Affix text="USD" />}
          mode="outlined"
          keyboardType="decimal-pad"
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            //  marginBottom: 15,
            marginTop: 15,
            backgroundColor: "#FFFEE8",
            borderColor: "#FF8C42",
            borderRadius: 5,
            borderWidth: 0.5,
            display: recipientAmount > 250 ? "flex" : "none",
          }}
        >
          <IconButton icon="alert" iconColor="#ebb703" size={25} />
          <Text
            style={{
              marginVertical: 5,
              fontWeight: Global.fontWeight,
              color: "black",
              lineHeight: 20,
              maxWidth: Dimensions.get("window").width * 0.7,
              writingDirection: "rtl",
            }}
          >
            قد تطلب منصة الدفع تحققًا من الهوية بالنسبة للمبالغ الأكبر من 250
            دولار.
          </Text>
        </View>
        <View
          style={{
            //  paddingEnd: 50,
            width: "100%",
            alignSelf: "center",
            borderRadius: 5,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              display: mainStore.banks ? "flex" : "none",
            }}
          >
            <IconButton icon="information-outline" size={25} />
            <View
              style={{
                fontWeight: Global.fontWeight,
                lineHeight: 25,
                color: Global.textcolor,
                flexDirection: "row",
              }}
            >
              <Text style={{ writingDirection: "rtl" }}>
                قائمة البنوك المتوافقة:
              </Text>
              <Text
                onPress={() => {
                  setModal2Visible(true);
                  setJustInfo(true);
                }}
                style={{ color: Global.primary, writingDirection: "rtl" }}
              >
                {" "}
                من هنا
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              //  display: recipientAmount < 15 ? 'flex' : 'none',
            }}
          >
            <IconButton icon="information-outline" size={25} />
            <Text
              style={{
                fontWeight: Global.fontWeight,
                lineHeight: 25,
                color: Global.textcolor,
              }}
            >
              الحد الأدنى للدفع هو 15 دولار
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <IconButton icon="information-outline" size={25} />
            <Text
              style={{
                color: Global.textcolor,
                fontWeight: Global.fontWeight,
                lineHeight: 25,
              }}
            >
              بعد إتمام عملية الدفع بنجاح، يصل المبلغ إلى محفظتكم خلال ثلاث
              دقائق كحد أقصى
            </Text>
          </View>
        </View>
        <Button
          mode="contained"
          onPress={handleConfirm}
          disabled={recipientAmount <= 14 || editing || editing2}
          contentStyle={{ height: 50 }}
          labelStyle={{ fontSize: 18, paddingTop: 10 }}
          style={styles.button}
        >
          تأكيد
        </Button>
        <Modal visible={modal2Visible} transparent={true} animationType="fade">
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            activeOpacity={1}
            onPress={() => setModal2Visible(false)}
            onRequestClose={() => setModal2Visible(false)}
          >
            <ScrollView
              style={{
                padding: 8,
                width: "90%",
                backgroundColor: Global.backgroundColor,
                borderRadius: 10,
                //   alignContent: "center",
                // alignItems: "center",
                maxHeight: "85%",
              }}
            >
              <IconButton
                style={{ alignSelf: "center" }}
                icon="information-outline"
                color={"red"}
                size={50}
              />

              <Text
                style={{
                  padding: 10,
                  //textAlign: 'center',
                  fontWeight: Global.fontWeight,
                  lineHeight: 23,
                  writingDirection: "rtl",
                }}
              >
                لإتمام عملية الشراء بنجاح، نوصي باستخدام بطاقات صادرة عن أحد هذه
                البنوك:
              </Text>
              <FlatList
                style={{
                  marginBottom: 10,
                  marginLeft: "10%",
                  writingDirection: "rtl",
                }}
                scrollEnabled={true}
                data={mainStore.banks}
                renderItem={({ item }) => (
                  <View>
                    <Text
                      style={{
                        fontSize: 18,
                        lineHeight: 24,
                        textAlign: "left",
                      }}
                    >
                      - {item}
                    </Text>
                  </View>
                )}
              />
            </ScrollView>

            <Button
              mode="contained"
              labelStyle={{ fontSize: 16 }}
              style={{
                alignSelf: "center",
                width: "90%",
                height: 50,
                justifyContent: "center",
                margin: 10,
                fontFamily: "Tajawal-Bold",
              }}
              onPress={async () => {
                setModal2Visible(false);
                !justInfo
                  ? (handleConfirm(), setJustInfo(false))
                  : checkFirstTime();
              }}
            >
              موافق
            </Button>
          </TouchableOpacity>
        </Modal>
      </KeyboardAvoidingView>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: Global.backgroundColor,
    paddingBottom: 0,
  },
  logo: {
    alignSelf: "center",
    marginBottom: 20,
    marginTop: 20,
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
    textAlign: "auto",
  },
  button: {
    marginTop: 20,
    borderRadius: 5,
  },
});
