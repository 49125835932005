import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  View,
  StyleSheet,
  Text as Txt,
  Platform,
  Image,
  TouchableHighlight,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  TouchableOpacity,
  Modal,
  Dimensions,
} from "react-native";
import {
  ProgressBar,
  TextInput,
  Button,
  IconButton,
  Text,
  Menu,
  Provider,
  Divider,
  SegmentedButtons,
  Portal,
  Chip,
  Avatar,
  Snackbar,
  Dialog,
  List,
  Searchbar,
} from "react-native-paper";
import Global from "../Global";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import SkeletonPlaceholder from "../react-native-skeleton-placeholder-master/src/skeleton-placeholder";
import Octicons from "react-native-vector-icons/Octicons";
import debounce from "lodash.debounce";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { observer } from "mobx-react-lite";
import mainStore from "../stores/MainStore";

const depositCountries = [
  {
    id: 1,
    name: "العالم",
    cur_code_en: "usd",
    cur_name_ar: "دولار أمريكي",
    flag: "global.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        id: 2,
        name: "الكرت",
        slug: "stripe",
        icon: "card.png",
      },
    ],
  },
  {
    id: 2,
    name: "أوروبا",
    cur_code_en: "eur",
    cur_name_ar: "يورو",
    flag: "europe.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        id: 2,
        name: "بنك إلكتروني",
        slug: "wise",
        icon: "wise.png",
      },
      {
        id: 3,
        name: "حوالة بنكية",
        slug: "bank-transfer",
        icon: "bank",
      },
    ],
  },
  {
    id: 3,
    name: "مصر",
    cur_code_en: "egp",
    cur_name_ar: "جنيه مصري",
    flag: "egypt.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        id: 2,
        name: "Vodafone Cash",
        slug: "vodafonecash",
        icon: "vodafone.png",
      },
    ],
  },
  {
    id: 4,
    name: "أمريكا",
    cur_code_en: "usd",
    cur_name_ar: "دولار أمريكي",
    flag: "usa.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        id: 2,
        name: "بنك إلكتروني",
        slug: "wise",
        icon: "wise.png",
      },
      {
        id: 3,
        name: "Zelle",
        slug: "zelle",
        icon: "zelle.png",
      },
    ],
  },
  {
    id: 5,
    name: "العراق",
    cur_code_en: "iqd",
    cur_name_ar: "دينار عراقي",
    flag: "iraq.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        id: 2,
        name: "زين كاش",
        slug: "zaincash",
        icon: "zaincash.png",
      },
    ],
  },
  {
    id: 6,
    name: "روسيا",
    cur_code_en: "rub",
    cur_name_ar: "روبل روسي",
    flag: "russia.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        id: 2,
        name: "حوالة بنكية",
        slug: "bank-transfer",
        icon: "bank",
      },
      {
        id: 3,
        name: "Payeer",
        slug: "payeer",
        icon: "payeer.png",
      },
    ],
  },
  {
    id: 7,
    name: "الخليج",
    cur_code_en: "usd",
    cur_name_ar: "دولار أمريكي",
    flag: "gulf.png",
    depositMethods: [
      {
        id: 1,
        name: "USDT",
        slug: "usdt",
        icon: "tether.png",
      },
      {
        name: "Western Union",
        slug: "western",
        icon: "wu.png",
      },
    ],
  },
];

const cities = [
  { name: "حلب", englishName: "Aleppo", id: 1, currency: "usd_aleppo" },
  { name: "دمشق", englishName: "Damascus", id: 2, currency: "usd_damascus" },
  {
    name: "ريف دمشق",
    englishName: "Rif Damascus",
    id: 20,
    currency: "usd_damascus",
    villages: [
      { name: "الكسوة", currency: "usd_damascus" },
      { name: "دوما", currency: "usd_damascus" },
      { name: "عرطوز", currency: "usd_damascus" },
      { name: "صحنايا", currency: "usd_damascus" },
      { name: "داريا", currency: "usd_damascus" },
      { name: "معضمية", currency: "usd_damascus" },
      { name: "التل", currency: "usd_damascus" },
      { name: "جرمانا", currency: "usd_damascus" },
      { name: "قدسيا", currency: "usd_damascus" },
      { name: "القدم", currency: "usd_damascus" },
      { name: "النبك", currency: "usd_damascus" },
      { name: "دير عطية", currency: "usd_damascus" },
      { name: "يبرود", currency: "usd_damascus" },
      { name: "قاره", currency: "usd_damascus" },
    ],
  },
  { name: "حمص", englishName: "Homs", id: 3, currency: "usd_homs" },
  {
    name: "ريف حمص",
    englishName: "Rif Homs",
    id: 21,
    currency: "usd_rifhoms",
    villages: [
      { name: "الرستن", currency: "usd_rifhoms" },
      { name: "تلبيسة", currency: "usd_rifhoms" },
      { name: "الحولة", currency: "usd_rifhoms" },
    ],
  },
  { name: "حماة", englishName: "Hama", id: 4, currency: "usd_hama" },
  {
    name: "ريف حماة",
    englishName: "Rif Hama",
    id: 21,
    currency: "usd_hama",
    villages: [{ name: "السلمية", currency: "usd_hama" }],
  },
  { name: "اللاذقية", englishName: "Latakia", id: 5, currency: "usd_latakia" },
  { name: "طرطوس", englishName: "Tartous", id: 6, currency: "usd_tartous" },
  {
    name: "ريف طرطوس",
    englishName: "Rif Tartous",
    id: 22,
    currency: "usd_tartous",
    villages: [
      { name: "صافيتا", currency: "usd_tartous" },
      { name: "بانياس", currency: "usd_tartous" },
    ],
  },
  { name: "درعا", englishName: "Daraa", id: 8, currency: "usd_daraa" },
  {
    name: "ريف درعا",
    englishName: "Rif Daraa",
    id: 23,
    currency: "usd_daraa",
    villages: [
      { name: "طفس", currency: "usd_daraa" },
      { name: "جاسم", currency: "usd_daraa" },
      { name: "نوى", currency: "usd_daraa" },
      { name: "داعل", currency: "usd_daraa" },
      { name: "المزريب", currency: "usd_daraa" },
      { name: "الحراك", currency: "usd_daraa" },
      { name: "الصنمين", currency: "usd_daraa" },
      { name: "تسيل", currency: "usd_daraa" },
      { name: "سحم الجولان", currency: "usd_daraa" },
      { name: "بصره الشام", currency: "usd_daraa" },
      { name: "الحارة", currency: "usd_daraa" },
      { name: "ازرع", currency: "usd_daraa" },
    ],
  },
  {
    name: "القنيطرة",
    englishName: "Quneitra",
    id: 9,
    currency: "usd_quneitra",
  },
  { name: "السويداء", englishName: "Suwayda", id: 10, currency: "usd_suwayda" },
  {
    name: "جبلة",
    englishName: "Jableh",
    id: 11,
    currency: "usd_jableh",
  },
  {
    name: "دير الزور",
    englishName: "Deir ez-Zor",
    id: 13,
    currency: "usd_deirezzor",
  },
];
const CURRENCIES = [
  { label: "يورو", value: "eur", symbol: "EUR" },
  { label: "دولار أمريكي", value: "usd", symbol: "USD" },
  { label: "كرونة نرويجية", value: "nok", symbol: "NOK" },
  { label: "كرونة سويدية", value: "sek", symbol: "SEK" },
  { label: "فرنك سويسري", value: "chf", symbol: "CHF" },
  { label: "جنيه استرليني", value: "gbp", symbol: "GBP" },
  { label: "دولار كندي", value: "cad", symbol: "CAD" },
  { label: "ريال سعودي", value: "sar", symbol: "SAR" },
  { label: "درهم إماراتي", value: "aed", symbol: "AED" },
  { label: "رينغيت ماليزي", value: "myr", symbol: "MYR" },
];
const MySkeletonPlaceholder = ({
  width = 150,
  height = 20,
  borderRadius = 5,
}) => {
  return (
    <SkeletonPlaceholder
      borderRadius={borderRadius}
      speed={300}
      backgroundColor={Global.placeholder}
    >
      <SkeletonPlaceholder.Item width={width} height={height} />
    </SkeletonPlaceholder>
  );
};
const CalcScreen = ({ navigation, route }) => {
  useEffect(() => {
    // Check if the current screen is HomeScreen
    const handleBackButton = (event) => {
      event.preventDefault();
      navigation.goBack();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, []);
  const [editing, setediting] = useState(false);
  const [editing2, setediting2] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [total, setTotal] = useState("");
  const [selectedCity, setSlectedCity] = useState(cities[1]);
  const [selectedCountry, setSelectedCountry] = useState(
    mainStore.externalCountries[0]
  );
  const [selectedDepositCountry, setSelectedDepositCountry] = useState(
    depositCountries[0]
  );
  const [selectedMethod, setSelectedMethod] = useState(
    mainStore.externalCountries[0]?.externalTransferMethods[0]
  );
  const [selectedDepositMethod, setSelectedDepositMethod] = useState(
    depositCountries[0]?.depositMethods[0]
  );
  const [selectedChoice, setSelectedChoice] = useState();
  const [amountFoucs, setAmountFoucs] = useState(false);
  const [rAmountFoucs, setRAmountFoucs] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "دولار أمريكي",
    value: "usd",
    symbol: "USD",
  });
  const [modal2Visible, setModal2Visible] = useState(false);

  const [amount, setAmount] = useState("150");
  const [recipientAmount, setRecipientAmount] = useState(" ");
  const [depositAmount, setDepositAmount] = useState("");
  const [depositFee, setDepositFee] = useState("");
  const [sendingFee, setSendingFee] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [curDialogVisible, setCurDialogVisible] = useState(false);
  const [countryDialogVisible, setCountryDialogVisible] = useState(false);
  const [depositCountryDialogVisible, setDepositCountryDialogVisible] =
    useState(false);
  const [methodDialogVisible, setMethodDialogVisible] = useState(false);
  const [depositMethodDialogVisible, setDepositMethodDialogVisible] =
    useState(false);
  const [choicesDialogVisible, setChoicesDialogVisible] = useState(false);

  useEffect(() => {
    mainStore.getBalance();

    //  mainStore.getExternalCountries();
  }, []);

  const debouncedTextChange = debounce(async (amount) => {
    setediting(true);
    if (!editing2) {
      setShowSkeleton(true);
      selectedDepositMethod.name === "الكرت"
        ? await mainStore.calcCard(
            Number(amount || 0).toFixed(2),
            selectedCurrency?.symbol,
            true
          )
        : await mainStore.calc(
            amount,
            selectedDepositMethod.slug === "usdt"
              ? "usd"
              : selectedDepositCountry.cur_code_en,
            "usd",
            "deposit",
            selectedDepositMethod.slug,
            true
          );
      setDepositFee(mainStore.feeDetails);
      await mainStore.calc(
        selectedDepositMethod.name === "الكرت"
          ? mainStore.cardAmount.crypto_amount
          : mainStore.feeDetails.receiving_amount,
        selectedMethod.calc_method === "agent" ? selectedCity.currency : "usd",
        selectedMethod.calc_method === "fadelUSD"
          ? "usd"
          : selectedCountry.cur_code_en.toLowerCase(),
        "external_transfer",
        selectedMethod.calc_method,
        true
      );
      setSendingFee(mainStore.feeDetails);
      mainStore.feeDetails.receiving_amount < 0 || amount === ""
        ? setRecipientAmount("0")
        : setRecipientAmount(
            Number(mainStore.feeDetails.receiving_amount).toFixed(2).toString()
          );
      setTotal(
        Math.max(
          (
            mainStore.feeDetails.sending_amount - mainStore.feeDetails.fee
          ).toFixed(2),
          0
        ).toString()
      );
      setShowSkeleton(false);
    }
    setediting(false);
  }, 700);
  const debouncedTextChange2 = debounce(async (amount) => {
    setediting2(true);

    if (!editing) {
      setShowSkeleton(true);
      await mainStore.calc(
        amount,
        selectedMethod.calc_method === "agent" ? selectedCity.currency : "usd",
        selectedMethod.calc_method === "fadelUSD"
          ? "usd"
          : selectedCountry.cur_code_en.toLowerCase(),
        "external_transfer",
        selectedMethod.calc_method,
        false
      );
      mainStore.feeDetails.receiving_amount < 0 || !recipientAmount
        ? setAmount("0")
        : console.log("");

      setTotal(
        Math.max(
          (
            mainStore.feeDetails.sending_amount - mainStore.feeDetails.fee
          ).toFixed(2),
          0
        ).toString()
      );
      setSendingFee(mainStore.feeDetails);
      selectedDepositMethod.name === "الكرت"
        ? await mainStore.calcCard(
            Number(mainStore?.feeDetails?.sending_amount || 0).toFixed(3),
            selectedCurrency?.symbol,
            false
          )
        : await mainStore.calc(
            mainStore.feeDetails.sending_amount,
            selectedDepositMethod.slug === "usdt"
              ? "usd"
              : selectedDepositCountry.cur_code_en,
            "usd",
            "deposit",
            selectedDepositMethod.slug,
            false
          );
      mainStore.feeDetails.sending_amount < 0 || !recipientAmount
        ? setAmount("0")
        : setAmount(
            selectedDepositMethod.name === "الكرت"
              ? Number(mainStore.cardAmount.fiat_amount || 0)
                  ?.toFixed(2)
                  ?.toString()
              : Number(mainStore.feeDetails.sending_amount)
                  .toFixed(2)
                  .toString()
          );
      setDepositFee(mainStore.feeDetails);
      setShowSkeleton(false);
      console.log(mainStore.cardAmount.fiat_amount);
    }
    setediting2(false);
  }, 700);
  useEffect(() => {
    recipientAmount === "" && !amountFoucs && setAmount("0");
  });

  useEffect(() => {
    debouncedTextChange(amount, selectedCity.currency);
    return () => {
      debouncedTextChange.cancel();
    };
  }, [
    selectedCity.currency,
    selectedCountry?.id,
    selectedMethod?.id,
    selectedDepositCountry.id,
    selectedDepositMethod.id,
    selectedCurrency.value,
  ]);

  useEffect(() => {
    debouncedTextChange(amount, selectedCity.currency);

    return () => {
      debouncedTextChange2.cancel();
    };
  }, []);

  useEffect(() => {
    const defaultCurrency =
      CURRENCIES.find((c) => c.symbol === Global.currency) || CURRENCIES[1];
    setSelectedCurrency(defaultCurrency);
  }, []);

  // For Country Dialog
  const showCountryDialog = () => {
    Keyboard.dismiss();
    setCountryDialogVisible(true);
  };
  const hideCountryDialog = () => setCountryDialogVisible(false);
  const selectCountry = (country) => {
    setSelectedCountry(country);
    setSelectedMethod(country.externalTransferMethods[0]);
    country?.externalTransferMethods[0]?.choices !== null &&
      setSelectedChoice(
        Object.values(country?.externalTransferMethods[0]?.choices)[0]
      );
    hideCountryDialog();
  };
  // for deposit countries
  const showDepositCountryDialog = () => {
    Keyboard.dismiss();
    setDepositCountryDialogVisible(true);
  };
  const hideDepositCountryDialog = () => setDepositCountryDialogVisible(false);
  const selectDepositCountry = (country) => {
    setSelectedDepositCountry(country);
    country.name === "العالم"
      ? setSelectedDepositMethod(country.depositMethods[0])
      : setSelectedDepositMethod(country.depositMethods[1]);
    hideDepositCountryDialog();
  };
  // For Deposit Method Dialog
  const showDepositMethodDialog = () => {
    Keyboard.dismiss();
    setDepositMethodDialogVisible(true);
  };
  const hideDepositMethodDialog = () => {
    setDepositMethodDialogVisible(false);
  };
  const selectDepositMethod = (method) => {
    setSelectedDepositMethod(method);
    hideDepositMethodDialog();
  };
  // For Method Dialog
  const showMethodDialog = () => {
    Keyboard.dismiss();
    setMethodDialogVisible(true);
  };
  const hideMethodDialog = () => {
    setMethodDialogVisible(false);
  };
  const selectMethod = (method) => {
    setSelectedMethod(method);
    method?.choices !== null &&
      setSelectedChoice(Object.values(method?.choices)[0]);
    hideMethodDialog();
  };
  const showDialog = () => {
    Keyboard.dismiss();
    setDialogVisible(true);
  };
  const hideDialog = () => setDialogVisible(false);
  const selectCity = (city) => {
    setSlectedCity(city);
    hideDialog();
  };

  const showCurDialog = () => {
    setCurDialogVisible(true);
    Keyboard.dismiss();
  };
  const hideCurDialog = () => setCurDialogVisible(false);

  const selectCurrency = async (currency) => {
    setSelectedCurrency(currency);
    hideCurDialog();
  };

  if (mainStore.externalCountries.length === 0 || mainStore.loading) {
    return (
      <Image
        source={require("../assets/Comp2.gif")}
        style={{
          width: 600,
          height: 600,
          flex: 1,
          backgroundColor: Global.backgroundColor,
          alignSelf: "center",
          justifyContent: "center",
          resizeMode: "contain",
        }}
      />
    );
  }
  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        {/*for send*/}
        <Portal>
          <Dialog
            style={{ maxHeight: Global.isios ? "100%" : "90%" }}
            visible={countryDialogVisible}
            onDismiss={hideCountryDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر بلدًا للتحويل:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {mainStore.externalCountries.map((country, index) => (
                <React.Fragment key={country.id}>
                  <Dialog.Content>
                    <TouchableOpacity onPress={() => selectCountry(country)}>
                      <View style={{ flexDirection: "row" }}>
                        <Image
                          source={{
                            uri: `https://api.yamersal.com/images/${country.flag}`,
                          }}
                          style={{
                            width: 35,
                            height: 35,
                            resizeMode: "contain",
                            marginLeft: 10,
                          }}
                        />
                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          {country.name}
                        </Text>
                      </View>
                      {index < mainStore.externalCountries.length - 1 && (
                        <Divider
                          style={{
                            marginTop: 20,
                            backgroundColor: Global.graycolor,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideCountryDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            style={{ width: "95%", alignSelf: "center", maxHeight: "90%" }}
            visible={methodDialogVisible}
            onDismiss={hideMethodDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر وسيلة التحويل:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {selectedCountry.externalTransferMethods.map((method, index) =>
                method.calc_method === false ? undefined : (
                  <React.Fragment key={method.id}>
                    <Dialog.Content>
                      <TouchableOpacity
                        onPress={() => {
                          method.calc_method === false
                            ? setModal2Visible(true)
                            : selectMethod(method);
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <View>
                            <Text style={{ fontSize: 18 }}>{method.name}</Text>
                            <Text
                              style={{
                                fontSize: 16,
                                marginTop: 10,
                                writingDirection: "rtl",
                              }}
                            >
                              {method.calc_method === "sawa"
                                ? Global.arabicToNumeric(
                                    mainStore.slider?.find(
                                      (item) =>
                                        item?.title === "شركات تحت 3 مليون"
                                    )?.ask
                                  ) +
                                  " - " +
                                  Global.arabicToNumeric(
                                    method.exchangeRate.ask
                                  ) +
                                  " "
                                : Global.arabicToNumeric(
                                    method.exchangeRate.ask
                                  ) + " "}
                              {method.calc_method === "fadelUSD"
                                ? "دولار"
                                : selectedCountry.cur_code_ar}
                            </Text>
                          </View>
                          <View
                            style={{
                              alignItems: "flex-end",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                name="alarm"
                                size={20}
                                color={Global.graycolor}
                                style={{ marginHorizontal: 5 }}
                              />
                              <Text
                                style={{
                                  fontSize: 15,
                                  color: Global.graycolor,
                                  alignSelf: "center",
                                  marginTop: 5,
                                  writingDirection: "rtl",
                                }}
                              >
                                {" " + method.estimated_arrival_time}
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: Global.graycolor,
                                  alignSelf: "center",
                                  marginTop: 5,
                                }}
                              >
                                الحد الأدنى:{" "}
                                {method.min_amount.toLocaleString("en")}{" "}
                                {method.calc_method === "fadelUSD"
                                  ? "دولار"
                                  : selectedCountry.cur_code_ar}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {index <
                          selectedCountry.externalTransferMethods.length -
                            1 && (
                          <Divider
                            style={{
                              marginTop: 20,
                              paddingTop: 0.6,
                              backgroundColor: Global.graycolor,
                            }}
                          />
                        )}
                      </TouchableOpacity>
                    </Dialog.Content>
                  </React.Fragment>
                )
              )}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideMethodDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            visible={dialogVisible}
            onDismiss={hideDialog}
            style={{ height: "90%", backgroundColor: Global.cardcolor }}
          >
            <Dialog.Title
              style={{
                writingDirection: "rtl",
              }}
            >
              اختر المحافظة
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {cities.map((city, index) => (
                <React.Fragment key={city.id}>
                  <Dialog.Content>
                    {city.name.includes("ريف") ? (
                      <List.Section key={index}>
                        <List.Accordion
                          style={{
                            backgroundColor: Global.cardcolor,
                            marginVertical: -17,
                          }}
                          titleNumberOfLines={2}
                          title={city.name}
                          titleStyle={{
                            lineHeight: 22,
                            alignSelf: "center",
                            marginLeft: -25,
                          }}
                        >
                          {cities[index].villages.map((village, index2) => (
                            <TouchableOpacity
                              style={{ marginTop: index2 === 0 ? 20 : 15 }}
                              onPress={() => selectCity(village)}
                            >
                              <Text style={{ alignSelf: "center" }}>
                                {village.name}
                              </Text>
                              {index2 < cities[index].villages.length - 1 && (
                                <Divider
                                  style={{
                                    marginTop: 20,
                                    backgroundColor: Global.graycolor,
                                  }}
                                />
                              )}
                            </TouchableOpacity>
                          ))}
                        </List.Accordion>
                        <Divider
                          style={{
                            marginTop: 20,
                            backgroundColor: Global.graycolor,
                          }}
                        />
                      </List.Section>
                    ) : (
                      <TouchableOpacity onPress={() => selectCity(city)}>
                        <Text style={{ alignSelf: "center" }}>{city.name}</Text>
                        {index < cities.length - 1 && (
                          <Divider
                            style={{
                              marginTop: 20,
                              backgroundColor: Global.graycolor,
                            }}
                          />
                        )}
                      </TouchableOpacity>
                    )}
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        {/*for deposit*/}
        <Portal>
          <Dialog
            style={{ maxHeight: Global.isios ? "100%" : "90%" }}
            visible={depositCountryDialogVisible}
            onDismiss={hideDepositCountryDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر بلد الإيداع:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {depositCountries.map((country, index) => (
                <React.Fragment key={country.id}>
                  <Dialog.Content>
                    <TouchableOpacity
                      onPress={() => selectDepositCountry(country)}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <Image
                          source={{
                            uri: `https://api.yamersal.com/images/${country.flag}`,
                          }}
                          style={{
                            width: 35,
                            height: 35,
                            resizeMode: "contain",
                            marginLeft: 10,
                          }}
                        />
                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          {country.name}
                        </Text>
                      </View>
                      {index < depositCountries.length - 1 && (
                        <Divider
                          style={{
                            marginTop: 20,
                            backgroundColor: Global.graycolor,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideDepositCountryDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            style={{ width: "95%", alignSelf: "center" }}
            visible={depositMethodDialogVisible}
            onDismiss={hideDepositMethodDialog}
          >
            <Dialog.Title style={{ writingDirection: "rtl" }}>
              اختر وسيلة الإيداع:
            </Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {selectedDepositCountry.depositMethods.map((method, index) => (
                <React.Fragment key={method.id}>
                  <Dialog.Content>
                    <TouchableOpacity
                      onPress={() => {
                        selectDepositMethod(method);
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {method.icon && method.icon.includes("png") ? (
                          <Image
                            source={{
                              uri: `https://api.yamersal.com/images/${method.icon}`,
                            }}
                            style={{ width: 30, height: 30 }}
                          />
                        ) : (
                          <Icon
                            name={method.icon}
                            marginRight={5}
                            size={30}
                            color={Global.textcolor}
                          />
                        )}
                        <View>
                          <Text style={{ fontSize: 18, marginEnd: 10 }}>
                            {method.name}
                          </Text>
                        </View>
                      </View>
                      {index <
                        selectedDepositCountry.depositMethods.length - 1 && (
                        <Divider
                          style={{
                            marginTop: 20,
                            paddingTop: 0.6,
                            backgroundColor: Global.graycolor,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>

            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideDepositMethodDialog}>موافق</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            visible={curDialogVisible}
            onDismiss={hideCurDialog}
            style={{ height: "90%", maxHeight: "90%" }}
          >
            <Dialog.Title>اختر العملة</Dialog.Title>
            <ScrollView showsVerticalScrollIndicator={false}>
              {CURRENCIES.map((currency, index) => (
                <React.Fragment key={currency.symbol}>
                  <Dialog.Content>
                    <TouchableOpacity onPress={() => selectCurrency(currency)}>
                      <Text>
                        {currency.label} / {currency.symbol}
                      </Text>
                      {index < CURRENCIES.length - 1 && (
                        <Divider style={{ marginTop: 20 }} />
                      )}
                    </TouchableOpacity>
                  </Dialog.Content>
                </React.Fragment>
              ))}
            </ScrollView>
            <Dialog.Actions style={{ justifyContent: "center" }}>
              <Button onPress={hideCurDialog}>إلغاء</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.label}>مبلغ وبلد الإيداع:</Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <TextInput
              value={amountFoucs ? amount : Global.arabicToNumeric(amount)}
              onChangeText={async (text) => {
                const normalizedText = text.replace(/,/g, ".");
                setAmount(normalizedText);
                await debouncedTextChange(normalizedText);
              }}
              onFocus={() => {
                setAmountFoucs(true);
              }}
              onBlur={() => {
                setAmountFoucs(false);
              }}
              placeholder="250.5"
              placeholderTextColor={Global.placeholder}
              style={styles.input}
              left={
                selectedDepositMethod.name === "الكرت" ? undefined : (
                  <TextInput.Affix
                    text={
                      selectedDepositMethod.slug === "usdt"
                        ? "USD"
                        : selectedDepositCountry.cur_code_en.toUpperCase()
                    }
                  />
                )
              }
              mode="outlined"
              keyboardType="decimal-pad"
              right={
                <TextInput.Icon
                  style={{ width: 200 }}
                  onPress={showDepositCountryDialog}
                  icon={() => (
                    <View>
                      <View style={{ flexDirection: "row", marginStart: -10 }}>
                        <Image
                          source={{
                            uri: `https://api.yamersal.com/images/${selectedDepositCountry.flag}`,
                          }}
                          style={{
                            width: 35,
                            height: 35,
                            resizeMode: "contain",
                            marginRight: 50,
                          }}
                        />
                        <Text
                          style={{
                            marginRight: 10,
                            fontSize: 15,
                            marginTop:
                              selectedDepositCountry.name.split(" ").length ===
                              2
                                ? 5
                                : 10,
                            maxWidth: 55,
                          }}
                        >
                          {selectedDepositCountry.name}
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            marginRight: 7,
                            marginTop: Global.isios ? 2.5 : 2.5,
                            transform: [{ scaleX: 1.5 }],
                          }}
                        >
                          ⌄
                        </Text>
                      </View>
                    </View>
                  )}
                />
              }
            />
            {selectedDepositMethod.name === "الكرت" && (
              <Button
                labelStyle={{ fontSize: 18, marginRight: 10 }}
                contentStyle={{ height: 69, direction: "ltr", marginLeft: -5 }}
                style={{
                  borderRadius: 0,
                  alignSelf: "center",
                  width: 90,
                  marginBottom: 17,
                  marginRight: 10,
                  height: 69,
                  flex: 0.65,
                }}
                mode="contained"
                onPress={showCurDialog}
                icon={() => (
                  <Icon
                    name="chevron-down"
                    size={30}
                    color={Global.buttonTextColor}
                    style={{ marginTop: -5, marginLeft: -10 }}
                  />
                )}
              >
                {`${selectedCurrency?.symbol}`}
              </Button>
            )}
          </View>
          <Text style={styles.label}>وسيلة الإيداع:</Text>
          <View style={{ flexDirection: "row", marginBottom: 20 }}>
            <Button
              icon={() => (
                <Icon
                  name="chevron-down"
                  size={30}
                  color={Global.textcolor}
                  style={{ marginTop: -5, marginHorizontal: -10 }}
                />
              )}
              labelStyle={{
                fontSize: 18,
                lineHeight: 22,

                // maxWidth: 100,
                // flexWrap: "wrap-reverse",
              }}
              contentStyle={{
                marginVertical: 7.5,
                direction: "ltr",
                marginHorizontal: -15,
              }}
              style={{
                borderRadius: 10,
                flex: 1,
                alignSelf: "stretch",
                fontWeight: "bold",
              }}
              mode="outlined"
              onPress={showDepositMethodDialog}
            >
              {selectedDepositMethod.name}
            </Button>
          </View>

          <View style={styles.hintBox}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 13,
                alignItems: "flex-end",
                display:
                  selectedDepositMethod.name === "الكرت" ? "none" : "flex",
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                <Icon
                  name="minus-circle-outline"
                  size={21}
                  style={{ marginLeft: 3, color: Global.graycolor }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {" "}
                    {depositFee?.fee?.toLocaleString("en") +
                      " " +
                      (selectedDepositMethod.slug === "usdt"
                        ? "دولار"
                        : selectedDepositCountry.cur_name_ar)}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>أجور الإيداع</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 13,
                alignItems: "flex-end",
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                <Icon
                  name="minus-circle-outline"
                  size={21}
                  style={{ marginLeft: 3, color: Global.graycolor }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {" "}
                    {sendingFee?.fee?.toLocaleString("en") +
                      " " +
                      "دولار أمريكي"}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>أجور الإرسال</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: 13,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <Icon
                  name="pause-circle-outline"
                  size={21}
                  style={{
                    transform: [{ rotate: "90deg" }],
                    marginLeft: 6,
                    color: Global.graycolor,
                  }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {Global.arabicToNumeric(total) + " " + "دولار أمريكي"}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>الإجمالي بعد الأجور</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                //marginBottom: 5,
                alignItems: "flex-end",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: Global.isios ? "flex-end" : "center",
                }}
              >
                <Octicons
                  name="x-circle"
                  size={17}
                  style={{
                    marginLeft: 6,
                    color: Global.graycolor,
                    marginRight: 2,
                  }}
                />
                {showSkeleton ? (
                  <MySkeletonPlaceholder />
                ) : (
                  <Txt style={styles.ios}>
                    {selectedMethod.calc_method === "fadelUSD"
                      ? "1 دولار"
                      : `${Global.arabicToNumeric(sendingFee?.exchangeRate)} ${
                          selectedCountry.currency_ar
                        }`}
                  </Txt>
                )}
              </View>
              <Text style={styles.labelText}>سعر صرف الدولار </Text>
            </View>
          </View>

          <Text
            style={{
              marginBottom: 10,
              fontWeight: Global.fontWeight,
              fontSize: 16,
              writingDirection: "rtl",
            }}
          >
            مبلغ وبلد الوجهة:
          </Text>
          <TextInput
            value={
              rAmountFoucs
                ? recipientAmount
                : Global.arabicToNumeric(recipientAmount)
            }
            onChangeText={async (text) => {
              const normalizedText = text.replace(/,/g, ".");
              setRecipientAmount(normalizedText);
              await debouncedTextChange2(normalizedText);
            }}
            onFocus={() => {
              setRAmountFoucs(true);
            }}
            onBlur={() => {
              setRAmountFoucs(false);
            }}
            placeholder="250.5"
            placeholderTextColor={Global.placeholder}
            style={styles.recipientAmount}
            left={
              <TextInput.Affix
                text={
                  selectedMethod.calc_method === "fadelUSD"
                    ? "دولار"
                    : selectedCountry.cur_code_ar
                }
              />
            }
            right={
              <TextInput.Icon
                style={{ width: 200 }}
                onPress={showCountryDialog}
                icon={() => (
                  <View>
                    <View style={{ flexDirection: "row", marginStart: -10 }}>
                      <Image
                        source={{
                          uri: `https://api.yamersal.com/images/${selectedCountry.flag}`,
                        }}
                        style={{
                          width: 35,
                          height: 35,
                          resizeMode: "contain",
                          marginRight: 50,
                        }}
                      />
                      <Text
                        style={{
                          marginRight: 10,
                          fontSize: 15,
                          marginTop:
                            selectedCountry.name.split(" ").length === 2
                              ? 5
                              : 10,
                          maxWidth: 55,
                        }}
                      >
                        {selectedCountry.name}
                      </Text>
                      <Text
                        style={{
                          fontSize: 16,
                          marginRight: 7,
                          marginTop: Global.isios ? 2.5 : 2.5,
                          transform: [{ scaleX: 1.5 }],
                        }}
                      >
                        ⌄
                      </Text>
                    </View>
                  </View>
                )}
              />
            }
            mode="outlined"
            keyboardType="decimal-pad"
          />
          <Text
            style={[styles.label, { marginTop: 20, writingDirection: "rtl" }]}
          >
            تفضيلات التحويل:
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Button
              icon={() => (
                <Icon
                  name="chevron-down"
                  size={30}
                  color={Global.textcolor}
                  style={{ marginTop: -5, marginHorizontal: -10 }}
                />
              )}
              labelStyle={{
                fontSize: 18,
                lineHeight: 22,

                // maxWidth: 100,
                // flexWrap: "wrap-reverse",
              }}
              contentStyle={{
                marginVertical: 7.5,
                direction: "ltr",
                marginHorizontal: -15,
              }}
              style={{
                borderRadius: 10,
                flex: 1,
                alignSelf: "stretch",
                fontWeight: "bold",
              }}
              mode="outlined"
              onPress={showMethodDialog}
            >
              {selectedMethod.name}
            </Button>
            {selectedMethod.calc_method === "agent" && (
              <Button
                icon={() => (
                  <Icon
                    name="chevron-down"
                    size={30}
                    color={Global.textcolor}
                    style={{ marginTop: -5, marginHorizontal: -10 }}
                    //  style={{ alignSelf: "flex-start" }}
                  />
                )}
                labelStyle={{ fontSize: 18, lineHeight: 22 }}
                contentStyle={{
                  marginVertical: 7.5,
                  direction: "ltr",
                  marginHorizontal: -15,
                }}
                style={{
                  marginEnd: 10,
                  borderRadius: 10,
                  flex: 1,
                  alignSelf: "stretch",
                  fontWeight: "bold",
                }}
                mode="outlined"
                onPress={showDialog}
              >
                {`${selectedCity.name}`}
              </Button>
            )}
          </View>
          <View>
            {selectedMethod.calc_method === "localSy" && (
              <Button
                icon={() => (
                  <Icon
                    name="chevron-down"
                    size={30}
                    color={Global.textcolor}
                    style={{ marginTop: -5, marginHorizontal: -10 }}
                    //  style={{ alignSelf: "flex-start" }}
                  />
                )}
                labelStyle={{
                  fontSize: 18,
                  lineHeight: 22,
                }}
                contentStyle={{
                  marginVertical: 7.5,
                  direction: "ltr",
                  marginHorizontal: -15,
                }}
                style={{
                  // marginEnd: 10,
                  borderRadius: 10,
                  flex: 1,
                  alignSelf: "stretch",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
                mode="outlined"
                onPress={showDialog}
              >
                {`${selectedCity.name}`}
              </Button>
            )}
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 0,
    flex: 1,
    backgroundColor: Global.backgroundColor,
  },
  title: {
    fontSize: 19,
    marginBottom: 10,
  },

  timerText: {
    color: Global.graycolor,
    marginBottom: 5,
    marginTop: -5,
    fontSize: 13,
  },
  infoBox: {
    backgroundColor: Global.cardcolor,
    padding: 10,
    width: "100%",
    alignSelf: "center",
    borderRadius: 5,
    borderWidth: 1,
    margin: 10,
    borderColor: Global.graycolor,
  },
  imageContainer: {
    marginVertical: 24,
    alignItems: "center",
  },
  image: {
    width: 200,
    height: 200,
  },

  input2: {
    flex: 1,
  },
  stepIndicators: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 20,
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
    borderRadius: 5,
  },
  stepText: {
    fontSize: 18,
    marginBottom: 10,
  },
  chipsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
  },
  modal: {
    backgroundColor: Global.backgroundColor,
    padding: 20,
    marginLeft: "5%",
    marginRight: "5%",
    marginBottom: "40%",
  },

  contentContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  timeOption: {
    width: "100%",
    paddingVertical: 12,
    borderTopColor: Global.textcolor,
    borderTopWidth: 0.3,
  },
  input: {
    fontWeight: "bold",
    fontSize: 20,
    padding: 7.5,
    marginBottom: 20,
    width: "100%",
    textAlign: "auto",
    flex: 1.6,
  },
  phoneNumberInput: {
    fontWeight: "bold",
    fontSize: 20,
    padding: 7.5,
    //marginBottom: 10,
    width: "100%",
    textAlign: "left",
    //marginRight: 10,
  },
  phoneInputContainer: {
    flexDirection: "row",
    marginBottom: Global.isios ? 30 : 20,
  },

  hintBox: {
    borderRadius: 5,
    paddingVertical: 10,
    marginBottom: 20,
    marginTop: 10,
    //marginTop: -10,
    //borderWidth: 1,
    //borderStyle: 'dashed',
    borderColor: Global.textcolor,
  },
  labelText: {
    color: Global.graycolor,
    fontSize: 15,
    fontWeight: Global.fontWeight,
  },
  ios: {
    fontWeight: Global.fontWeight,
    fontSize: 15,
    color: Global.textcolor,
    fontFamily: "Tajawal",
  },
  alert: {
    fontSize: 16,
    marginStart: 5,
  },
  recipientAmount: {
    fontWeight: "bold",
    fontSize: 20,
    padding: 7.5,
    textAlign: "auto",
  },

  label: {
    marginBottom: 10,
    fontWeight: Global.fontWeight,
    fontSize: 16,
    lineHeight: 22,
    writingDirection: "rtl",
  },
});

export default observer(CalcScreen);
