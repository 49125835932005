import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  View,
  Modal,
  Easing,
  Animated,
  Linking,
  ScrollView,
} from "react-native";
import {
  ActivityIndicator,
  Button,
  IconButton,
  MD2Colors,
  PaperProvider,
  Text,
  TextInput,
  configureFonts,
  MD3LightTheme,
  Portal,
  MD3DarkTheme,
  useTheme,
} from "react-native-paper";

import {
  Platform,
  I18nManager,
  TouchableOpacity,
  useColorScheme,
} from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
//import Global from "./Global";
import mainStore from "./stores/MainStore";
import { observer } from "mobx-react-lite";
import CountryFlag from "react-native-country-flag";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import LoginScreen from "./screens/AccountScreens/LoginScreen";
import SignupScreen from "./screens/AccountScreens/SignupScreen";
import ResetPasswordScreen from "./screens/AccountScreens/ResetPasswordScreen";
import ResetPassword2 from "./screens/AccountScreens/ResetPassword2";
import Global from "./Global";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AccountScreen from "./screens/AccountScreens/AccountScreen";
import ConfirmationScreen from "./screens/AccountScreens/ConfirmationScreen";
import IviteFriendScreen from "./screens/IviteFriendScreen";
import InvitedScreen from "./screens/InvitedScreen";
import HomeScreen from "./screens/HomeScreen";
import UsdtDepositScreen from "./screens/DepositScreens/UsdtDepositScreen";
import UsdtWithdraw from "./screens/UsdtWithdraw";
import ExternalTransferScreen from "./screens/SendScreens/ExternalTransferScreen";
import SendToWalletScreen from "./screens/SendScreens/SendToWalletScreen";
import TransactionDetailsScreen from "./screens/TransactionDetailsScreen";
import TransactionsScreen from "./screens/TransactionsScreen";
import MobileRechargeScreen from "./screens/MobileRechargeScreen";
import ElectronicBankDepositScreen from "./screens/DepositScreens/ElectronicBankDepositScreen";
import BankDetailsScreen from "./screens/DepositScreens/BankDetailsScreen";
import BankDepositScreen from "./screens/DepositScreens/BankDepositScreen";
import VodafoneDepositScreen from "./screens/DepositScreens/VodafoneDepositScreen";
import VodafoneAddressScreen from "./screens/DepositScreens/VodafoneAddressScreen";
import FaqScreen from "./screens/FaqScreen";
import OneSignal, { useOneSignalSetup } from "react-onesignal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import ResultScreen from "./screens/ResultScreen";
import CardDepositScreen from "./screens/DepositScreens/CardDepositScreen";
import ZelleDepositScreen from "./screens/DepositScreens/ZelleDepositScreen";
import ZainDepositScreen from "./screens/DepositScreens/ZainDepositScreen";
import FreelancersDeposit from "./screens/DepositScreens/FreelancersDeposit";
import PayeerDepositScreen from "./screens/DepositScreens/PayeerDepositScreen";
import SuggestionsScreen from "./screens/SuggestionsScreen";
import WesternDepositScreen from "./screens/DepositScreens/WesternDepositScreen";
import CalcScreen from "./screens/CalcScreen";
import TransactionDetails2 from "./screens/TransactionDetails2";

const Toast = ({ message, visible, onClose, openModal }) => {
  if (!visible) return null;

  return (
    <View style={styles2.toast}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          maxWidth: "70%",
        }}
      >
        <TouchableOpacity onPress={onClose}>
          <Text style={styles2.closeButton}>X</Text>
        </TouchableOpacity>
        <Text style={styles2.text}>{message}</Text>
      </View>

      <Button onPress={openModal} mode="contained" style={{ borderRadius: 10 }}>
        تثبيت الآن
      </Button>
    </View>
  );
};
const styles2 = StyleSheet.create({
  toast: {
    // position: "absolute",
    backgroundColor: "#eee",
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    zIndex: 999,
    marginBottom: 5,
    justifyContent: "space-between",
  },
  text: {
    color: "black",
    fontSize: 16,
    writingDirection: "rtl",
  },
  closeButton: {
    fontSize: 25,
    color: "#263e5b",
    marginStart: 10,
  },
});
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();
function MyTabs({ navigation }) {
  useEffect(() => {
    const interval = setInterval(() => {
      document.title = "يامرسال";
    }, 250);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);
  const translateY = useRef(new Animated.Value(100)).current;

  const theme = useTheme();
  const [menuVisible, setMenuVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(undefined);

  useEffect(() => {
    let timeout;

    if (modalVisible) {
      timeout = setTimeout(() => {
        setBackgroundColor(Global.modaloverlay);
      }, 300);
    } else {
      setBackgroundColor(undefined);
    }

    return () => clearTimeout(timeout);
  }, [modalVisible]);
  // ...
  const handleMenuItemPress = (screen) => {
    setModalVisible(false);
    navigation.navigate(screen);
  };
  return (
    <View style={{ flex: 1, backgroundColor: Global.backgroundColor }}>
      <Tab.Navigator
        tabBarOptions={{
          activeTintColor: Global.secondary,
          inactiveTintColor: theme.colors.text,
        }}
        screenOptions={{
          tabBarLabelStyle: {
            fontFamily: "Tajawal",
            fontSize: 11,
            lineHeight: 14,
          },
          tabBarStyle: {
            height: 60,
            marginBottom: /iphone|ipad|ipod/.test(
              window.navigator.userAgent.toLowerCase()
            )
              ? undefined
              : undefined,
            backgroundColor: Global.backgroundColor,
          },
          // tabBarLabel: () => false,
          headerShown: false,
        }}
      >
        <Tab.Screen
          name="HomeScreen"
          component={HomeScreen}
          options={{
            tabBarLabel: "محفظتي",
            tabBarIcon: ({ color, size }) => (
              <Icon name="wallet-outline" color={color} size={30} />
            ),
          }}
        />
        <Tab.Screen
          name="TransactionScreen"
          component={TransactionsScreen}
          options={{
            unmountOnBlur: true,
            tabBarLabel: "السجل",
            tabBarIcon: ({ color, size }) => (
              <Icon name="history" color={color} size={30} />
            ),
          }}
        />
        <Tab.Screen
          name="SendMoney"
          listeners={({ navigation, route }) => ({
            tabPress: (e) => {
              e.preventDefault();
              setModalVisible(true);
            },
          })}
          component={View} // Using 'View' as a placeholder component.
          options={{
            tabBarLabel: "أرسل",
            unmountOnBlur: true,
            tabBarIcon: ({ color, size }) => (
              <IconButton
                icon="arrow-up-circle"
                iconColor={"#f9c307"}
                size={55}
                style={{
                  top: -10,
                  //  left: 2,
                  zIndex: 100,
                }}
                top={-10}
                right={2}
                zIndex={100}
              />
            ),
          }}
        />
        <Tab.Screen
          name="InviteFriendScreen"
          component={IviteFriendScreen}
          options={{
            tabBarLabel: "دعوة صديق",
            tabBarIcon: ({ color, size }) => (
              <Icon name="share-variant-outline" color={color} size={30} />
            ),
          }}
        />
        <Tab.Screen
          name="AccountScreen"
          component={AccountScreen}
          options={{
            tabBarLabel: "الحساب",
            tabBarIcon: ({ color, size }) => (
              <Icon name="account-settings" color={color} size={30} />
            ),
          }}
        />
      </Tab.Navigator>

      <View style={styles.buttonContainer} />

      <Modal
        transparent={true}
        visible={modalVisible}
        onBackdropPress={() => setModalVisible(false)}
        onModalHide={() => setModalVisible(false)} // Add this line
        animationType="slide"
      >
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: backgroundColor,
          }}
          activeOpacity={1}
          onPress={() => setModalVisible(false)}
        >
          <View
            style={{
              width: "90%",
              backgroundColor: Global.backgroundColor,
              borderRadius: 10,
            }}
          >
            <TouchableOpacity
              style={{
                borderBottomWidth: 1,
                borderColor: Global.bordercolor,
                paddingHorizontal: 20,
                paddingVertical: 15,
                flexDirection: "row",
                alignItems: "center",
                display:
                  mainStore.sendMethods[0]?.active === 1 ? "flex" : "none",
              }}
              onPress={() => handleMenuItemPress("SendToWalletScreen")}
            >
              <Image
                source={{
                  uri: `https://api.yamersal.com/images/${mainStore.sendMethods[0]?.icon}`,
                }}
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: 8,
                  resizeMode: "contain",
                }}
              />
              <Text
                style={{
                  fontSize: 18,
                  color: Global.textcolor,
                  fontWeight: Platform.OS === "ios" ? "900" : "normal",
                  marginStart: 10,
                  direction: "rtl",
                }}
              >
                {mainStore.sendMethods[0]?.title}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                borderColor: Global.bordercolor,
                paddingHorizontal: 20,
                paddingVertical: 15,
                flexDirection: "row",
                alignItems: "center",
                display:
                  mainStore.sendMethods[1]?.active === 1 ? "flex" : "none",
              }}
              onPress={() => handleMenuItemPress("ExternalTransferScreen")}
            >
              <Image
                source={{
                  uri: `https://api.yamersal.com/images/${mainStore.sendMethods[1]?.icon}`,
                }}
                style={{
                  width: 50,
                  height: 50,
                  marginVertical: -15,
                  marginEnd: -17,
                  resizeMode: "contain",
                  marginLeft: 8,
                }}
              />
              <Text
                style={{
                  fontSize: 18,
                  color: Global.textcolor,
                  fontWeight: Platform.OS === "ios" ? "900" : "normal",
                  marginStart: 10,
                  direction: "rtl",
                }}
              >
                {mainStore.sendMethods[1]?.title}
              </Text>
            </TouchableOpacity>
          </View>
          <Button
            labelStyle={{ fontSize: 19 }}
            mode="contained"
            style={{
              borderRadius: 10,
              alignSelf: "center",
              width: "90%",
              height: 50,
              justifyContent: "center",
              margin: 10,
              marginBottom: Global.isWebIos ? 50 : 20,
            }}
            onPress={() => setModalVisible(false)}
          >
            إلغاء
          </Button>
        </TouchableOpacity>
      </Modal>
    </View>
  );
}
const MyStack = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      document.title = "يامرسال";
    }, 250);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);
  return (
    <Stack.Navigator
      // mode="modal"
      screenOptions={{
        headerStyle: {
          backgroundColor: Global.backgroundColor,
        },
        headerTintColor: Global.textcolor, // set the text color
        gestureResponseDistance: 500,
        //  gestureEnabled: true,
        headerBackImage: ({ tintColor }) => (
          <Icon
            name="close"
            color={Global.textcolor}
            size={35}
            marginLeft={5}
          />
        ),
        presentation: Global.isWebIos ? "transparentModal" : "modal",
        transitionSpec: {
          open: {
            animation: "timing",
            config: {
              duration: 300, // Custom duration for opening animation
              easing: Easing.inOut(Easing.ease),
            },
          },
          close: {
            animation: "timing",
            config: {
              duration: 300, // Custom duration for closing animation
              easing: Easing.inOut(Easing.ease),
            },
          },
        },
      }}
    >
      <Stack.Screen
        name="MyTabs"
        component={MyTabs}
        options={{
          headerShown: false,
        }}
      />

      <Stack.Screen
        name="ConfirmationScreen"
        component={ConfirmationScreen}
        options={{
          title: "تأكيد الحساب",
          headerTitleStyle: { fontFamily: "Tajawal", fontSize: 24 },
          headerBackTitleVisible: false,
        }}
      />
      <Stack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
        options={{
          title: "تغيير كلمة المرور",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS,
        }}
      />
      <Stack.Screen
        name="ResetPassword2"
        component={ResetPassword2}
        options={{
          title: "تغيير كلمة المرور",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
        }}
      />
      <Stack.Screen
        name="FaqScreen"
        component={FaqScreen}
        options={{
          headerShown: true,
          title: "الأسئلة الشائعة",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
        }}
      />
      <Stack.Screen
        name="SuggestionsScreen"
        component={SuggestionsScreen}
        options={{
          headerShown: true,
          title: "الشكاوى والاقتراحات",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
        }}
      />
      <Stack.Screen
        name="InvitedScreen"
        component={InvitedScreen}
        options={{
          headerShown: true,
          headerTransparent: true,
          title: "قائمة الأصدقاء المدعوين",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
        }}
      />
      <Stack.Screen
        name="DepositScreen"
        component={UsdtDepositScreen}
        options={({ navigation }) => ({
          headerShown: true,
          // eslint-disable-next-line react/no-unstable-nested-components
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositUsdt;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
          headerTransparent: true,
          title: "إيداع USDT",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
        })}
      />
      <Stack.Screen
        name="UsdtWithdraw"
        component={UsdtWithdraw}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "سحب عبر USDT",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.withdrawal;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SendToWalletScreen"
        component={SendToWalletScreen}
        options={{
          title: "إرسال داخل يا مرسال",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS, // Use the default modal transition animation
        }}
      />
      <Stack.Screen
        name="ExternalTransferScreen"
        component={ExternalTransferScreen}
        options={{
          title: "حوالة خارجية",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS, // Use the default modal transition animation
        }}
      />
      <Stack.Screen
        name="TransactionDetailsScreen"
        component={TransactionDetailsScreen}
        options={{
          headerTransparent: true,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          title: "",
          headerStyle: { backgroundColor: "#263e5b" },
          headerBackTitleVisible: false,
          headerBackImage: ({ tintColor }) => (
            <Icon name="close" color={"white"} size={35} marginLeft={5} />
          ),
        }}
      />
      <Stack.Screen
        name="TransactionDetails2"
        component={TransactionDetails2}
        options={({ navigation }) => ({
          headerStyle: {
            backgroundColor: !Global.isDark
              ? "rgba(245, 245, 245, 1)"
              : Global.backgroundColor,
            borderBottomWidth: 0,
          },
          headerShown: true,
          //   headerTransparent: true,
          title: "تفاصيل المعاملة",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS,
        })}
      />
      <Stack.Screen
        name="MobileRechargeScreen"
        component={MobileRechargeScreen}
        options={{
          title: "شحن رصيد الموبايل",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS, // Use the default modal transition animation
        }}
      />
      <Stack.Screen
        name="CalcScreen"
        component={CalcScreen}
        options={{
          title: "حاسبة الحوالات",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS, // Use the default modal transition animation
        }}
      />
      <Stack.Screen
        name="FreelancersDeposit"
        component={FreelancersDeposit}
        options={{
          title: "سحب من خمسات ومستقل",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS, // Use the default modal transition animation
        }}
      />
      <Stack.Screen
        name="ElectronicBankDepositScreen"
        component={ElectronicBankDepositScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر بنك إلكتروني",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositBank;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PayeerDepositScreen"
        component={PayeerDepositScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر Payeer",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositBank;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="ZelleDepositScreen"
        component={ZelleDepositScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر Zelle",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositBank;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="WesternDepositScreen"
        component={WesternDepositScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر Western Union",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
        })}
      />
      <Stack.Screen
        name="BankDepositScreen"
        component={BankDepositScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر حوالة بنكية",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositBank;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="BankDetailsScreen"
        component={BankDetailsScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "معلومات التحويل",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositBank;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="VodafoneAddressScreen"
        component={VodafoneAddressScreen}
        options={({ navigation }) => ({
          headerShown: true,
          //   headerTransparent: true,
          title: "حساب فودافون للإرسال",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
          headerRight: () => (
            <IconButton
              icon="book-information-variant"
              iconColor={Global.textcolor}
              size={30}
              marginRight={5}
              onPress={() => {
                const URL = mainStore.helpLinks.depositBank;
                Linking.openURL(URL).catch((err) => {
                  console.error("Failed to open url:", err);
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="CardDepositScreen"
        component={CardDepositScreen}
        options={{
          title: "إيداع عبر الكرت",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          ...TransitionPresets.ModalSlideFromBottomIOS, // Use the default modal transition animation
        }}
      />
      <Stack.Screen
        name="VodafoneDepositScreen"
        component={VodafoneDepositScreen}
        options={{
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر فودافون كاش",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
        }}
      />
      <Stack.Screen
        name="ZainDepositScreen"
        component={ZainDepositScreen}
        options={{
          headerShown: true,
          //   headerTransparent: true,
          title: "إيداع عبر زين كاش",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
          cardStyle: { flex: 1, backgroundColor: "transparent" },
          cardOverlayEnabled: true,
        }}
      />
      <Stack.Screen
        name="ResultScreen"
        component={ResultScreen}
        options={{
          title: "",
          headerTitleStyle: { fontFamily: "Tajawal-Bold", fontSize: 24 },
          headerBackTitleVisible: false,
        }}
      />
    </Stack.Navigator>
  );
};

const MyFirstStack = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      document.title = "يامرسال";
    }, 250);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);
  return (
    <Stack.Navigator
      mode="modal"
      screenOptions={{
        // presentation: "transparentModal",
        headerBackImage: ({ tintColor }) => (
          <Icon
            name="arrow-right"
            color={Global.textcolor}
            size={35}
            marginLeft={5}
          />
        ),
        presentation: Global.isWebIos ? "transparentModal" : "modal",
        headerStyle: {
          backgroundColor: Global.backgroundColor,
        },
        headerTintColor: Global.textcolor,
      }}
    >
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="SignupScreen"
        component={SignupScreen}
        options={{
          title: "تسجيل حساب جديد",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
        }}
      />
      <Stack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
        options={{
          title: "إعادة تعيين كلمة المرور",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
        }}
      />
      <Stack.Screen
        name="ResetPassword2"
        component={ResetPassword2}
        options={{
          title: "إعادة تعيين كلمة المرور",
          headerTitleStyle: { fontFamily: "Tajawal" },
          headerBackTitleVisible: false,
        }}
      />
    </Stack.Navigator>
  );
};
function App() {
  const fontConfig = {
    fontFamily: "Tajawal",
    fontWeight: Global.fontWeight,
    textAlign: "right",
  };
  const theme = {
    ...MD3LightTheme,
    fonts: configureFonts({ config: fontConfig }),
    colors: {
      ...MD3LightTheme.colors,
      primary: Global.primary,
      background: "#F6F6F6",
      onSurfaceVariant: Global.inputleft,
      onSurface: Global.textcolor,
    },
  };
  const theme2 = {
    ...MD3DarkTheme,
    fonts: configureFonts({ config: fontConfig }),
    colors: {
      ...MD3DarkTheme.colors,
      primary: Global.primary,
      onPrimary: Global.buttonTextColor,
      surface: "white",
      // background: '#2111',
    },
  };
  const [storagevalue, setstorage] = useState("");
  const [splashload, setsplashload] = useState(false);
  const [showRealApp, setShowRealApp] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [isUpdated, setIsUpdated] = useState(true);
  const [toastVisible, setToastVisible] = useState(true);
  const [modal2Visible, setModal2Visible] = useState(false);

  //const colorScheme = Appearance.getColorScheme();
  const colorScheme = useColorScheme();
  const { loggedIn } = mainStore;
  const [screen, setscreen] = useState("");

  useEffect(() => {
    mainStore.getCountry();

    /* Geolocation.getCurrentPosition(
      async position => {
        const {latitude, longitude} = position.coords;
        const country = await getCountryFromLatLon(latitude, longitude);
        Global.country = country;
        await getCurrencyFromCountry(country);
        // callback(currency);
        // console.error(currency);
      },
      error => {
        console.error('Location error:', error);
        Global.country = 'US';
        Global.currency = 'USD';
      },
    ); */
  }, []);
  useEffect(() => {
    if (mainStore.errorMessage === "Unauthenticated.") {
      AsyncStorage.removeItem("accesstoken");
      mainStore.logout();
      mainStore.load(false);
      mainStore.stackState("closed");
    }
  }, [mainStore.errorMessage]);

  useEffect(() => {
    // انتبه هاد ممكن يسبب مشاكل (ال return تبعه)
    // mainStore.getUserdata();
    mainStore.getAccesstoken();
    //storeData(loggedIn);
    const logAsyncStorageContent = async () => {
      try {
        const keys = await AsyncStorage.getAllKeys();
        const result = await AsyncStorage.multiGet(keys);

        console.log("AsyncStorage content:");
        result.forEach(([key, value]) => {
          console.log(`Key: ${key}, Value: ${value}`);
        });
      } catch (error) {
        console.error("Error retrieving AsyncStorage content:", error);
      }
    };
    const storage = async () => {
      try {
        const value = await AsyncStorage.getItem("accesstoken");
        setstorage(value);
      } catch (e) {
        console.log(e);
      }
    };

    storage();
    logAsyncStorageContent();
  }, [mainStore.accessToken, mainStore.loggedIn, showRealApp]);

  useEffect(() => {
    const interval = setInterval(() => {
      document.title = "يامرسال";
    }, 250);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);
  const stripePromise = loadStripe(
    mainStore.isTest
      ? "pk_test_51OPUluEvUis3mHPEqLJ2tEtLueYU7eDzzeg3A6fHTVoFCgZq0zSvafBN4BpQAoWJWCAMvFFoYQbNQgRBhZ5cKXo300NvsVlcxv"
      : "pk_live_51OPUluEvUis3mHPESJ8VpO7oo93GlFJmVjyfW87eSsyiDHVxFWFuGvPMEaEJ8c7LptTVDh8t4cWP8rEhhEXX1iZR00AKwDX1E2"
  );
  const options = {
    mode: "payment",
    amount: 10000,
    currency: "usd",
    locale: "ar",
    appearance: {
      theme: Global.isDark ? "night" : "flat",
      labels: "floating",
      variables: {
        fontFamily: "Tajawal", // Set Tajawal font

        fontSizeBase: "1.05rem",
        // fontFamily: "Tajawal, sans-serif",
        colorPrimary: Global.primary,
        borderRadius: "5px",
        fontWeightNormal: "700", // Your desired font weight
        focusBoxShadow: "none",
        focusOutline: "1.8px solid var(--colorPrimary)",
        colorDanger: "red",
        colorText: Global.textcolor,
        //  writingDirection: "rtl",
      },
      layout: {
        type: "accordion",
      },
      rules: {
        ".Input, .Block": {
          backgroundColor: Global.isDark ? "#212121" : undefined,
          border: "1.5px solid var(--colorPrimary)",
        },
      },
    },
  };
  return (
    <Elements options={options} stripe={stripePromise}>
      <PaperProvider theme={colorScheme === "dark" ? theme2 : theme}>
        <SafeAreaView
          style={{
            flex: 1,
            backgroundColor: Global.backgroundColor,
            paddingBottom: Global.isWebIos ? 25 : 0,
          }}
        >
          <SafeAreaProvider style={{ backgroundColor: Global.backgroundColor }}>
            <NavigationContainer>
              {!window.matchMedia("(display-mode: standalone)").matches && (
                <Toast
                  message="ثبت التطبيق على جوالك لتستمتع بتجربة متكاملة"
                  visible={Global.isWebIos && toastVisible}
                  onClose={() => setToastVisible(false)}
                  openModal={setModal2Visible}
                />
              )}

              {/* Rest of your app */}
              {storagevalue === null ? <MyFirstStack /> : <MyStack />}
              {Platform.OS === "web" ? (
                <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require("react-native-vector-icons/Fonts/MaterialIcons.ttf")}) format('truetype');
        }
        @font-face {
          font-family: 'Octicons';
          src: url(${require("react-native-vector-icons/Fonts/Octicons.ttf")}) format('truetype');
        }
      `}</style>
              ) : null}
              <Modal
                visible={modal2Visible}
                transparent={true}
                animationType="fade"
              >
                <TouchableOpacity
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.5)",
                  }}
                  activeOpacity={1}
                  onPress={() => setModal2Visible(false)}
                  onRequestClose={() => setModal2Visible(false)}
                >
                  <View
                    padding={8}
                    style={{
                      width: "90%",
                      backgroundColor: Global.backgroundColor,
                      borderRadius: 10,
                      alignContent: "center",
                      alignItems: "center",
                      maxHeight: "85%",
                    }}
                  >
                    <ScrollView>
                      <Text
                        paddingBottom={10}
                        style={{
                          textAlign: "center",
                          fontWeight: Global.fontWeight,
                          lineHeight: 23,
                          marginHorizontal: 20,
                          marginBottom: 20,
                          writingDirection: "rtl",
                          fontSize: 19,
                          marginTop: 20,
                        }}
                      >
                        موقع يامرسال يمكن تحميله كتطبيق على جهازك!
                      </Text>
                      <Image
                        source={require("./assets/pwa.mp4")}
                        style={{
                          resizeMode: "contain",
                          width: 350,
                          height: 350,
                          maxWidth: "90%",
                          alignSelf: "center",
                        }}
                      />
                      <Text
                        paddingBottom={10}
                        style={{
                          textAlign: "center",
                          fontWeight: Global.fontWeight,
                          lineHeight: 23,
                          marginHorizontal: 20,
                          marginBottom: 20,
                          writingDirection: "rtl",
                          fontSize: 17,
                          marginVertical: 10,
                        }}
                      >
                        اتبع التعليمات الموضحة أعلاه حتى تستطيع استخدام الموقع
                        كتطبيق بدون الحاجة إلى فتحه من المتصفح.
                      </Text>
                    </ScrollView>
                  </View>

                  <Button
                    mode="contained"
                    labelStyle={{ fontSize: 16 }}
                    style={{
                      alignSelf: "center",
                      width: "90%",
                      height: 50,
                      justifyContent: "center",
                      margin: 10,
                      fontFamily: "Tajawal-Bold",
                    }}
                    onPress={() => setModal2Visible(false)}
                  >
                    موافق
                  </Button>
                </TouchableOpacity>
              </Modal>
            </NavigationContainer>
          </SafeAreaProvider>
        </SafeAreaView>
      </PaperProvider>
    </Elements>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  app: {
    marginHorizontal: "auto",
    maxWidth: 500,
  },
  logo: {
    height: 80,
  },
  header: {
    padding: 20,
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginVertical: "1em",
    textAlign: "center",
  },

  link: {
    color: "#1B95E0",
  },
  code: {
    fontFamily: "monospace, monospace",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  toast: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "grey",
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 999,
  },
  text: {
    color: "white",
    textAlign: "right",
    writingDirection: "rtl",
  },
  closeButton: {
    color: "white",
    fontWeight: "bold",
  },
});

const buttonStyles = StyleSheet.create({
  button: {
    backgroundColor: "#2196F3",
    borderRadius: 2,
  },
  text: {
    color: "#fff",
    fontWeight: "500",
    padding: 8,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

export default observer(App);
